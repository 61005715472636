import styled from 'styled-components';
import {colors} from '../../assets/styles';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 700px;

  form {
    background: ${colors.secondary};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin: 0.8rem 0;
    padding: 2rem;
    width: 400px;
    border-radius: 8px;
    text-align: center;
  }

  div > label {
    margin-left: 0.5rem;
  }

  .button_submit {
    transition: 0.5s;
    margin-top: 1rem;
    &:hover {
      background: ${colors.primary};
      color: ${colors.secondary};
    }
  }
`;
