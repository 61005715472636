export const Types = {
  CREATE_OREGLOBALCONSTRAINT_REQUEST: '@ore/CREATE_OREGLOBALCONSTRAINT_REQUEST',
  CREATE_OREGLOBALCONSTRAINT_SUCCESS: '@ore/CREATE_OREGLOBALCONSTRAINT_SUCCESS',
  CREATE_OREGLOBALCONSTRAINT_FAILURE: '@ore/CREATE_OREGLOBALCONSTRAINT_FAILURE',
  GET_OREGLOBALCONSTRAINT_REQUEST: '@ore/GET_OREGLOBALCONSTRAINT_REQUEST',
  GET_OREGLOBALCONSTRAINT_SUCCESS: '@ore/GET_OREGLOBALCONSTRAINT_SUCCESS',
  GET_OREGLOBALCONSTRAINT_FAILURE: '@ore/GET_OREGLOBALCONSTRAINT_FAILURE',
  UPDATE_OREGLOBALCONSTRAINT_REQUEST: '@ore/UPDATE_OREGLOBALCONSTRAINT_REQUEST',
  UPDATE_OREGLOBALCONSTRAINT_SUCCESS: '@ore/UPDATE_OREGLOBALCONSTRAINT_SUCCESS',
  UPDATE_OREGLOBALCONSTRAINT_FAILURE: '@ore/UPDATE_OREGLOBALCONSTRAINT_FAILURE',
  DELETE_OREGLOBALCONSTRAINT_REQUEST: '@ore/DELETE_OREGLOBALCONSTRAINT_REQUEST',
  DELETE_OREGLOBALCONSTRAINT_SUCCESS: '@ore/DELETE_OREGLOBALCONSTRAINT_SUCCESS',
  DELETE_OREGLOBALCONSTRAINT_FAILURE: '@ore/DELETE_OREGLOBALCONSTRAINT_FAILURE',
};
