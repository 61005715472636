import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  ores: [],
  lotes: [],
};

const oreRegister = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_ORE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_ORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ores: [...state.ores, action.payload.data],
        lotes: [...state.lotes, Number(action.payload.data.lote_number)],
      };

    case Types.CREATE_ORE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_ORE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_ORE_SUCCESS:
      return {
        ...state,
        ores: action.payload.data,
        lotes: action.payload.data.map((oree) => oree.lote_number),
        loading: false,
        error: null,
      };

    case Types.GET_ORE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_ORE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_ORE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        ores: [
          ...state.ores.map((oree) =>
            oree.id === action.payload.id ? action.payload.data : oree
          ),
        ],
        lotes: [
          ...state.ores.map((oree) =>
            oree.id === action.payload.id
              ? Number(action.payload.data.lote_number)
              : Number(oree.lote_number)
          ),
        ],
      };

    case Types.UPDATE_ORE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_ORE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_ORE_SUCCESS:
      return {
        loading: false,
        error: null,
        ores: [...state.ores.filter((oree) => oree.id !== action.payload.id)],
        lotes: [
          ...state.ores
            .filter((oree) => oree.id !== action.payload.id)
            .map((lote) => Number(lote.lote_number)),
        ],
      };

    case Types.DELETE_ORE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default oreRegister;
