/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';
import {colors} from '../../../../assets/styles';

export const Container = styled.div`
  width: 95%;
  height: 75vh;

  background: ${colors.secondary};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0 0px 50px 0;
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
`;

export const TableLeito = styled.div`
  grid-area: leito;
  width: 100%;
  height: auto;
  max-height: 65vh;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  background: ${colors.white._100};
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

export const LeitoHeader = styled.div`
  width: 100%;
  min-height: 40px;
  height: 40px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background: ${colors.primary};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
`;

export const LeitoHeaderLabel = styled.strong`
  color: ${colors.white._100};
  font-size: 14px;
  width: 100%;
  text-align: center;
`;

export const TitleLeito = styled.h1`
  color: ${colors.white._100};
  font-size: 25px;
  font-weight: bold;
  margin-left: 3rem;
  padding-bottom: 0.2rem;
`;

export const LeitoBody = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const MaterialContainer = styled.div`
  width: 100%;
  height: 35px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  margin-left: 16px;
  border-bottom: 1px solid ${colors.tertiary};
`;

export const MaterialName = styled.span`
  width: 100%;
  color: #9542ff;
  font-size: 1.2rem;
  font-weight: bold;
  font-style: normal;
`;

export const MaterialValue = styled(MaterialName)`
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
`;

export const Wrapper = styled.div`
  grid-area: card;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  overflow: scroll;
`;

export const CardContent = styled.div`
  width: 100%;
  min-width: 320px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background: ${colors.white._100};
  border: 5px solid ${(props) => props.customColor};
  margin: 30px 10px 0px 10px;
`;

export const DeviationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: hidden;
  margin: 25px 0px 5px 10px;
`;

export const SelectContent = styled.div`
  margin: 20px 0px;

  label {
    font-size: 0.9.75rem;
    font-weight: 600;
  }

  select {
    margin-top: 10px;
  }
`;

export const Space = styled.div`
  margin: 5px 0;
`;

export const ButtonContainer = styled.div`
  width: 80%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;

  .btn-primary {
    max-height: 30px;
    min-height: 20px;
    border-radius: 10px;
    background: ${colors.primary};
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

    a {
      color: ${colors.secondary};
    }
  }
`;
