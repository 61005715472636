import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as OreConstraintActions} from '../../ducks/ore/constraints/actions';

function* createOreConstraint({payload}) {
  const {
    ore_id,
    ores,
    status_constraint,
    lote_number,
    min_amount,
    max_amount,
    amount,
    composition,
    crepitation,
  } = payload.data;

  try {
    const response = yield call(api.post, '/ores/constraints', {
      ore_id,
      status_constraint,
      lote_number,
      min_amount,
      max_amount,
      amount,
      composition,
      crepitation,
    });

    const newData = {
      ...response.data,
      ores,
    };

    yield put(OreConstraintActions.createOreSuccess(newData));
  } catch (error) {
    yield put(OreConstraintActions.createOreFailure(error.message));
  }
}

function* getOreConstraint() {
  try {
    const response = yield call(api.get, '/ores/constraints');
    yield put(OreConstraintActions.getOreSuccess(response.data));
  } catch (error) {
    yield put(OreConstraintActions.getOreFailure(error.message));
  }
}

function* updateOreConstraint({payload}) {
  try {
    const {
      id,
      ore_id,
      ores,
      status_constraint,
      lote_number,
      min_amount,
      max_amount,
      amount,
      composition,
      crepitation,
    } = payload.data;

    const ore = {
      ore_id,
      status_constraint,
      lote_number,
      min_amount,
      max_amount,
      amount,
      composition,
      crepitation,
    };

    const {data} = yield call(api.put, `/ores/constraints/${id}`, ore);

    const oreUpdated = {
      id: data.id,
      ore_id: data.ore_id,
      ores,
      status_constraint: data.status_constraint,
      lote_number: data.lote_number,
      min_amount: data.min_amount,
      max_amount: data.max_amount,
      amount: data.amount,
      composition: data.composition,
      crepitation: data.crepitation,
    };

    yield put(OreConstraintActions.updateOreSuccess(id, oreUpdated));
  } catch (error) {
    yield put(OreConstraintActions.updateOreFailure(error.message));
  }
}

function* deleteOreConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/ores/constraints/${id}`);

    yield put(OreConstraintActions.deleteOreSuccess(id));
  } catch (error) {
    yield put(OreConstraintActions.deleteOreFailure(error.message));
  }
}

export {
  createOreConstraint,
  getOreConstraint,
  updateOreConstraint,
  deleteOreConstraint,
};
