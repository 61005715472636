/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEdit, MdDelete, MdArrowForward, MdArrowBack} from 'react-icons/md';

import animationButtonAdd from '../../../../assets/lotties/button_add.json';
import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  HorizontalArrow,
  ContainerTable,
  ButtonItem,
  MenuBottomContainer,
  MenuBottomContent,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {Content, ContentInRow, ButtonContainer} from './styles';

import {colors} from '../../../../assets/styles';

import Input from '../../../../components/Input';
import SimpleSelect from '../../../../components/Select';
import LateralModal from '../../../../components/LateralModal';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import Menu from '../../../../components/Menu';
import ResidueGlobalConstraint from '../ResidueGlobalConstraint';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as ResidueActions} from '../../../../store/ducks/residue/constraints/actions';

import {useToast} from '../../../../contexts/ToastContext';

import {formatPercentage} from '../../../../utils/format';

import useKeys from '../../../../hooks/useKeys';

const ResidueConstraint = () => {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {residues} = useSelector((state) => state.residueRegister);
  const {residuesConstraints} = useSelector((state) => state.residueConstraint);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [isEditGlobalConstraint, setIsEditGlobalConstraint] = useState(false);
  const [residueToEdit, setResidueToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  const initialValuesInput = {
    name: residues.length !== 0 ? residues[0].name : '',
    composition: {
      Fe_original: 0,
      SiO2_original: 0,
      Al2O3_original: 0,
      CaOMgO_original: 0,
      Mn_original: 0,
      P_original: 0,
    },
  };

  const [values, setValues] = useState(initialValuesInput);

  const getResidues = () => {
    setLoadingPage(true);

    try {
      dispatch(ResidueActions.getResidueRequest());
      setTimeout(() => {
        setLoadingPage(false);
      }, 1000);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getResidues();
  }, []);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(ResidueActions.deleteResidueRequest(Number(event.id)));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  const setFormToAdd = (show) => {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
    setResidueToEdit(null);
  };

  const setFormToEdit = (show, residue) => {
    const {composition} = residue;

    setValues({
      name: residue?.residues?.name,
      composition,
    });

    setResidueToEdit(residue);
    setShowFormToEdit(show);
    setActionAllowed(false);
  };

  const onCloseForms = () => {
    setFormToAdd(false);
    setShowFormToEdit(false);
    setValues(initialValuesInput);
    setResidueToEdit(null);
  };

  const schemaYup = Yup.object().shape({
    Fe_original: Yup.number().required(
      'É necessário informar a taxa de Fe no resíduo'
    ),
    SiO2_original: Yup.number().required(
      'É necessário informar a taxa de SiO2 no resíduo'
    ),
    Al2O3_original: Yup.number().required(
      'É necessário informar a taxa de Al2O3 no resíduo'
    ),
    CaOMgO_original: Yup.number().required(
      'É necessário informar a taxa de CaOMgO no resíduo'
    ),
    Mn_original: Yup.number().required(
      'É necessário informar a taxa de Mn no resíduo'
    ),
    P_original: Yup.number().required(
      'É necessário informar a taxa de P no resíduo'
    ),
  });

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const residueId = residues.filter(
          (residue) => residue.name === data.name
        );
        if (residueId.length === 0) return;

        const newResidue = {
          residue_id: residueId[0].id,
          residues: {
            name: residueId[0].name,
          },
          composition: {
            Fe_original: Number(data.Fe_original),
            SiO2_original: Number(data.SiO2_original),
            Al2O3_original: Number(data.Al2O3_original),
            CaOMgO_original: Number(data.CaOMgO_original),
            Mn_original: Number(data.Mn_original),
            P_original: Number(data.P_original),
          },
        };

        dispatch(ResidueActions.createResidueRequest(newResidue));

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const residueId = residues.filter(
          (residue) => residue.name === data.name
        );
        if (residueId.length === 0) return;

        const residueUpdated = {
          id: Number(residueToEdit.id),
          residue_id: residueId[0].id,
          residues: {
            name: residueId[0].name,
          },
          composition: {
            Fe_original: Number(data.Fe_original),
            SiO2_original: Number(data.SiO2_original),
            Al2O3_original: Number(data.Al2O3_original),
            CaOMgO_original: Number(data.CaOMgO_original),
            Mn_original: Number(data.Mn_original),
            P_original: Number(data.P_original),
          },
        };

        dispatch(ResidueActions.updateResidueRequest(residueUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemoveResidue = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `O registro será removido do banco de dados do sistema e esta ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  useEffect(() => {
    if (
      leftArrow &&
      !showFormToAdd &&
      !showFormToEdit &&
      !isEditGlobalConstraint
    ) {
      history.push('/fundentes/cadastro');
    }
    if (
      rightArrow &&
      !showFormToAdd &&
      !showFormToEdit &&
      !isEditGlobalConstraint
    ) {
      history.push('/produtos/restricoesdeproducao');
    }
  }, [
    leftArrow,
    rightArrow,
    showFormToAdd,
    showFormToEdit,
    isEditGlobalConstraint,
  ]);

  return (
    <>
      <Container>
        <Menu />
        <HorizontalArrow>
          <Link to="/fundentes/cadastro">
            <div className="icon">
              <MdArrowBack />
            </div>
          </Link>
        </HorizontalArrow>
        <HorizontalArrow anchor="right">
          <Link to="/produtos/restricoesdeproducao">
            <div className="icon">
              <MdArrowForward />
            </div>
          </Link>
        </HorizontalArrow>

        {(showFormToAdd || showFormToEdit) && (
          <BackgroundOpacity
            className="overlay"
            onClick={() => onCloseForms()}
          />
        )}

        <LateralModal
          open={showFormToAdd || showFormToEdit}
          title={showFormToAdd ? 'Novo Registro' : 'Editar Registro'}
          onClose={showFormToAdd ? setShowFormToAdd : setShowFormToEdit}>
          <Content>
            <Form
              initialData={showFormToAdd ? values : residueToEdit}
              ref={showFormToAdd ? formRefAdd : formRefEdit}
              onSubmit={showFormToAdd ? handleAddForm : handleEditForm}>
              <ContentInRow>
                <label htmlFor="name">
                  <strong>Nome:</strong>
                </label>
                <ContentInput className="input-block">
                  <SimpleSelect
                    name="name"
                    value={values.name}
                    options={residues.map((residue) => {
                      return {value: residue.id, label: residue.name};
                    })}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        name: e.target.value,
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <strong>Composição química (%): </strong>
              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="Fe_original">Fe:</label>
                  <Input
                    name="Fe_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.Fe_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          Fe_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="SiO2_original">SiO2:</label>
                  <Input
                    name="SiO2_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.SiO2_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          SiO2_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="Al2O3_original">Al2O3:</label>
                  <Input
                    name="Al2O3_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.Al2O3_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          Al2O3_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="CaOMgO_original">CaO+MgO:</label>
                  <Input
                    name="CaOMgO_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.CaOMgO_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          CaOMgO_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="Mn_original">Mn:</label>
                  <Input
                    name="Mn_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.Mn_original}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          Mn_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="P_original">P:</label>
                  <Input
                    name="P_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.P_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          P_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ButtonContainer>
                <ButtonSecondary type="submit" className="button_submit">
                  {loading ? (
                    <LottieAnimation
                      width={40}
                      height={40}
                      animation={animationLoadingButton}
                    />
                  ) : (
                    <p>{showFormToAdd ? 'Adicionar' : 'Salvar alterações'}</p>
                  )}
                </ButtonSecondary>
              </ButtonContainer>
            </Form>
          </Content>
        </LateralModal>

        <BoxContainer>
          <ContainerTitlePage className="container_title_page">
            <TitlePage className="title_page">Resíduos</TitlePage>
            {profile.occupation !== 'Analista de Laboratório' && (
              <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
                <LottieAnimation
                  width={50}
                  height={50}
                  animation={animationButtonAdd}
                />
                {widthWindow > 500 && <p>Adicionar</p>}
              </ContainerButtonAdd>
            )}
          </ContainerTitlePage>

          <ContainerTable>
            <ResidueGlobalConstraint
              open={isEditGlobalConstraint}
              onClose={setIsEditGlobalConstraint}
            />

            <Table>
              <TableHeader>
                <tr>
                  <th className="th-edit">Editar</th>
                  <th>RESÍDUO</th>
                  <th>%Fe</th>
                  <th>%SiO2</th>
                  <th>%Al2O3</th>
                  <th>%CaO</th>
                  <th>%Mn</th>
                  <th>%P</th>
                  <th className="th-delete">Excluir</th>
                </tr>
              </TableHeader>

              {loadingPage ? (
                <TableBody>
                  <tr>
                    <td>
                      <BackgroundOpacity className="overlay">
                        <LottieAnimation
                          width={100}
                          height={100}
                          animation={animationLoadingPage}
                        />
                      </BackgroundOpacity>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {residuesConstraints.length !== 0 &&
                    residuesConstraints.map((residue) => (
                      <tr key={residue.id}>
                        <td
                          className="td-edit"
                          onClick={() => setFormToEdit(true, residue)}>
                          <MdEdit size={16} color={colors.primary} />
                        </td>
                        <td>{residue?.residues?.name || ''}</td>
                        <td>
                          {formatPercentage(
                            residue?.composition?.Fe_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            residue?.composition?.SiO2_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            residue?.composition?.Al2O3_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            residue?.composition?.CaOMgO_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            residue?.composition?.Mn_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            residue?.composition?.P_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td
                          className="td-delete"
                          onClick={() => handleRemoveResidue(residue.id)}>
                          <MdDelete size={16} color={colors.failure} />
                        </td>
                      </tr>
                    ))}
                </TableBody>
              )}
            </Table>

            {residuesConstraints.length === 0 && (
              <div className="container_information">
                <p>
                  Insira um novo registro clicando sobre o botão "Adicionar"
                </p>
              </div>
            )}
          </ContainerTable>
        </BoxContainer>
      </Container>

      <MenuBottomContainer>
        <MenuBottomContent>
          <ButtonItem className="btn-secondary">
            <Link to="/residuos/cadastro">Cadastro de resíduos</Link>
          </ButtonItem>
          <ButtonItem className="btn-primary">
            <Link to="/residuos/restricoes">Restrições de resíduos</Link>
          </ButtonItem>
        </MenuBottomContent>
      </MenuBottomContainer>
    </>
  );
};

export default ResidueConstraint;
