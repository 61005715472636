export const Types = {
  CREATE_RESIDUECONSTRAINT_REQUEST: '@residue/CREATE_RESIDUECONSTRAINT_REQUEST',
  CREATE_RESIDUECONSTRAINT_SUCCESS: '@residue/CREATE_RESIDUECONSTRAINT_SUCCESS',
  CREATE_RESIDUECONSTRAINT_FAILURE: '@residue/CREATE_RESIDUECONSTRAINT_FAILURE',
  GET_RESIDUECONSTRAINT_REQUEST: 'residue/GET_RESIDUECONSTRAINT_REQUEST',
  GET_RESIDUECONSTRAINT_SUCCESS: 'residue/GET_RESIDUECONSTRAINT_SUCCESS',
  GET_RESIDUECONSTRAINT_FAILURE: 'residue/GET_RESIDUECONSTRAINT_FAILURE',
  UPDATE_RESIDUECONSTRAINT_REQUEST: '@residue/UPDATE_RESIDUECONSTRAINT_REQUEST',
  UPDATE_RESIDUECONSTRAINT_SUCCESS: '@residue/UPDATE_RESIDUECONSTRAINT_SUCCESS',
  UPDATE_RESIDUECONSTRAINT_FAILURE: '@residue/UPDATE_RESIDUECONSTRAINT_FAILURE',
  DELETE_RESIDUECONSTRAINT_REQUEST: '@residue/DELETE_RESIDUECONSTRAINT_REQUEST',
  DELETE_RESIDUECONSTRAINT_SUCCESS: '@residue/DELETE_RESIDUECONSTRAINT_SUCCESS',
  DELETE_RESIDUECONSTRAINT_FAILURE: '@residue/DELETE_RESIDUECONSTRAINT_FAILURE',
};
