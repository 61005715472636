import styled, {keyframes, css} from 'styled-components';
import {colors} from '../../../../assets/styles';

const fadeIn = keyframes`
   from {
     opacity: 0;
  }
   to {
     opacity: 1;
   }
`;

const fadeOut = keyframes`
   from {
     opacity: 1;
  }
   to {
     opacity: 0;
   }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;
  width: 100%;
  height: 70vh;
  max-width: 700px;
  line-height: 2rem;
  overflow-y: auto;

  label,
  span,
  strong,
  input,
  select {
    font-size: 1.1rem;
  }

  form {
    margin-top: 10rem;
  }

  .button_submit {
    transition: 0.5s;
    background: ${colors.primary};
    color: ${colors.secondary};

    &:hover {
      opacity: 0.8;
      color: ${colors.primary};
    }
  }

  ${(props) =>
    props.showForm
      ? css`
          form {
            animation: ${fadeIn} 0.5s;
          }
        `
      : css`
          form {
            animation: ${fadeOut} 0.5s;
          }
        `}

  @media(max-width: 900px) {
    line-height: 1rem;
    overflow-y: scroll;

    label,
    span,
    strong,
    input,
    select {
      font-size: 0.9rem;
    }

    form {
      margin-top: 14rem;
      margin-bottom: 7rem;
    }
  }
`;

export const ContentInRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  input {
    width: 100%;
    margin: 0.2rem;
    padding: 0.2rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${colors.secondary};

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    padding: 2rem 3rem;
    .button_submit {
      position: relative;
      bottom: 2rem;
    }
  }
`;

export const Status = styled.td`
  color: ${colors.secondary};
  font-weight: bold;
  background: ${({status}) => {
    if (status === 'DISPONIVEL') {
      return `${colors.success}`;
    }
    if (status === 'INDISPONIVEL') {
      return `${colors.failure}`;
    }
    return `${colors.info}`;
  }};
`;
