import styled from 'styled-components';
import colors from '../../../assets/styles/colors';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;

  ul {
    list-style: none;
  }

  ul li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1.4rem;
    border-bottom: 1px solid ${colors.secondary};
  }

  ul li a {
    font-weight: bold;
    padding: 1rem 0.6rem;
    color: ${colors.secondary};
    cursor: pointer;
  }

  ul li a:hover {
    color: ${colors.primary};
  }

  ul li a:focus-within {
    color: ${colors.primary};
  }

  ul li .image {
    width: 25px;
    height: auto;
  }

  @media (min-width: 700px) {
    font-size: 1.2rem;

    ul li {
      margin: 2.5rem;
    }
  }
`;
