import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  wastesConstraints: [],
};

const wasteConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_WASTECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_WASTECONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wastesConstraints: [...state.wastesConstraints, action.payload.data],
      };

    case Types.CREATE_WASTECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_WASTECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_WASTECONSTRAINT_SUCCESS:
      return {
        ...state,
        wastesConstraints: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_WASTECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_WASTECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_WASTECONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wastesConstraints: [
          ...state.wastesConstraints.map((wastee) =>
            wastee.id === action.payload.id ? action.payload.data : wastee
          ),
        ],
      };

    case Types.UPDATE_WASTECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_WASTECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_WASTECONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        wastesConstraints: [
          ...state.wastesConstraints.filter((wastee) => wastee.id !== action.payload.id),
        ],
      };

    case Types.DELETE_WASTECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default wasteConstraint;
