import styled, {keyframes, css} from 'styled-components';
import {colors} from '../../assets/styles';

const fadeIn = keyframes`
   from {
     opacity: 0;
  }
   to {
     opacity: 1;
   }
`;

const fadeOut = keyframes`
   from {
     opacity: 1;
  }
   to {
     opacity: 0;
   }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 700px;

  .button_submit {
    transition: 0.5s;
    background: ${colors.primary};
    color: ${colors.secondary};

    &:hover {
      opacity: 0.8;
      color: ${colors.primary};
    }
  }

  ${(props) =>
    props.showForm
      ? css`
          form {
            animation: ${fadeIn} 0.5s;
          }
        `
      : css`
          form {
            animation: ${fadeOut} 0.5s;
          }
        `}
`;

export const ContentInRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  input {
    width: 100%;
    margin: 0.2rem;
    padding: 0.2rem;
  }
`;
