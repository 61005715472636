/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import {Link} from 'react-router-dom';

import {
  MdDashboard,
  MdPermDataSetting,
  MdEventNote,
  MdSettings,
} from 'react-icons/md';

import {FaFireAlt, FaBalanceScale} from 'react-icons/fa';
import {Container} from './styles';
import colors from '../../../assets/styles/colors';

import {useSidebar} from '../../../contexts/SidebarContext';
import logoCimp from '../../../assets/images/logo-cimp.svg';

function LeftSection() {
  const {setShowSidebar} = useSidebar();

  return (
    <Container>
      <ul>
        {/* <li>
          <MdDashboard size={22} color={colors.primary} />
           <Link to="/" onClick={() => setShowSidebar(false)}>
            Painel de controle
          </Link> 
        </li> */}

        <li>
          <img className="image" src={logoCimp} alt="logo cimp" />
          <Link to="/leito" onClick={() => setShowSidebar(false)}>
            AF CIMP
          </Link>
        </li>

        {/* <li>
          <MdPermDataSetting size={22} color={colors.primary} />
          <Link to="/silos" onClick={() => setShowSidebar(false)}>
            Controle de carregamento
          </Link>
        </li>
        <li>
          <FaFireAlt size={22} color={colors.primary} />
          <Link to="/panelas" onClick={() => setShowSidebar(false)}>
            Controle de queima
          </Link>
        </li>
        <li>
          <FaBalanceScale size={22} color={colors.primary} />
          <Link to="/laboratorio" onClick={() => setShowSidebar(false)}>
            Controle do laboratório
          </Link>
        </li>
        <li>
          <MdEventNote size={22} color={colors.primary} />
          <Link to="/frequencia" onClick={() => setShowSidebar(false)}>
            Controle de frequência
          </Link>
        </li> */}
        {/* <li>
          <MdSettings size={22} color={colors.primary} />
          <Link to="/configuracoes" onClick={() => setShowSidebar(false)}>
            Configurações
          </Link>
        </li> */}
      </ul>
    </Container>
  );
}

export default LeftSection;
