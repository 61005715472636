import React, {useState} from 'react';

import {Container, Content} from './styles';
import {usePaginate} from '../../contexts/PaginateContext';

const Paginate = ({orderBy, years}) => {
  const {filters, setFilters} = usePaginate();

  const months = [
    {id: 1, name: 'Jan'},
    {id: 2, name: 'Fev'},
    {id: 3, name: 'Mar'},
    {id: 4, name: 'Abr'},
    {id: 5, name: 'Mai'},
    {id: 6, name: 'Jun'},
    {id: 7, name: 'Jul'},
    {id: 8, name: 'Ago'},
    {id: 9, name: 'Set'},
    {id: 10, name: 'Out'},
    {id: 11, name: 'Nov'},
    {id: 12, name: 'Dez'},
  ];

  const [selected, setSelected] = useState(filters.month);

  function handleChangeMonth(month) {
    setSelected(month);
    setFilters({...filters, month});
  }

  return (
    <Container>
      <div>
        <strong>Ordenar registros por:</strong>
        <select
          name="order_by"
          onChange={(e) => setFilters({...filters, orderBy: e.target.value})}>
          {orderBy.map((item) => (
            <option key={item.value} value={`${item.value}`}>
              {item.value}
            </option>
          ))}
        </select>
      </div>

      <Content>
        <div>
          <strong>Ano:</strong>
          <select
            name="years"
            defaultValue={filters.year}
            onChange={(e) => setFilters({...filters, year: e.target.value})}>
            {years.map((item) => (
              <option key={item.value} value={`${item.value}`}>
                {item.value}
              </option>
            ))}
          </select>
        </div>

        <strong>Mês:</strong>
        <ul>
          {months.map((month) => (
            <li
              key={month.id}
              className={`${
                month.id === selected ? 'li_active' : 'li_inative'
              }`}
              onClick={() => handleChangeMonth(month.id)}>
              <a
                className={`${
                  month.id === selected ? 'link_active' : 'link_inative'
                }`}>
                {month.name}
              </a>
            </li>
          ))}
        </ul>
      </Content>
    </Container>
  );
};

export default Paginate;
