import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';
import history from '../../../services/history';

import {Actions as AuthActions} from '../../ducks/auth/actions';

function* signIn({payload}) {
  try {
    const {email, password} = payload;

    const response = yield call(api.post, '/sessions', {email, password});

    const {token, user} = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(AuthActions.signInSuccess(token, user));

    history.push('/');
    history.go();
  } catch (error) {
    yield put(AuthActions.signInFailure(error.message));
  }
}

function* signUp({payload}) {
  const {name, email, password, occupation, class_type} = payload;

  try {
    yield call(api.post, '/users', {
      name,
      email,
      password,
      occupation,
      class_type,
    });

    yield put(AuthActions.signUpSuccess());
  } catch (error) {
    yield put(AuthActions.signUpFailure(error.message));
  }
}

// Essa funcao nao eh assíncrona, por isso, ela nao precisa do "*"
function setToken({payload}) {
  if (!payload) return;

  const {token} = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

function signOut() {
  history.push('/');
  history.go();
}

export {signIn, signUp, signOut, setToken};
