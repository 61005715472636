export const Types = {
  CREATE_RESIDUE_REQUEST: '@residue/CREATE_RESIDUE_REQUEST',
  CREATE_RESIDUE_SUCCESS: '@residue/CREATE_RESIDUE_SUCCESS',
  CREATE_RESIDUE_FAILURE: '@residue/CREATE_RESIDUE_FAILURE',
  GET_RESIDUE_REQUEST: '@residue/GET_RESIDUE_REQUEST',
  GET_RESIDUE_SUCCESS: '@residue/GET_RESIDUE_SUCCESS',
  GET_RESIDUE_FAILURE: '@residue/GET_RESIDUE_FAILURE',
  UPDATE_RESIDUE_REQUEST: '@residue/UPDATE_RESIDUE_REQUEST',
  UPDATE_RESIDUE_SUCCESS: '@residue/UPDATE_RESIDUE_SUCCESS',
  UPDATE_RESIDUE_FAILURE: '@residue/UPDATE_RESIDUE_FAILURE',
  DELETE_RESIDUE_REQUEST: '@residue/DELETE_RESIDUE_REQUEST',
  DELETE_RESIDUE_SUCCESS: '@residue/DELETE_RESIDUE_SUCCESS',
  DELETE_RESIDUE_FAILURE: '@residue/DELETE_RESIDUE_FAILURE',
};
