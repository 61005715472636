import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as ResidueGlobalConstraintActions} from '../../ducks/residue/globalConstraints/actions';

function* createResidueGlobalConstraint({payload}) {
  const {
    amount_balloon_powder,
    amount_coal,
    coal_ash_percentage,
    amount_pci,
    pci_ash_percentage,
  } = payload.data;

  try {
    const response = yield call(api.post, '/residues/globalconstraints', {
      amount_balloon_powder,
      amount_coal,
      coal_ash_percentage,
      amount_pci,
      pci_ash_percentage,
    });

    yield put(
      ResidueGlobalConstraintActions.createResidueSuccess(response.data)
    );
  } catch (error) {
    yield put(
      ResidueGlobalConstraintActions.createResidueFailure(error.message)
    );
  }
}

function* getResidueGlobalConstraint() {
  try {
    const response = yield call(api.get, '/residues/globalconstraints');
    yield put(ResidueGlobalConstraintActions.getResidueSuccess(response.data));
  } catch (error) {
    yield put(ResidueGlobalConstraintActions.getResidueFailure(error.message));
  }
}

function* updateResidueGlobalConstraint({payload}) {
  try {
    const {
      id,
      amount_balloon_powder,
      amount_coal,
      coal_ash_percentage,
      amount_pci,
      pci_ash_percentage,
    } = payload.data;

    const residue = {
      amount_balloon_powder,
      amount_coal,
      coal_ash_percentage,
      amount_pci,
      pci_ash_percentage,
    };

    const {data} = yield call(
      api.patch,
      `/residues/globalconstraints/${id}`,
      residue
    );

    const residueUpdated = {
      id: data.id,
      amount_balloon_powder: data.amount_balloon_powder,
      amount_coal: data.amount_coal,
      coal_ash_percentage: data.coal_ash_percentage,
      amount_pci: data.amount_pci,
      pci_ash_percentage: data.pci_ash_percentage,
    };

    yield put(
      ResidueGlobalConstraintActions.updateResidueSuccess(id, residueUpdated)
    );
  } catch (error) {
    yield put(
      ResidueGlobalConstraintActions.updateResidueFailure(error.message)
    );
  }
}

function* deleteResidueGlobalConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/residues/globalconstraints/${id}`);

    yield put(ResidueGlobalConstraintActions.deleteResidueSuccess(id));
  } catch (error) {
    yield put(
      ResidueGlobalConstraintActions.deleteResidueFailure(error.message)
    );
  }
}

export {
  createResidueGlobalConstraint,
  getResidueGlobalConstraint,
  updateResidueGlobalConstraint,
  deleteResidueGlobalConstraint,
};
