export const Types = {
  CREATE_RESIDUEGLOBALCONSTRAINT_REQUEST: '@residue/CREATE_RESIDUEGLOBALCONSTRAINT_REQUEST',
  CREATE_RESIDUEGLOBALCONSTRAINT_SUCCESS: '@residue/CREATE_RESIDUEGLOBALCONSTRAINT_SUCCESS',
  CREATE_RESIDUEGLOBALCONSTRAINT_FAILURE: '@residue/CREATE_RESIDUEGLOBALCONSTRAINT_FAILURE',
  GET_RESIDUEGLOBALCONSTRAINT_REQUEST: 'residue/GET_RESIDUEGLOBALCONSTRAINT_REQUEST',
  GET_RESIDUEGLOBALCONSTRAINT_SUCCESS: 'residue/GET_RESIDUEGLOBALCONSTRAINT_SUCCESS',
  GET_RESIDUEGLOBALCONSTRAINT_FAILURE: 'residue/GET_RESIDUEGLOBALCONSTRAINT_FAILURE',
  UPDATE_RESIDUEGLOBALCONSTRAINT_REQUEST: '@residue/UPDATE_RESIDUEGLOBALCONSTRAINT_REQUEST',
  UPDATE_RESIDUEGLOBALCONSTRAINT_SUCCESS: '@residue/UPDATE_RESIDUEGLOBALCONSTRAINT_SUCCESS',
  UPDATE_RESIDUEGLOBALCONSTRAINT_FAILURE: '@residue/UPDATE_RESIDUEGLOBALCONSTRAINT_FAILURE',
  DELETE_RESIDUEGLOBALCONSTRAINT_REQUEST: '@residue/DELETE_RESIDUEGLOBALCONSTRAINT_REQUEST',
  DELETE_RESIDUEGLOBALCONSTRAINT_SUCCESS: '@residue/DELETE_RESIDUEGLOBALCONSTRAINT_SUCCESS',
  DELETE_RESIDUEGLOBALCONSTRAINT_FAILURE: '@residue/DELETE_RESIDUEGLOBALCONSTRAINT_FAILURE',
};
