import React from 'react';
import {MdClose} from 'react-icons/md';

import {Container, Header, Title, Content} from './styles';
import {ButtonCloseForm} from '../../assets/styles/CommonStyled';

import {colors} from '../../assets/styles';

const LateralModal = ({open, onClose, title, children}) => {
  return (
    <Container open={open}>
      <Header>
        <Title>{title}</Title>
        <ButtonCloseForm onClick={() => onClose(false)}>
          <MdClose size={25} color={colors.primary} />
        </ButtonCloseForm>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default LateralModal;
