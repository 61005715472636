/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEdit, MdDelete, MdArrowForward, MdArrowBack} from 'react-icons/md';

import animationButtonAdd from '../../../../assets/lotties/button_add.json';
import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  HorizontalArrow,
  ContainerTable,
  ButtonItem,
  TdStatus,
  MenuBottomContainer,
  MenuBottomContent,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {Content, ContentInRow, ButtonContainer} from './styles';

import {colors} from '../../../../assets/styles';

import Input from '../../../../components/Input';
import SimpleSelect from '../../../../components/Select';
import LateralModal from '../../../../components/LateralModal';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import Menu from '../../../../components/Menu';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as FondantActions} from '../../../../store/ducks/fondant/constraints/actions';

import {useToast} from '../../../../contexts/ToastContext';

import {
  formatPrice,
  formatAmount,
  formatPercentage,
} from '../../../../utils/format';

import useKeys from '../../../../hooks/useKeys';

const FondantConstraint = () => {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {fondants} = useSelector((state) => state.fondantRegister);
  const {fondantsConstraints} = useSelector((state) => state.fondantConstraint);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [fondantToEdit, setFondantToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  const initialValuesInput = {
    status_constraint: 'DISPONIVEL',
    name: fondants.length !== 0 ? fondants[0].name : '',
    min_amount: 0,
    max_amount: 600,
    composition: {
      Fe_original: 0,
      SiO2_original: 0,
      Al2O3_original: 0,
      CaOMgO_original: 0,
      Mn_original: 0,
      P_original: 0,
      PPC_original: 0,
      H2O_original: 0,
    },
  };

  const [values, setValues] = useState(initialValuesInput);

  const getFondants = () => {
    setLoadingPage(true);

    try {
      dispatch(FondantActions.getFondantRequest());
      setTimeout(() => {
        setLoadingPage(false);
      }, 1000);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getFondants();
  }, []);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(FondantActions.deleteFondantRequest(Number(event.id)));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  const setFormToAdd = (show) => {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
    setFondantToEdit(null);
  };

  const setFormToEdit = (show, fondant) => {
    const {status_constraint, min_amount, max_amount, composition} = fondant;

    setValues({
      name: fondant?.fondants?.name,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    });

    setFondantToEdit(fondant);
    setShowFormToEdit(show);
    setActionAllowed(false);
  };

  const onCloseForms = () => {
    setFormToAdd(false);
    setShowFormToEdit(false);
    setValues(initialValuesInput);
    setFondantToEdit(null);
  };

  const schemaYup = Yup.object().shape({
    min_amount: Yup.number().required(
      'É necessário informar a quantidade mínima do fundente'
    ),
    max_amount: Yup.number()
      .when('min_amount', (min_amount, schema) =>
        schema.min(
          min_amount,
          'A quantidade máxima deve ser maior que a mínima'
        )
      )
      .required(),
    Fe_original: Yup.number().required(
      'É necessário informar a taxa de Fe no fundente'
    ),
    SiO2_original: Yup.number().required(
      'É necessário informar a taxa de SiO2 no fundente'
    ),
    Al2O3_original: Yup.number().required(
      'É necessário informar a taxa de Al2O3 no fundente'
    ),
    CaOMgO_original: Yup.number().required(
      'É necessário informar a taxa de CaOMgO no fundente'
    ),
    Mn_original: Yup.number().required(
      'É necessário informar a taxa de Mn no fundente'
    ),
    P_original: Yup.number().required(
      'É necessário informar a taxa de P no fundente'
    ),
    PPC_original: Yup.number().required(
      'É necessário informar a taxa de PPC no fundente'
    ),
    H2O_original: Yup.number().required(
      'É necessário informar a taxa de umidade no fundente'
    ),
  });

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const fondantId = fondants.filter(
          (fondant) => fondant?.name === data.name
        );
        if (fondantId.length === 0) return;

        const newFondant = {
          fondant_id: Number(fondantId[0].id),
          fondants: {
            name: fondantId[0].name,
            price: Number(fondantId[0].price),
          },
          status_constraint: data.status_constraint,
          min_amount: Number(data.min_amount),
          max_amount: Number(data.max_amount),
          composition: {
            Fe_original: Number(data.Fe_original),
            SiO2_original: Number(data.SiO2_original),
            Al2O3_original: Number(data.Al2O3_original),
            CaOMgO_original: Number(data.CaOMgO_original),
            Mn_original: Number(data.Mn_original),
            P_original: Number(data.P_original),
            PPC_original: Number(data.PPC_original),
            H2O_original: Number(data.H2O_original),
          },
        };

        dispatch(FondantActions.createFondantRequest(newFondant));

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const fondantId = fondants.filter(
          (fondant) => fondant?.name === data.name
        );
        if (fondantId.length === 0) return;

        const fondantUpdated = {
          id: Number(fondantToEdit.id),
          fondant_id: Number(fondantId[0].id),
          fondants: {
            name: fondantId[0].name,
            price: Number(fondantId[0].price),
          },
          status_constraint: data.status_constraint,
          min_amount: Number(data.min_amount),
          max_amount: Number(data.max_amount),
          composition: {
            Fe_original: Number(data.Fe_original),
            SiO2_original: Number(data.SiO2_original),
            Al2O3_original: Number(data.Al2O3_original),
            CaOMgO_original: Number(data.CaOMgO_original),
            Mn_original: Number(data.Mn_original),
            P_original: Number(data.P_original),
            PPC_original: Number(data.PPC_original),
            H2O_original: Number(data.H2O_original),
          },
        };

        dispatch(FondantActions.updateFondantRequest(fondantUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemoveFondant = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `O registro será removido do banco de dados do sistema e esta ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  useEffect(() => {
    if (leftArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/sucatas/cadastro');
    }
    if (rightArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/residuos/cadastro');
    }
  }, [leftArrow, rightArrow, showFormToAdd, showFormToEdit]);

  return (
    <>
      <Container>
        <Menu />
        <HorizontalArrow>
          <Link to="/sucatas/cadastro">
            <div className="icon">
              <MdArrowBack />
            </div>
          </Link>
        </HorizontalArrow>
        <HorizontalArrow anchor="right">
          <Link to="/residuos/cadastro">
            <div className="icon">
              <MdArrowForward />
            </div>
          </Link>
        </HorizontalArrow>

        {(showFormToAdd || showFormToEdit) && (
          <BackgroundOpacity
            className="overlay"
            onClick={() => onCloseForms()}
          />
        )}

        <LateralModal
          open={showFormToAdd || showFormToEdit}
          title={showFormToAdd ? 'Novo Registro' : 'Editar Registro'}
          onClose={showFormToAdd ? setShowFormToAdd : setShowFormToEdit}>
          <Content>
            <Form
              initialData={showFormToAdd ? values : fondantToEdit}
              ref={showFormToAdd ? formRefAdd : formRefEdit}
              onSubmit={showFormToAdd ? handleAddForm : handleEditForm}>
              <ContentInRow>
                <label htmlFor="status_constraint">
                  <strong>Status:</strong>
                </label>
                <SimpleSelect
                  name="status_constraint"
                  value={values?.status_constraint || ''}
                  options={[
                    {value: 'DISPONIVEL', label: 'DISPONIVEL'},
                    {value: 'INDISPONIVEL', label: 'INDISPONIVEL'},
                    {value: 'USANDO', label: 'USANDO'},
                  ]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      status_constraint: e.target.value,
                    })
                  }
                />
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="name">
                  <strong>Nome:</strong>
                </label>
                <ContentInput className="input-block">
                  <SimpleSelect
                    name="name"
                    value={values?.name || ''}
                    options={fondants.map((fondant) => {
                      return {value: fondant?.id, label: fondant?.name};
                    })}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        name: e.target.value,
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <strong>Quantidade (kg): </strong>
              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="min_amount">Mínima:</label>
                  <Input
                    name="min_amount"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    min="0"
                    placeholder="0"
                    value={values?.min_amount || 0}
                    onChange={(e) =>
                      setValues({...values, min_amount: e.target.value})
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="max_amount">Máxima:</label>
                  <Input
                    name="max_amount"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    min="0"
                    placeholder="600"
                    value={values?.max_amount || 0}
                    onChange={(e) =>
                      setValues({...values, max_amount: e.target.value})
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <strong>Composição química (%): </strong>
              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="Fe_original">Fe:</label>
                  <Input
                    name="Fe_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.Fe_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          Fe_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="SiO2_original">SiO2:</label>
                  <Input
                    name="SiO2_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.SiO2_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          SiO2_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="Al2O3_original">Al2O3:</label>
                  <Input
                    name="Al2O3_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.Al2O3_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          Al2O3_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="CaOMgO_original">CaO+MgO:</label>
                  <Input
                    name="CaOMgO_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.CaOMgO_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          CaOMgO_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="Mn_original">Mn:</label>
                  <Input
                    name="Mn_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.Mn_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          Mn_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="P_original">P:</label>
                  <Input
                    name="P_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.P_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          P_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="PPC_original">PPC:</label>
                  <Input
                    name="PPC_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.PPC_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          PPC_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="H2O_original">UMIDADE:</label>
                  <Input
                    name="H2O_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition?.H2O_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition: {
                          ...values?.composition,
                          H2O_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ButtonContainer>
                <ButtonSecondary type="submit" className="button_submit">
                  {loading ? (
                    <LottieAnimation
                      width={40}
                      height={40}
                      animation={animationLoadingButton}
                    />
                  ) : (
                    <p>{showFormToAdd ? 'Adicionar' : 'Salvar alterações'}</p>
                  )}
                </ButtonSecondary>
              </ButtonContainer>
            </Form>
          </Content>
        </LateralModal>

        <BoxContainer>
          <ContainerTitlePage className="container_title_page">
            <TitlePage className="title_page">Fundentes</TitlePage>
            {profile.occupation !== 'Analista de Laboratório' && (
              <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
                <LottieAnimation
                  width={50}
                  height={50}
                  animation={animationButtonAdd}
                />
                {widthWindow > 500 && <p>Adicionar</p>}
              </ContainerButtonAdd>
            )}
          </ContainerTitlePage>
          <ContainerTable>
            <Table>
              <TableHeader>
                <tr>
                  <th className="th-edit">Editar</th>
                  <th>STATUS</th>
                  <th>FUNDENTE</th>
                  <th>
                    Preço <br />
                    (R$/ton.)
                  </th>
                  <th>
                    Mínimo <br /> a usar(kg)
                  </th>
                  <th>
                    Máximo <br /> a usar(kg)
                  </th>
                  <th>%Fe</th>
                  <th>%SiO2</th>
                  <th>%Al2O3</th>
                  <th>%CaO+MgO</th>
                  <th>%Mn</th>
                  <th>%P</th>
                  <th>%PPC</th>
                  <th>%UMIDADE</th>
                  <th className="th-delete">Excluir</th>
                </tr>
              </TableHeader>

              {loadingPage ? (
                <TableBody>
                  <tr>
                    <td>
                      <BackgroundOpacity className="overlay">
                        <LottieAnimation
                          width={100}
                          height={100}
                          animation={animationLoadingPage}
                        />
                      </BackgroundOpacity>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {fondantsConstraints?.length !== 0 &&
                    fondantsConstraints.map((fondant) => (
                      <tr key={fondant.id}>
                        <td
                          className="td-edit"
                          onClick={() => setFormToEdit(true, fondant)}>
                          <MdEdit size={16} color={colors.primary} />
                        </td>
                        <TdStatus status={fondant?.status_constraint || ''}>
                          <div className="container-status">
                            <div className="circle-status-container">
                              <div className="circle-status" />
                            </div>
                            <div className="title-status">
                              <p>{fondant?.status_constraint || ''}</p>
                            </div>
                          </div>
                        </TdStatus>
                        <td>{fondant?.fondants?.name || ''}</td>
                        <td>{formatPrice(fondant?.fondants?.price || 0)}</td>
                        <td>
                          {formatAmount(fondant?.min_amount || 0, 0, '.', ',')}
                        </td>
                        <td>
                          {formatAmount(fondant?.max_amount || 0, 0, '.', ',')}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.Fe_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.SiO2_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.Al2O3_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.CaOMgO_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.Mn_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.P_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.PPC_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            fondant?.composition?.H2O_original || 0,
                            3,
                            '.',
                            ','
                          )}
                        </td>
                        <td
                          className="td-delete"
                          onClick={() => handleRemoveFondant(fondant?.id)}>
                          <MdDelete size={16} color={colors.failure} />
                        </td>
                      </tr>
                    ))}
                </TableBody>
              )}
            </Table>

            {fondantsConstraints.length === 0 && (
              <div className="container_information">
                <p>
                  Insira um novo registro clicando sobre o botão "Adicionar"
                </p>
              </div>
            )}
          </ContainerTable>
        </BoxContainer>
      </Container>

      <MenuBottomContainer>
        <MenuBottomContent>
          <ButtonItem className="btn-secondary">
            <Link to="/fundentes/cadastro">Cadastro de fundentes</Link>
          </ButtonItem>
          <ButtonItem className="btn-primary">
            <Link to="/fundentes/restricoes">Restrições de fundentes</Link>
          </ButtonItem>
        </MenuBottomContent>
      </MenuBottomContainer>
    </>
  );
};

export default FondantConstraint;
