import React from 'react';

import {Container, Title} from './styles';

function FrequencyControl() {
  return (
    <>
      <Container>
        <Title>Controle de frequência</Title>
      </Container>
    </>
  );
}

export default FrequencyControl;
