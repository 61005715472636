import {Types} from './types';

export const Actions = {
  createPanRequest: (data) => ({
    type: Types.CREATE_PAN_REQUEST,
    payload: {data},
  }),
  createPanSuccess: () => ({
    type: Types.CREATE_PAN_SUCCESS,
  }),
  createPanFailure: (error) => ({
    type: Types.CREATE_PAN_FAILURE,
    payload: {error},
  }),
  updatePanRequest: (data) => ({
    type: Types.UPDATE_PAN_REQUEST,
    payload: {data},
  }),
  updatePanSuccess: () => ({
    type: Types.UPDATE_PAN_SUCCESS,
  }),
  updatePanFailure: (error) => ({
    type: Types.UPDATE_PAN_FAILURE,
    payload: {error},
  }),
  deletePanRequest: (id) => ({
    type: Types.DELETE_PAN_REQUEST,
    payload: {id},
  }),
  deletePanSuccess: () => ({
    type: Types.DELETE_PAN_SUCCESS,
  }),
  deletePanFailure: (error) => ({
    type: Types.DELETE_PAN_FAILURE,
    payload: {error},
  }),
};
