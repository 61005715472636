import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as ProductActions} from '../../ducks/product/actions';

function* createProduct({payload}) {
  const {
    name,
    gusa_min,
    gusa_goal,
    slag_min,
    slag_max,
    ib_goal,
    ib_max,
    composition_gusa,
    composition_slag,
    criticities,
    global_weights,
  } = payload.data;

  try {
    const response = yield call(api.post, '/products', {
      name,
      gusa_min,
      gusa_goal,
      slag_min,
      slag_max,
      ib_goal,
      ib_max,
      composition_gusa,
      composition_slag,
      criticities,
      global_weights,
    });

    yield put(ProductActions.createProductSuccess(response.data));
  } catch (error) {
    yield put(ProductActions.createProductFailure(error.message));
  }
}

function* getProduct() {
  try {
    const response = yield call(api.get, '/products');
    yield put(ProductActions.getProductSuccess(response.data));
  } catch (error) {
    yield put(ProductActions.getProductFailure(error.message));
  }
}

function* updateProduct({payload}) {
  try {
    const {data} = yield call(
      api.patch,
      `/products/${payload.id}`,
      payload.data
    );

    const productUpdated = {
      id: data.id,
      ...data,
    };

    yield put(
      ProductActions.updateProductSuccess(productUpdated.id, productUpdated)
    );
  } catch (error) {
    yield put(ProductActions.updateProductFailure(error.message));
  }
}

function* deleteProduct({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/products/${id}`);

    yield put(ProductActions.deleteProductSuccess(id));
  } catch (error) {
    yield put(ProductActions.deleteProductFailure(error.message));
  }
}

export {createProduct, getProduct, updateProduct, deleteProduct};
