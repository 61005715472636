import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  residuesConstraints: [],
};

const residueConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_RESIDUECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_RESIDUECONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        residuesConstraints: [
          ...state.residuesConstraints,
          action.payload.data,
        ],
      };

    case Types.CREATE_RESIDUECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_RESIDUECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_RESIDUECONSTRAINT_SUCCESS:
      return {
        ...state,
        residuesConstraints: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_RESIDUECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_RESIDUECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_RESIDUECONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        residuesConstraints: [
          ...state.residuesConstraints.map((residue) =>
            residue.id === action.payload.id ? action.payload.data : residue
          ),
        ],
      };

    case Types.UPDATE_RESIDUECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_RESIDUECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_RESIDUECONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        residuesConstraints: [
          ...state.residuesConstraints.filter(
            (residue) => residue.id !== action.payload.id
          ),
        ],
      };

    case Types.DELETE_RESIDUECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default residueConstraint;
