import React from 'react';

import {Container, Title} from './styles';

function Settings() {
  return (
    <>
      <Container>
        <Title>Configurações</Title>
      </Container>
    </>
  );
}

export default Settings;
