import React from 'react';

import {DeviationContent, DeviationHeader, DeviationBody} from './styles';

import {formatAmount} from '../../../../utils/format';

const EspecificationsAndDeviations = ({
  headerTitle,
  bodyTitle1,
  bodyTitle2,
  disableSubtitles,
  Fe_1,
  Si_1,
  Al2O3_1,
  CaO_1,
  MgO_1,
  Mn_1,
  P_1,
  Fe_2,
  Si_2,
  Al2O3_2,
  CaO_2,
  MgO_2,
  Mn_2,
  P_2,
}) => {
  return (
    <DeviationContent>
      <DeviationHeader>
        <div className="deviation-content">
          <strong className="title-header">{headerTitle}</strong>
          <strong>{!disableSubtitles ? 'Fe' : ''}</strong>
          <strong>{!disableSubtitles ? 'Si' : ''}</strong>
          <strong>{!disableSubtitles ? 'Al2O3' : ''}</strong>
          <strong>{!disableSubtitles ? 'CaO' : ''}</strong>
          <strong>{!disableSubtitles ? 'MgO' : ''}</strong>
          <strong>{!disableSubtitles ? 'Mn' : ''}</strong>
          <strong>{!disableSubtitles ? 'P' : ''}</strong>
        </div>
      </DeviationHeader>

      <DeviationBody>
        <div className="deviation-content">
          <strong className="title-body">{bodyTitle1}</strong>
          <span>{`${formatAmount(Fe_1 || 0, 3, '.', ',')}`}</span>
          <span>{`${formatAmount(Si_1 || 0, 3, '.', ',')}`}</span>
          <span>{`${formatAmount(Al2O3_1 || 0, 3, '.', ',')}`}</span>
          <span>{`${formatAmount(CaO_1 || 0, 3, '.', ',')}`}</span>
          <span>{`${formatAmount(MgO_1 || 0, 3, '.', ',')}`}</span>
          <span>{`${formatAmount(Mn_1 || 0, 3, '.', ',')}`}</span>
          <span>{`${formatAmount(P_1 || 0, 3, '.', ',')}`}</span>
        </div>
      </DeviationBody>

      {bodyTitle2 && (
        <DeviationBody>
          <div className="deviation-content">
            <strong className="title-body">{bodyTitle2}</strong>
            <span>{`${formatAmount(Fe_2 || 0, 3, '.', ',')}`}</span>
            <span>{`${formatAmount(Si_2 || 0, 3, '.', ',')}`}</span>
            <span>{`${formatAmount(Al2O3_2 || 0, 3, '.', ',')}`}</span>
            <span>{`${formatAmount(CaO_2 || 0, 3, '.', ',')}`}</span>
            <span>{`${formatAmount(MgO_2 || 0, 3, '.', ',')}`}</span>
            <span>{`${formatAmount(Mn_2 || 0, 3, '.', ',')}`}</span>
            <span>{`${formatAmount(P_2 || 0, 3, '.', ',')}`}</span>
          </div>
        </DeviationBody>
      )}
    </DeviationContent>
  );
};

export default EspecificationsAndDeviations;
