// Zoom
export const ZOOM_25_BREAK_POINT = 2780;
export const ZOOM_33_BREAK_POINT = 2085;
export const ZOOM_50_BREAK_POINT = 1390;
export const ZOOM_67_BREAK_POINT = 1042.5;
export const ZOOM_75_BREAK_POINT = 926.67;
export const ZOOM_80_BREAK_POINT = 868.75;
export const ZOOM_90_BREAK_POINT = 772.22;
export const ZOOM_100_BREAK_POINT = 695;
export const ZOOM_110_BREAK_POINT = 631.82;
export const ZOOM_125_BREAK_POINT = 556;
export const ZOOM_150_BREAK_POINT = 466.33;
export const ZOOM_175_BREAK_POINT = 397.14;
export const ZOOM_200_BREAK_POINT = 347.5;

// Media queries break points
export const MAX_SIZE_MOBILE = 540;
export const MIN_SIZE_DESKTOP_1 = 720;
export const MIN_SIZE_DESKTOP_2 = 900;
