import React from 'react';

import {ToastProvider} from './ToastContext';
import {SidebarProvider} from './SidebarContext';
import {HeaderProvider} from './HeaderContext';

const AppProvider = ({children}) => {
  return (
    <ToastProvider>
      <SidebarProvider>
        <HeaderProvider>{children}</HeaderProvider>
      </SidebarProvider>
    </ToastProvider>
  );
};

export default AppProvider;
