import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as WasteConstraintActions} from '../../ducks/waste/constraints/actions';

function* createWasteConstraint({payload}) {
  const {
    waste_id,
    wastes,
    status_constraint,
    min_amount,
    max_amount,
    composition,
  } = payload.data;

  try {
    const response = yield call(api.post, '/wastes/constraints', {
      waste_id,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    });

    const newData = {
      ...response.data,
      wastes,
    };

    yield put(WasteConstraintActions.createWasteSuccess(newData));
  } catch (error) {
    yield put(WasteConstraintActions.createWasteFailure(error.message));
  }
}

function* getWasteConstraint() {
  try {
    const response = yield call(api.get, '/wastes/constraints');
    yield put(WasteConstraintActions.getWasteSuccess(response.data));
  } catch (error) {
    yield put(WasteConstraintActions.getWasteFailure(error.message));
  }
}

function* updateWasteConstraint({payload}) {
  try {
    const {
      id,
      waste_id,
      wastes,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    } = payload.data;

    const waste = {
      waste_id,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    };

    const {data} = yield call(api.put, `/wastes/constraints/${id}`, waste);

    const wasteUpdated = {
      id: data.id,
      waste_id: data.waste_id,
      wastes,
      status_constraint: data.status_constraint,
      min_amount: data.min_amount,
      max_amount: data.max_amount,
      composition: data.composition,
    };

    yield put(WasteConstraintActions.updateWasteSuccess(id, wasteUpdated));
  } catch (error) {
    yield put(WasteConstraintActions.updateWasteFailure(error.message));
  }
}

function* deleteWasteConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/wastes/constraints/${id}`);

    yield put(WasteConstraintActions.deleteWasteSuccess(id));
  } catch (error) {
    yield put(WasteConstraintActions.deleteWasteFailure(error.message));
  }
}

export {
  createWasteConstraint,
  getWasteConstraint,
  updateWasteConstraint,
  deleteWasteConstraint,
};
