/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, {useState, useEffect, useRef, useCallback} from 'react';

import {Form} from '@unform/web';
import * as Yup from 'yup';

import {useDispatch, useSelector} from 'react-redux';

import {MdEdit, MdCheck, MdClose} from 'react-icons/md';

import Input from '../../../../components/Input';

import {colors} from '../../../../assets/styles';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as ResidueActions} from '../../../../store/ducks/residue/globalConstraints/actions';

import {formatPercentage} from '../../../../utils/format';

import {useToast} from '../../../../contexts/ToastContext';

import {
  WrapperConstraint,
  ConstraintContainer,
  ConstraintContent,
  ConstraintLabel,
  ConstraintValue,
  ContainerEditForm,
  ButtonEditForm,
} from '../../../../assets/styles/CommonStyled';

import {ContainerInput} from './styles';

const ResidueGlobalConstraint = ({open = false, onClose}) => {
  const dispatch = useDispatch();
  const {residuesGlobalConstraints} = useSelector(
    (state) => state.residueGlobalConstraint
  );
  const {addToast} = useToast();
  const formRefEdit = useRef(null);

  const initialValues = {
    amount_balloon_powder:
      residuesGlobalConstraints?.[0]?.amount_balloon_powder || 80,
    amount_coal: residuesGlobalConstraints?.[0]?.amount_coal || 750,
    coal_ash_percentage:
      residuesGlobalConstraints?.[0]?.coal_ash_percentage || 2.5,
    amount_pci: residuesGlobalConstraints?.[0]?.amount_pci || 0,
    pci_ash_percentage: residuesGlobalConstraints?.[0]?.pci_ash_percentage || 0,
  };

  const [values, setValues] = useState(initialValues);

  const [loading, setLoading] = useState(false);

  const schemaYup = Yup.object().shape({
    amount_balloon_powder: Yup.number().required(
      'Informe a quantidade de pó de balão'
    ),
    amount_coal: Yup.number().required('Informe a quantidade de carvão'),
    coal_ash_percentage: Yup.number().required(
      'Informe a porcentagem de cinzas no carvão'
    ),
    amount_pci: Yup.number().required('Informe a quantidade de cinzas no PCI'),
    pci_ash_percentage: Yup.number().required(
      'Informe a porcentagem de cinzas no PCI'
    ),
  });

  const getResidues = () => {
    setLoading(true);

    try {
      dispatch(ResidueActions.getResidueRequest());
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getResidues();
  }, []);

  useEffect(() => {
    if (residuesGlobalConstraints?.length !== 0) {
      setValues({
        amount_balloon_powder:
          residuesGlobalConstraints[0].amount_balloon_powder,
        amount_coal: residuesGlobalConstraints[0].amount_coal,
        coal_ash_percentage: residuesGlobalConstraints[0].coal_ash_percentage,
        amount_pci: residuesGlobalConstraints[0].amount_pci,
        pci_ash_percentage: residuesGlobalConstraints[0].pci_ash_percentage,
      });
    }
  }, [residuesGlobalConstraints]);

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const residueUpdated = {
          id: Number(residuesGlobalConstraints?.[0]?.id || 1),
          amount_balloon_powder: Number(data.amount_balloon_powder),
          amount_coal: Number(data.amount_coal),
          coal_ash_percentage: Number(data.coal_ash_percentage),
          amount_pci: Number(data.amount_pci),
          pci_ash_percentage: Number(data.pci_ash_percentage),
        };

        dispatch(ResidueActions.updateResidueRequest(residueUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setLoading(false);
    },
    [addToast, dispatch]
  );

  const onCloseForms = () => {
    onClose(false);
    setValues(initialValues);
  };

  return (
    <Form initialData={values} ref={formRefEdit} onSubmit={handleEditForm}>
      <WrapperConstraint>
        {!open ? (
          <ContainerEditForm>
            <MdEdit
              size={16}
              color={colors.primary}
              onClick={() => onClose(true)}
            />
          </ContainerEditForm>
        ) : (
          <ButtonEditForm type="submit">
            <MdCheck size={16} color={colors.primary} />
          </ButtonEditForm>
        )}

        <ConstraintContainer>
          <ConstraintContent>
            <ConstraintLabel>Quantidade de pó de balão (kg)</ConstraintLabel>
            {!open ? (
              <ConstraintValue>{`${values.amount_balloon_powder}`}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="amount_balloon_powder"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.amount_balloon_powder || 0}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      amount_balloon_powder: e.target.value,
                    })
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>
              Quant. de carvão (kg carvão / ton. gusa)
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>{`${values.amount_coal}`}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="amount_coal"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.amount_coal || 0}
                  onChange={(e) =>
                    setValues({...values, amount_coal: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>Cinzas no carvão (%)</ConstraintLabel>
            {!open ? (
              <ConstraintValue>
                {formatPercentage(values.coal_ash_percentage, 2, '.', ',')}
              </ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="coal_ash_percentage"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.coal_ash_percentage || 0}
                  onChange={(e) =>
                    setValues({...values, coal_ash_percentage: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>
              Quantidade de PCI (kg PCI / ton. gusa)
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>{`${values.amount_pci}`}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="amount_pci"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.amount_pci || 0}
                  onChange={(e) =>
                    setValues({...values, amount_pci: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>Cinzas no PCI (%)</ConstraintLabel>
            {!open ? (
              <ConstraintValue>
                {formatPercentage(values.pci_ash_percentage, 2, '.', ',')}
              </ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="pci_ash_percentage"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.pci_ash_percentage || 0}
                  onChange={(e) =>
                    setValues({...values, pci_ash_percentage: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>
        </ConstraintContainer>

        {open && (
          <ContainerEditForm>
            <MdClose
              size={16}
              color={colors.primary}
              onClick={() => onCloseForms()}
            />
          </ContainerEditForm>
        )}
      </WrapperConstraint>
    </Form>
  );
};

export default ResidueGlobalConstraint;
