import styled from 'styled-components';
import colors from '../../assets/styles/colors';
import {MIN_SIZE_DESKTOP_2} from '../../constants/BREAKPOINTS';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 10rem;
  background: ${colors.primary};

  ul {
    list-style: none;
    position: relative;
    z-index: 3;
  }

  ul li {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
    border-bottom: 1px solid ${colors.secondary};
  }

  ul li a {
    margin: 0.4rem 0.6rem;
    color: ${colors.secondary};
    font-weight: bold;
    cursor: pointer;
  }

  ul li a:hover {
    color: ${colors.secondary};
  }

  ul li a:focus-within {
    color: ${colors.secondary};
  }

  .active {
    border-bottom: 5px solid ${colors.secondary};
    border-radius: 3px;
  }

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    display: none;
  }
`;

export const Title = styled.p`
  z-index: 2;
`;
