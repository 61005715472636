import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  wastes: [],
  lotes: [],
};

const wasteRegister = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_WASTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_WASTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wastes: [...state.wastes, action.payload.data],
        lotes: [...state.lotes, Number(action.payload.data.lote_number)],
      };

    case Types.CREATE_WASTE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_WASTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_WASTE_SUCCESS:
      return {
        ...state,
        wastes: action.payload.data,
        lotes: action.payload.data.map((wastee) => wastee.lote_number),
        loading: false,
        error: null,
      };

    case Types.GET_WASTE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_WASTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_WASTE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wastes: [
          ...state.wastes.map((wastee) =>
            wastee.id === action.payload.id ? action.payload.data : wastee
          ),
        ],
        lotes: [
          ...state.wastes.map((wastee) =>
            wastee.id === action.payload.id
              ? Number(action.payload.data.lote_number)
              : Number(wastee.lote_number)
          ),
        ],
      };

    case Types.UPDATE_WASTE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_WASTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_WASTE_SUCCESS:
      return {
        loading: false,
        error: null,
        wastes: [
          ...state.wastes.filter((wastee) => wastee.id !== action.payload.id),
        ],
        lotes: [
          ...state.wastes
            .filter((wastee) => wastee.id !== action.payload.id)
            .map((lote) => Number(lote.lote_number)),
        ],
      };

    case Types.DELETE_WASTE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default wasteRegister;
