import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as ResidueConstraintActions} from '../../ducks/residue/constraints/actions';

function* createResidueConstraint({payload}) {
  const {residue_id, residues, composition} = payload.data;

  try {
    const response = yield call(api.post, '/residues/constraints', {
      residue_id,
      composition,
    });

    const newData = {
      ...response.data,
      residues,
    };

    yield put(ResidueConstraintActions.createResidueSuccess(newData));
  } catch (error) {
    yield put(ResidueConstraintActions.createResidueFailure(error.message));
  }
}

function* getResidueConstraint() {
  try {
    const response = yield call(api.get, '/residues/constraints');
    yield put(ResidueConstraintActions.getResidueSuccess(response.data));
  } catch (error) {
    yield put(ResidueConstraintActions.getResidueFailure(error.message));
  }
}

function* updateResidueConstraint({payload}) {
  try {
    const {id, residue_id, residues, composition} = payload.data;

    const residue = {
      residue_id,
      composition,
    };

    const {data} = yield call(
      api.patch,
      `/residues/constraints/${id}`,
      residue
    );

    const residueUpdated = {
      id: data.id,
      residue_id: data.residue_id,
      residues,
      composition: data.composition,
    };

    yield put(
      ResidueConstraintActions.updateResidueSuccess(id, residueUpdated)
    );
  } catch (error) {
    yield put(ResidueConstraintActions.updateResidueFailure(error.message));
  }
}

function* deleteResidueConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/residues/constraints/${id}`);

    yield put(ResidueConstraintActions.deleteResidueSuccess(id));
  } catch (error) {
    yield put(ResidueConstraintActions.deleteResidueFailure(error.message));
  }
}

export {
  createResidueConstraint,
  getResidueConstraint,
  updateResidueConstraint,
  deleteResidueConstraint,
};
