import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as SiloActions} from '../../ducks/silo/actions';

function* createSilo({payload}) {
  const {
    date,
    user_id,
    silo_number,
    rpm_used,
    measured_time,
    mass_found,
    mass_objective,
    rpm_calc,
  } = payload.data;

  try {
    yield call(api.post, '/silos', {
      date,
      user_id,
      silo_number,
      rpm_used,
      measured_time,
      mass_found,
      mass_objective,
      rpm_calc,
    });

    yield put(SiloActions.createSiloSuccess());
  } catch (error) {
    yield put(SiloActions.createSiloFailure(error.message));
  }
}

function* updateSilo({payload}) {
  try {
    const {
      id,
      date,
      silo_number,
      rpm_used,
      measured_time,
      mass_found,
      mass_objective,
      rpm_calc,
    } = payload.data;

    const silo = {
      date,
      silo_number,
      rpm_used,
      measured_time,
      mass_found,
      mass_objective,
      rpm_calc,
    };

    yield call(api.put, `/silos/${id}`, silo);

    yield put(SiloActions.updateSiloSuccess());
  } catch (error) {
    yield put(SiloActions.updateSiloFailure(error.message));
  }
}

function* deleteSilo({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/silos/${id}`);

    yield put(SiloActions.deleteSiloSuccess());
  } catch (error) {
    yield put(SiloActions.deleteSiloFailure(error.message));
  }
}
export {createSilo, updateSilo, deleteSilo};
