import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  leitos: [],
};

const leito = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_LEITO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_LEITO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        leitos: [...state.leitos, action.payload.data],
      };

    case Types.CREATE_LEITO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_LEITO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_LEITO_SUCCESS:
      return {
        ...state,
        leitos: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_LEITO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_LEITO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_LEITO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        leitos: [
          ...state.leitos.map((leitoo) =>
            leitoo.id === action.payload.id ? action.payload.data : leitoo
          ),
        ],
      };

    case Types.UPDATE_LEITO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_LEITO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_LEITO_SUCCESS:
      return {
        loading: false,
        error: null,
        leitos: [
          ...state.leitos.filter((leitoo) => leitoo.id !== action.payload.id),
        ],
      };

    case Types.DELETE_LEITO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default leito;
