/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEdit, MdDelete, MdArrowForward, MdArrowBack} from 'react-icons/md';

import animationButtonAdd from '../../../../assets/lotties/button_add.json';
import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  HorizontalArrow,
  ContainerTable,
  DatePickerStyled,
  ButtonItem,
  TdStatus,
  MenuBottomContainer,
  MenuBottomContent,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {Content, ContentInRow, ButtonContainer} from './styles';

import CalendarContainer from '../../../../components/Container/CalendarContainer';

import {colors} from '../../../../assets/styles';

import Input from '../../../../components/Input';
import SimpleSelect from '../../../../components/Select';
import LateralModal from '../../../../components/LateralModal';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import Menu from '../../../../components/Menu';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as WasteActions} from '../../../../store/ducks/waste/register/actions';

import {useToast} from '../../../../contexts/ToastContext';

import {formatPrice, formatAmount} from '../../../../utils/format';

import {formatDateToShow} from '../../../../utils/date';

import useKeys from '../../../../hooks/useKeys';

const WasteRegister = () => {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {wastes} = useSelector((state) => state.wasteRegister);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [wasteToEdit, setWasteToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  const initialValuesInput = {
    status: 'FECHADO',
    name: '',
    price: 0,
    mass_available: 0,
    bought_in: new Date(),
  };

  const [values, setValues] = useState(initialValuesInput);

  const getWastes = () => {
    setLoadingPage(true);

    try {
      dispatch(WasteActions.getWasteRequest());
      setTimeout(() => {
        setLoadingPage(false);
      }, 1000);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getWastes();
  }, []);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(WasteActions.deleteWasteRequest(Number(event.id)));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  const setFormToAdd = (show) => {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
    setWasteToEdit(null);
  };

  const setFormToEdit = (show, waste) => {
    const {status, name, price, mass_available, bought_in} = waste;

    setValues({
      status,
      name,
      price,
      mass_available,
      bought_in: new Date(bought_in),
    });

    setWasteToEdit(waste);
    setShowFormToEdit(show);
    setActionAllowed(false);
  };

  const onCloseForms = () => {
    setFormToAdd(false);
    setShowFormToEdit(false);
    setValues(initialValuesInput);
    setWasteToEdit(null);
  };

  const schemaYup = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Mínimo 3 caracteres')
      .required('É necessário informar o nome da sucata'),
    price: Yup.number().required('É necessário informar o preço da sucata'),

    mass_available: Yup.number().required(
      'É necessário informar a quantidade disponível da sucata'
    ),
  });

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const newWaste = {
          status: data.status,
          name: data.name,
          price: Number(data.price),
          mass_available: Number(data.mass_available),
          bought_in: values.bought_in,
        };

        dispatch(WasteActions.createWasteRequest(newWaste));

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const wasteUpdated = {
          id: Number(wasteToEdit.id),
          status: data.status,
          name: data.name,
          price: Number(data.price),
          mass_available: Number(data.mass_available),
          bought_in: values.bought_in,
        };

        dispatch(WasteActions.updateWasteRequest(wasteUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemoveWaste = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `O registro será removido do banco de dados do sistema e esta ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  useEffect(() => {
    if (leftArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/minerios/cadastro');
    }
    if (rightArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/fundentes/cadastro');
    }
  }, [leftArrow, rightArrow, showFormToAdd, showFormToEdit]);

  return (
    <>
      <Container>
        <Menu />
        <HorizontalArrow>
          <Link to="/minerios/cadastro">
            <div className="icon">
              <MdArrowBack />
            </div>
          </Link>
        </HorizontalArrow>
        <HorizontalArrow anchor="right">
          <Link to="/fundentes/cadastro">
            <div className="icon">
              <MdArrowForward />
            </div>
          </Link>
        </HorizontalArrow>

        {(showFormToAdd || showFormToEdit) && (
          <BackgroundOpacity
            className="overlay"
            onClick={() => onCloseForms()}
          />
        )}

        <LateralModal
          open={showFormToAdd || showFormToEdit}
          title={showFormToAdd ? 'Novo Registro' : 'Editar Registro'}
          onClose={showFormToAdd ? setShowFormToAdd : setShowFormToEdit}>
          <Content>
            <Form
              initialData={showFormToAdd ? values : wasteToEdit}
              ref={showFormToAdd ? formRefAdd : formRefEdit}
              onSubmit={showFormToAdd ? handleAddForm : handleEditForm}>
              <ContentInput className="input-block">
                <label htmlFor="name">
                  <strong>Nome:</strong>
                </label>
                <Input
                  name="name"
                  type="text"
                  placeholder="SUCATA DE GUSA"
                  value={values.name.toUpperCase().trim()}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      name: e.target.value.toUpperCase().trim(),
                    })
                  }
                />
              </ContentInput>
              <LineHorizontal />

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="bought_in">
                    <strong>Data da compra:</strong>
                  </label>
                  <DatePickerStyled>
                    <DatePicker
                      name="bought_in"
                      selected={values.bought_in}
                      onChange={(bought_in) =>
                        setValues({...values, bought_in})
                      }
                      customInput={<input className="input-datapicker" />}
                      dateFormat="dd/MM/yyyy"
                      locale="pt"
                      calendarContainer={CalendarContainer}
                    />
                  </DatePickerStyled>
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="status" className="label-status">
                    <strong>Status:</strong>
                  </label>
                  <SimpleSelect
                    name="status"
                    value={values.status}
                    options={[
                      {value: 'FECHADO', label: 'FECHADO'},
                      {value: 'ABERTO', label: 'ABERTO'},
                      {value: 'ESGOTADO', label: 'ESGOTADO'},
                    ]}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        status: e.target.value,
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ContentInput className="input-block">
                <label htmlFor="mass_available">
                  <strong>Massa disponível (ton):</strong>
                </label>
                <Input
                  name="mass_available"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  min="0"
                  placeholder="0"
                  value={values.mass_available}
                  onChange={(e) =>
                    setValues({...values, mass_available: e.target.value})
                  }
                />
              </ContentInput>
              <LineHorizontal />

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="price">
                    <strong>Preço (R$/ton):</strong>
                  </label>
                  <Input
                    name="price"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step="0.01"
                    min="0"
                    placeholder="R$ 100,00"
                    value={values.price}
                    onChange={(e) =>
                      setValues({...values, price: e.target.value})
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ButtonContainer>
                <ButtonSecondary type="submit" className="button_submit">
                  {loading ? (
                    <LottieAnimation
                      width={40}
                      height={40}
                      animation={animationLoadingButton}
                    />
                  ) : (
                    <p>{showFormToAdd ? 'Adicionar' : 'Salvar alterações'}</p>
                  )}
                </ButtonSecondary>
              </ButtonContainer>
            </Form>
          </Content>
        </LateralModal>

        <BoxContainer>
          <ContainerTitlePage className="container_title_page">
            <TitlePage className="title_page">Sucatas</TitlePage>
            {profile.occupation !== 'Analista de Laboratório' && (
              <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
                <LottieAnimation
                  width={50}
                  height={50}
                  animation={animationButtonAdd}
                />
                {widthWindow > 500 && <p>Adicionar</p>}
              </ContainerButtonAdd>
            )}
          </ContainerTitlePage>

          <ContainerTable>
            <Table>
              <TableHeader>
                <tr>
                  <th className="th-edit">Editar</th>
                  <th>STATUS</th>
                  <th>SUCATA</th>
                  <th>
                    Preço <br />
                    (R$/ton)
                  </th>
                  <th>
                    Data <br /> da compra
                  </th>
                  <th>
                    Massa <br /> disponível (ton)
                  </th>
                  <th className="th-delete">Excluir</th>
                </tr>
              </TableHeader>

              {loadingPage ? (
                <TableBody>
                  <tr>
                    <td>
                      <BackgroundOpacity className="overlay">
                        <LottieAnimation
                          width={100}
                          height={100}
                          animation={animationLoadingPage}
                        />
                      </BackgroundOpacity>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {wastes?.length !== 0 &&
                    wastes.map((waste) => (
                      <tr key={waste.id}>
                        <td
                          className="td-edit"
                          onClick={() => setFormToEdit(true, waste)}>
                          <MdEdit size={16} color={colors.primary} />
                        </td>

                        <TdStatus status={waste.status}>
                          <div className="container-status">
                            <div className="circle-status-container">
                              <div className="circle-status" />
                            </div>
                            <div className="title-status">
                              <p>{waste.status}</p>
                            </div>
                          </div>
                        </TdStatus>
                        <td>{waste.name}</td>
                        <td>{formatPrice(waste.price)}</td>
                        <td>{formatDateToShow(waste.bought_in)}</td>
                        <td>
                          {formatAmount(waste.mass_available, 0, '.', ',')}
                        </td>
                        <td
                          className="td-delete"
                          onClick={() => handleRemoveWaste(waste.id)}>
                          <MdDelete size={16} color={colors.failure} />
                        </td>
                      </tr>
                    ))}
                </TableBody>
              )}
            </Table>

            {wastes.length === 0 && (
              <div className="container_information">
                <p>
                  Insira um novo registro clicando sobre o botão "Adicionar"
                </p>
              </div>
            )}
          </ContainerTable>
        </BoxContainer>
      </Container>

      <MenuBottomContainer>
        <MenuBottomContent>
          <ButtonItem className="btn-primary">
            <Link to="/sucatas/cadastro">Cadastro de sucatas</Link>
          </ButtonItem>
          <ButtonItem className="btn-secondary">
            <Link to="/sucatas/restricoes">Restrições de sucatas</Link>
          </ButtonItem>
        </MenuBottomContent>
      </MenuBottomContainer>
    </>
  );
};

export default WasteRegister;
