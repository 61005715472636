import styled from 'styled-components';
import {colors, metrics} from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${colors.primary};
  font-size: ${metrics.font.small}rem;

  @media (min-width: 700px) {
    font-size: ${metrics.font.bigger}rem;
  }
`;
