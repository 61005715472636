import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as ResidueActions} from '../../ducks/residue/register/actions';

function* createResidue({payload}) {
  const {name} = payload.data;

  try {
    const response = yield call(api.post, '/residues/register', {
      name,
    });

    yield put(ResidueActions.createResidueSuccess(response.data));
  } catch (error) {
    yield put(ResidueActions.createResidueFailure(error.message));
  }
}

function* getResidue() {
  try {
    const response = yield call(api.get, '/residues/register');
    yield put(ResidueActions.getResidueSuccess(response.data));
  } catch (error) {
    yield put(ResidueActions.getResidueFailure(error.message));
  }
}

function* updateResidue({payload}) {
  try {
    const {id, name} = payload.data;

    const residue = {
      name,
    };

    const {data} = yield call(api.patch, `/residues/register/${id}`, residue);

    const residueUpdated = {
      id: data.id,
      name: data.name,
    };

    yield put(ResidueActions.updateResidueSuccess(id, residueUpdated));
  } catch (error) {
    yield put(ResidueActions.updateResidueFailure(error.message));
  }
}

function* deleteResidue({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/residues/register/${id}`);

    yield put(ResidueActions.deleteResidueSuccess(id));
  } catch (error) {
    yield put(ResidueActions.deleteResidueFailure(error.message));
  }
}

export {createResidue, getResidue, updateResidue, deleteResidue};
