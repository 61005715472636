export const Types = {
  CREATE_ORECONSTRAINT_REQUEST: '@ore/CREATE_ORECONSTRAINT_REQUEST',
  CREATE_ORECONSTRAINT_SUCCESS: '@ore/CREATE_ORECONSTRAINT_SUCCESS',
  CREATE_ORECONSTRAINT_FAILURE: '@ore/CREATE_ORECONSTRAINT_FAILURE',
  GET_ORECONSTRAINT_REQUEST: '@ore/GET_ORECONSTRAINT_REQUEST',
  GET_ORECONSTRAINT_SUCCESS: '@ore/GET_ORECONSTRAINT_SUCCESS',
  GET_ORECONSTRAINT_FAILURE: '@ore/GET_ORECONSTRAINT_FAILURE',
  UPDATE_ORECONSTRAINT_REQUEST: '@ore/UPDATE_ORECONSTRAINT_REQUEST',
  UPDATE_ORECONSTRAINT_SUCCESS: '@ore/UPDATE_ORECONSTRAINT_SUCCESS',
  UPDATE_ORECONSTRAINT_FAILURE: '@ore/UPDATE_ORECONSTRAINT_FAILURE',
  DELETE_ORECONSTRAINT_REQUEST: '@ore/DELETE_ORECONSTRAINT_REQUEST',
  DELETE_ORECONSTRAINT_SUCCESS: '@ore/DELETE_ORECONSTRAINT_SUCCESS',
  DELETE_ORECONSTRAINT_FAILURE: '@ore/DELETE_ORECONSTRAINT_FAILURE',
};
