import React, {useState, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';
import {MdEmail, MdPerson, MdLock} from 'react-icons/md';

import animationLoadingButton from '../../assets/lotties/loading_button.json';

import {
  ButtonSecondary,
  LineHorizontal,
} from '../../assets/styles/CommonStyled';
import {Content} from './styles';

import Input from '../../components/Input';
import LottieAnimation from '../../components/Animation/LottieAnimation';

import getValidationErrors from '../../utils/getValidationErrors';
import {Actions as UserActions} from '../../store/ducks/user/actions';

import {useToast} from '../../contexts/ToastContext';
import {useSidebar} from '../../contexts/SidebarContext';

function EditProfile() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [loading, setLoading] = useState(false);
  const {addToast} = useToast();
  const {setShowSidebar} = useSidebar();

  const handleSubmit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Informe um e-mail válido'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const user = {id: profile.id, ...data};
        dispatch(UserActions.updateProfileRequest(user));

        addToast({
          type: 'success',
          title: 'Seu perfil foi atualizado',
        });
        setShowSidebar(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar dados do colaborador(a)',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setLoading(false);
    },
    [addToast, dispatch, profile.id, setShowSidebar]
  );

  return (
    <Content>
      <Form initialData={profile} ref={formRef} onSubmit={handleSubmit}>
        <Input name="name" icon={MdPerson} type="name" placeholder="Nome" />
        <Input name="email" icon={MdEmail} type="email" placeholder="E-mail" />

        <LineHorizontal />
        <Input
          name="oldPassword"
          icon={MdLock}
          type="password"
          placeholder="Sua senha atual"
          isFieldPass
        />
        <Input
          name="password"
          icon={MdLock}
          type="password"
          placeholder="Nova senha"
          isFieldPass
        />
        <Input
          name="confirmPassword"
          icon={MdLock}
          type="password"
          placeholder="Confirmação de senha"
          isFieldPass
        />

        <ButtonSecondary type="submit" className="button_submit">
          {loading ? (
            <LottieAnimation
              width={40}
              height={40}
              animation={animationLoadingButton}
            />
          ) : (
            <p>Atualizar perfil</p>
          )}
        </ButtonSecondary>
      </Form>
    </Content>
  );
}

export default EditProfile;
