import {Types} from './types';

export const Actions = {
  createLeitoRequest: (data) => ({
    type: Types.CREATE_LEITO_REQUEST,
    payload: {data},
  }),
  createLeitoSuccess: (data) => ({
    type: Types.CREATE_LEITO_SUCCESS,
    payload: {data},
  }),
  createLeitoFailure: (error) => ({
    type: Types.CREATE_LEITO_FAILURE,
    payload: {error},
  }),
  getLeitoRequest: () => ({
    type: Types.GET_LEITO_REQUEST,
  }),
  getLeitoSuccess: (data) => ({
    type: Types.GET_LEITO_SUCCESS,
    payload: {data},
  }),
  getLeitoFailure: (error) => ({
    type: Types.UPDATE_LEITO_FAILURE,
    payload: {error},
  }),
  updateLeitoRequest: (id, data) => ({
    type: Types.UPDATE_LEITO_REQUEST,
    payload: {id, data},
  }),
  updateLeitoSuccess: (id, data) => ({
    type: Types.UPDATE_LEITO_SUCCESS,
    payload: {id, data},
  }),
  updateLeitoFailure: (error) => ({
    type: Types.UPDATE_LEITO_FAILURE,
    payload: {error},
  }),
  deleteLeitoRequest: (id) => ({
    type: Types.DELETE_LEITO_REQUEST,
    payload: {id},
  }),
  deleteLeitoSuccess: (id) => ({
    type: Types.DELETE_LEITO_SUCCESS,
    payload: {id},
  }),
  deleteLeitoFailure: (error) => ({
    type: Types.DELETE_LEITO_FAILURE,
    payload: {error},
  }),
};
