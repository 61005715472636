/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEdit, MdArrowBack} from 'react-icons/md';

import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  HorizontalArrow,
  ContainerTable,
  AlertContainer,
  AlertMessage,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {
  Content,
  ContentInRow,
  ContainerSelect,
  ButtonContainer,
  Space,
  SpaceBottom,
  ContainerCheckbox,
} from './styles';

import {colors} from '../../../../assets/styles';

import Input from '../../../../components/Input';
import Checkbox from '../../../../components/Checkbox';
import SimpleSelect from '../../../../components/Select';
import LateralModal from '../../../../components/LateralModal';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import Menu from '../../../../components/Menu';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as ProductActions} from '../../../../store/ducks/product/actions';

import {useToast} from '../../../../contexts/ToastContext';

import {formatPercentage} from '../../../../utils/format';

import useKeys from '../../../../hooks/useKeys';
import MenuBottom from '../MenuBottom';

const WeightConstraint = () => {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {products} = useSelector((state) => state.product);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [showAlertError, setShowAlertError] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);
  const [checked, setChecked] = useState(true);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  const initialValuesInput = {
    name: 'ACIARIA',
    gusa_min: 100,
    gusa_goal: 1200,
    slag_min: 200,
    slag_max: 700,
    ib_goal: 70,
    ib_max: 71,
    composition_gusa: {
      Fe_original: 92,
      SiO2_original: 2.5,
      Al2O3_original: 0.0,
      CaOMgO_original: 0.0,
      Mn_original: 0.4,
      P_original: 0.15,
    },
    composition_slag: {
      Fe_original: 1,
      SiO2_original: 20,
      Al2O3_original: 16,
      CaOMgO_original: 30,
      Mn_original: 0.5,
      P_original: 0.0,
    },
    criticities: {
      Fe: 'HIPER CRITICO',
      Si: 'MUITO CRITICO',
      Al2O3: 'MUITO CRITICO',
      CaO: 'MUITO CRITICO',
      MgO: 'IRRELEVANTE',
      Mn: 'CRITICO',
      P: 'CRITICO',
    },
    global_weights: {
      quality_original: 65,
      basicity_original: 10,
      slag_volume_original: 2,
      cost_original: 20,
      gusa_original: 3,
    },
  };

  const [values, setValues] = useState(initialValuesInput);

  const getProducts = async () => {
    setLoadingPage(true);

    try {
      dispatch(ProductActions.getProductRequest());
      setTimeout(() => {
        setLoadingPage(false);
      }, 1000);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(ProductActions.deleteProductRequest(Number(event.id)));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  const setFormToAdd = (show) => {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
    setProductToEdit(null);
  };

  const setFormToEdit = (show, product) => {
    const {
      name,
      gusa_min,
      gusa_goal,
      slag_min,
      slag_max,
      ib_goal,
      ib_max,
      criticities,
      global_weights,
    } = product;

    setValues({
      name,
      gusa_min,
      gusa_goal,
      slag_min,
      slag_max,
      ib_goal,
      ib_max,
      criticities,
      global_weights,
    });

    setProductToEdit(product);
    setShowFormToEdit(show);
    setActionAllowed(false);
  };

  const onCloseForms = () => {
    setFormToAdd(false);
    setShowFormToEdit(false);
    setValues(initialValuesInput);
    setProductToEdit(null);
  };

  const schemaYup = Yup.object().shape({
    quality_original: Yup.number()
      .required(
        'É necessário definir um valor de peso entre 0 e 100 para a qualidade do produto'
      )
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    basicity_original: Yup.number()
      .required(
        'É necessário definir um valor de peso entre 0 e 100 para a basicidade do produto'
      )
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    slag_volume_original: Yup.number()
      .required(
        'É necessário definir um valor de peso entre 0 e 100 para o volume de escória do produto'
      )
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    cost_original: Yup.number()
      .required(
        'É necessário definir um valor de peso entre 0 e 100 para o custo do produto'
      )
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    gusa_original: Yup.number()
      .required(
        'É necessário definir um valor de peso entre 0 e 100 para o gusa'
      )
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
  });

  const calculateSumTotalGlobalWeights = (global_weights) => {
    return (
      Number(global_weights.quality_original) +
      Number(global_weights.basicity_original) +
      Number(global_weights.slag_volume_original) +
      Number(global_weights.cost_original) +
      Number(global_weights.gusa_original)
    );
  };

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const newProduct = {
          name: data.name,
          gusa_min: Number(data.gusa_min),
          gusa_goal: Number(data.gusa_goal),
          slag_min: Number(data.slag_min),
          slag_max: Number(data.slag_max),
          ib_goal: Number(data.ib_goal),
          ib_max: Number(data.ib_max),
          criticities: {
            Fe: data.Fe,
            Si: data.Si,
            Al2O3: data.Al2O3,
            CaO: data.CaO,
            MgO: 'IRRELEVANTE',
            Mn: data.Mn,
            P: data.P,
          },
          global_weights: {
            quality_original: Number(data.quality_original),
            basicity_original: Number(data.basicity_original),
            slag_volume_original: Number(data.slag_volume_original),
            cost_original: Number(data.cost_original),
            gusa_original: Number(data.gusa_original),
          },
        };

        dispatch(ProductActions.createProductRequest(newProduct));

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const productUpdated = {
          ...productToEdit,
          criticities: {
            ...productToEdit.criticities,
          },
          global_weights: {
            quality_original: Number(data.quality_original),
            basicity_original: Number(data.basicity_original),
            slag_volume_original: Number(data.slag_volume_original),
            cost_original: Number(data.cost_original),
            gusa_original: Number(data.gusa_original),
          },
        };

        const total = calculateSumTotalGlobalWeights(
          productUpdated.global_weights
        );
        if (total !== 100) {
          setShowAlertError(true);
          setLoading(false);
          setActionAllowed(false);
          return;
        }

        dispatch(
          ProductActions.updateProductRequest(
            Number(productToEdit.id),
            productUpdated
          )
        );

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
      setShowAlertError(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  useEffect(() => {
    if (leftArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/residuos/cadastro');
    }
    if (rightArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/produtos/restricoesdeproducao');
    }
  }, [leftArrow, rightArrow, showFormToAdd, showFormToEdit]);

  return (
    <>
      <Container>
        <Menu />
        <HorizontalArrow>
          <Link to="/residuos/cadastro">
            <div className="icon">
              <MdArrowBack />
            </div>
          </Link>
        </HorizontalArrow>

        {(showFormToAdd || showFormToEdit) && (
          <BackgroundOpacity
            className="overlay"
            onClick={() => onCloseForms()}
          />
        )}

        <LateralModal
          open={showFormToAdd || showFormToEdit}
          title={showFormToAdd ? 'Novo Registro' : 'Editar Registro'}
          onClose={showFormToAdd ? setShowFormToAdd : setShowFormToEdit}>
          <Content>
            <Form
              initialData={showFormToAdd ? values : productToEdit}
              ref={showFormToAdd ? formRefAdd : formRefEdit}
              onSubmit={showFormToAdd ? handleAddForm : handleEditForm}>
              {showFormToAdd && (
                <>
                  <SpaceBottom />
                  <ContentInRow>
                    <ContentInput className="input-block">
                      <label htmlFor="name">
                        <strong>Nome:</strong>
                      </label>
                      <Input
                        name="name"
                        type="text"
                        placeholder="ACIARIA"
                        value={values.name.toUpperCase().trim()}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            name: e.target.value.toUpperCase().trim(),
                          })
                        }
                      />
                    </ContentInput>
                  </ContentInRow>
                  <LineHorizontal />

                  <strong className="label">
                    Meta de produção de gusa (kg):{' '}
                  </strong>
                  <ContentInRow>
                    <ContentInput className="input-block">
                      <label htmlFor="gusa_min">Mínimo:</label>
                      <Input
                        name="gusa_min"
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="700"
                        value={values?.gusa_min || 0}
                        onChange={(e) =>
                          setValues({...values, gusa_min: e.target.value})
                        }
                      />
                    </ContentInput>
                    <ContentInput className="input-block">
                      <label htmlFor="gusa_goal">Meta:</label>
                      <Input
                        name="gusa_goal"
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="1200"
                        value={values?.gusa_goal || 0}
                        onChange={(e) =>
                          setValues({...values, gusa_goal: e.target.value})
                        }
                      />
                    </ContentInput>
                  </ContentInRow>
                  <LineHorizontal />

                  <strong className="label">
                    Meta de produção de escória (kg):{' '}
                  </strong>
                  <ContentInRow>
                    <ContentInput className="input-block">
                      <label htmlFor="slag_min">Mínimo:</label>
                      <Input
                        name="slag_min"
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="100"
                        value={values?.slag_min || 0}
                        onChange={(e) =>
                          setValues({...values, slag_min: e.target.value})
                        }
                      />
                    </ContentInput>
                    <ContentInput className="input-block">
                      <label htmlFor="slag_max">Máximo:</label>
                      <Input
                        name="slag_max"
                        type="number"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        placeholder="700"
                        value={values?.slag_max || 0}
                        onChange={(e) =>
                          setValues({...values, slag_max: e.target.value})
                        }
                      />
                    </ContentInput>
                  </ContentInRow>
                  <LineHorizontal />

                  <strong className="label">Faixa de criticidades: </strong>
                  <ContentInRow>
                    <ContainerSelect>
                      <label htmlFor="Fe">Fe:</label>
                      <SimpleSelect
                        name="Fe"
                        value={values?.criticities?.Fe || ''}
                        options={[
                          {value: 'HIPER CRITICO', label: 'HIPER CRITICO'},
                          {value: 'MUITO CRITICO', label: 'MUITO CRITICO'},
                          {value: 'CRITICO', label: 'CRITICO'},
                          {
                            value: 'MUITO IMPORTANTE',
                            label: 'MUITO IMPORTANTE',
                          },
                          {value: 'IMPORTANTE', label: 'IMPORTANTE'},
                          {value: 'IRRELEVANTE', label: 'IRRELEVANTE'},
                        ]}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            criticities: {
                              ...values?.criticities,
                              Fe: e.target.value,
                            },
                          })
                        }
                      />
                    </ContainerSelect>

                    <ContainerSelect>
                      <label htmlFor="Si">Si:</label>
                      <SimpleSelect
                        name="Si"
                        value={values?.criticities?.Si || ''}
                        options={[
                          {value: 'HIPER CRITICO', label: 'HIPER CRITICO'},
                          {value: 'MUITO CRITICO', label: 'MUITO CRITICO'},
                          {value: 'CRITICO', label: 'CRITICO'},
                          {
                            value: 'MUITO IMPORTANTE',
                            label: 'MUITO IMPORTANTE',
                          },
                          {value: 'IMPORTANTE', label: 'IMPORTANTE'},
                          {value: 'IRRELEVANTE', label: 'IRRELEVANTE'},
                        ]}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            criticities: {
                              ...values?.criticities,
                              Si: e.target.value,
                            },
                          })
                        }
                      />
                    </ContainerSelect>

                    <ContainerSelect>
                      <label htmlFor="Al2O3">Al2O3:</label>
                      <SimpleSelect
                        name="Al2O3"
                        value={values?.criticities?.Al2O3 || ''}
                        options={[
                          {value: 'HIPER CRITICO', label: 'HIPER CRITICO'},
                          {value: 'MUITO CRITICO', label: 'MUITO CRITICO'},
                          {value: 'CRITICO', label: 'CRITICO'},
                          {
                            value: 'MUITO IMPORTANTE',
                            label: 'MUITO IMPORTANTE',
                          },
                          {value: 'IMPORTANTE', label: 'IMPORTANTE'},
                          {value: 'IRRELEVANTE', label: 'IRRELEVANTE'},
                        ]}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            criticities: {
                              ...values?.criticities,
                              Al2O3: e.target.value,
                            },
                          })
                        }
                      />
                    </ContainerSelect>
                  </ContentInRow>
                  <LineHorizontal />

                  <ContentInRow>
                    <ContainerSelect>
                      <label htmlFor="CaO">CaO:</label>
                      <SimpleSelect
                        name="CaO"
                        value={values?.criticities?.CaO || ''}
                        options={[
                          {value: 'HIPER CRITICO', label: 'HIPER CRITICO'},
                          {value: 'MUITO CRITICO', label: 'MUITO CRITICO'},
                          {value: 'CRITICO', label: 'CRITICO'},
                          {
                            value: 'MUITO IMPORTANTE',
                            label: 'MUITO IMPORTANTE',
                          },
                          {value: 'IMPORTANTE', label: 'IMPORTANTE'},
                          {value: 'IRRELEVANTE', label: 'IRRELEVANTE'},
                        ]}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            criticities: {
                              ...values?.criticities,
                              CaO: e.target.value,
                            },
                          })
                        }
                      />
                    </ContainerSelect>

                    <ContainerSelect>
                      <label htmlFor="Mn">Mn:</label>
                      <SimpleSelect
                        name="Mn"
                        value={values?.criticities?.Mn || ''}
                        options={[
                          {value: 'HIPER CRITICO', label: 'HIPER CRITICO'},
                          {value: 'MUITO CRITICO', label: 'MUITO CRITICO'},
                          {value: 'CRITICO', label: 'CRITICO'},
                          {
                            value: 'MUITO IMPORTANTE',
                            label: 'MUITO IMPORTANTE',
                          },
                          {value: 'IMPORTANTE', label: 'IMPORTANTE'},
                          {value: 'IRRELEVANTE', label: 'IRRELEVANTE'},
                        ]}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            criticities: {
                              ...values?.criticities,
                              Mn: e.target.value,
                            },
                          })
                        }
                      />
                    </ContainerSelect>

                    <ContainerSelect>
                      <label htmlFor="P">P</label>
                      <SimpleSelect
                        name="P"
                        value={values?.criticities?.P || 0}
                        options={[
                          {value: 'HIPER CRITICO', label: 'HIPER CRITICO'},
                          {value: 'MUITO CRITICO', label: 'MUITO CRITICO'},
                          {value: 'CRITICO', label: 'CRITICO'},
                          {
                            value: 'MUITO IMPORTANTE',
                            label: 'MUITO IMPORTANTE',
                          },
                          {value: 'IMPORTANTE', label: 'IMPORTANTE'},
                          {value: 'IRRELEVANTE', label: 'IRRELEVANTE'},
                        ]}
                        onChange={(e) =>
                          setValues({
                            ...values,
                            criticities: {
                              ...values?.criticities,
                              P: e.target.value,
                            },
                          })
                        }
                      />
                    </ContainerSelect>
                  </ContentInRow>
                  <LineHorizontal />
                </>
              )}

              <strong>Pesos globais (%):</strong>
              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="quality_original">Qualidade:</label>
                  <Input
                    name="quality_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.global_weights?.quality_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        global_weights: {
                          ...values?.global_weights,
                          quality_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="basicity_original">Basicidade:</label>
                  <Input
                    name="basicity_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.global_weights?.basicity_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        global_weights: {
                          ...values?.global_weights,
                          basicity_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="slag_volume_original">
                    {widthWindow > 500 ? 'Volume de escória' : 'Vol. escória'}:
                  </label>
                  <Input
                    name="slag_volume_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.global_weights?.slag_volume_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        global_weights: {
                          ...values?.global_weights,
                          slag_volume_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="cost_original">Custo:</label>
                  <Input
                    name="cost_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.global_weights?.cost_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        global_weights: {
                          ...values?.global_weights,
                          cost_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="gusa_original">Gusa:</label>
                  <Input
                    name="gusa_original"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.global_weights?.gusa_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        global_weights: {
                          ...values?.global_weights,
                          gusa_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ContainerCheckbox>
                <Checkbox
                  label="Usar valores default"
                  value={checked}
                  onChange={() => setChecked((prevState) => !prevState)}
                />
              </ContainerCheckbox>

              {showAlertError && (
                <AlertContainer>
                  <AlertMessage>
                    * A soma total dos pesos globais deve ser igual a 100
                  </AlertMessage>
                </AlertContainer>
              )}

              <ButtonContainer>
                <ButtonSecondary type="submit" className="button_submit">
                  {loading ? (
                    <LottieAnimation
                      width={40}
                      height={40}
                      animation={animationLoadingButton}
                    />
                  ) : (
                    <p>{showFormToAdd ? 'Adicionar' : 'Salvar alterações'}</p>
                  )}
                </ButtonSecondary>
              </ButtonContainer>
              <Space />
            </Form>
          </Content>
        </LateralModal>

        <BoxContainer>
          <ContainerTitlePage className="container_title_page">
            <TitlePage className="title_page">Produção</TitlePage>
          </ContainerTitlePage>

          <ContainerTable>
            <Table>
              <TableHeader>
                <tr>
                  <th rowSpan="2" className="th-edit">
                    Editar
                  </th>
                  <th rowSpan="2">PRODUTO</th>
                  <th colSpan="5">PESOS GLOBAIS (%)</th>
                </tr>

                <tr>
                  <th>Qualidade</th>
                  <th>Basicidade</th>
                  <th>Volume Escória</th>
                  <th>Custo</th>
                  <th>Gusa</th>
                </tr>
              </TableHeader>

              {loadingPage ? (
                <TableBody>
                  <tr>
                    <td>
                      <BackgroundOpacity className="overlay">
                        <LottieAnimation
                          width={100}
                          height={100}
                          animation={animationLoadingPage}
                        />
                      </BackgroundOpacity>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {products?.length !== 0 &&
                    products.map((product) => (
                      <tr key={product.id}>
                        <td
                          className="td-edit"
                          onClick={() => setFormToEdit(true, product)}>
                          <MdEdit size={16} color={colors.primary} />
                        </td>

                        <td>{product.name}</td>

                        <td>
                          {formatPercentage(
                            product?.global_weights?.quality_original || 0,
                            2,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            product?.global_weights?.basicity_original || 0,
                            2,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            product?.global_weights?.slag_volume_original || 0,
                            2,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            product?.global_weights?.cost_original || 0,
                            2,
                            '.',
                            ','
                          )}
                        </td>
                        <td>
                          {formatPercentage(
                            product?.global_weights?.gusa_original || 0,
                            2,
                            '.',
                            ','
                          )}
                        </td>
                      </tr>
                    ))}
                </TableBody>
              )}
            </Table>

            {products.length === 0 && (
              <div className="container_information">
                <p>
                  Insira um novo registro clicando sobre o botão "Adicionar"
                </p>
              </div>
            )}
          </ContainerTable>
        </BoxContainer>
      </Container>

      <MenuBottom btnPrimary="4" />
    </>
  );
};

export default WeightConstraint;
