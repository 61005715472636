import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ReactParticles from 'react-tsparticles';
import {Container} from './styles';
import cimp from '../../assets/images/cimp.svg';

import {useSidebar} from '../../contexts/SidebarContext';
import {useToast} from '../../contexts/ToastContext';

import {checkIfTokenExpired} from '../../utils/checkIfTokenExpired';

import {Actions as AuthActions} from '../../store/ducks/auth/actions';
import {colors} from '../../assets/styles';

const Dashboard = () => {
  const {addToast} = useToast();
  const {setShowSidebar} = useSidebar();
  const dispatch = useDispatch();
  const {signed, token} = useSelector((state) => state.auth);
  const {profile} = useSelector((state) => state.user);
  const tokenExpired = checkIfTokenExpired(token);
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  useEffect(() => {
    if (signed && !tokenExpired) {
      addToast({
        type: 'success',
        title: `Bem vindo ao sistema, ${profile.name}`,
      });
    } else {
      dispatch(AuthActions.signOut());
    }
  }, [signed, addToast, profile.name, setShowSidebar]);

  function Particles({children}) {
    return (
      <div style={{position: 'relative', width: '100%', height: '100%'}}>
        <ReactParticles
          params={{
            fpsLimit: 60,
            particles: {
              number: {
                value: widthWindow <= 500 ? 10 : 40,
              },
              size: {
                value: 3,
              },
              color: {
                value: colors.secondary,
              },
              links: {
                enable: true,
                color: colors.secondary,
                distance: 150,
              },
              move: {
                enable: true,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'repulse',
                },
              },
            },
          }}
          style={{
            position: 'absolute',
            zIndex: 1,
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        />
        {children}
      </div>
    );
  }

  return (
    <Particles>
      <Container>
        <img src={cimp} width={widthWindow <= 500 ? 300 : 500} alt="adm" />
      </Container>
    </Particles>
  );
};

export default Dashboard;
