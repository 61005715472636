import styled from 'styled-components';
import {colors} from '../../assets/styles';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: calc(100% - 10px);
  background: ${colors.primary};
  top: 0;
  position: fixed;
  z-index: 2;
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 96%;
  justify-content: space-between;
  align-items: center;
`;

export const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    margin-left: 2rem;
    color: ${colors.secondary};
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const LogoutContainer = styled.div`
  display: flex;
  flex-direction: column,
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  border-radius: 1.6rem;
  cursor: pointer;
  
  :hover {
    background: ${colors.tertiary};
  }
`;

export const TextLogout = styled.strong`
  grid-area: 'text-logout';
  color: ${colors.secondary};
  font-size: 0.8rem;
  padding-left: 0.4px;
`;
