/* eslint-disable import/no-cycle */
import React from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {FiLogOut} from 'react-icons/fi';
import colors from '../../assets/styles/colors';
import MenuButton from '../Button/MenuButton';

import {
  HeaderContainer,
  HeaderContent,
  LogoutContainer,
  TextLogout,
  LeftContent,
} from './styles';

import {useSidebar} from '../../contexts/SidebarContext';
import {useHeader} from '../../contexts/HeaderContext';
import {BackgroundOpacity} from '../../assets/styles/CommonStyled';
import {Actions as AuthActions} from '../../store/ducks/auth/actions';

function Header() {
  const {showSidebar, setShowSidebar} = useSidebar();
  const {showHeader} = useHeader();
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(AuthActions.signOut());
  }

  return (
    <>
      {showSidebar && (
        <BackgroundOpacity
          className="overlay"
          onClick={() => setShowSidebar(false)}
        />
      )}

      {showHeader && (
        <HeaderContainer>
          <HeaderContent>
            <LeftContent>
              <MenuButton showSidebar />
              <Link to="/">INÍCIO</Link>
            </LeftContent>

            <LogoutContainer onClick={() => handleSignOut()}>
              <FiLogOut
                size={22}
                color={
                  ['/'].includes(window.location.pathname)
                    ? colors.secondary
                    : colors.primary
                }
              />
              <TextLogout
                style={{
                  color: ['/'].includes(window.location.pathname)
                    ? colors.secondary
                    : colors.primary,
                }}>
                Sair
              </TextLogout>
            </LogoutContainer>
          </HeaderContent>
        </HeaderContainer>
      )}
    </>
  );
}

export default Header;
