/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {MdPerson, MdGroupAdd} from 'react-icons/md';
import {Container, ContentProfile, ContentAddUsers, Username} from './styles';
import MenuButton from '../../Button/MenuButton';

import colors from '../../../assets/styles/colors';

import {useSidebar} from '../../../contexts/SidebarContext';

function TopSection() {
  const {profile} = useSelector((state) => state.user);
  const {setShowSidebar} = useSidebar();
  return (
    <Container>
      <MenuButton showSidebar={false} />
      <ContentProfile>
        <Username>
          <MdPerson size={20} color="#FFF" />
          <strong>
            {profile.name.length > 10
              ? `${profile.name.split(' ')[0]}...`
              : profile.name}
          </strong>
        </Username>

        <span>{profile.occupation}</span>

        {/* <Link to="/perfil" onClick={() => setShowSidebar(false)}>
          <p>Editar Perfil</p>
        </Link> */}
      </ContentProfile>

      {/* {profile.occupation === 'Superintendente' && (
        <ContentAddUsers>
          <Link
            to="/cadastro"
            onClick={() => setShowSidebar(false)}>
            <MdGroupAdd size={22} color={colors.primary} />
          </Link>
        </ContentAddUsers>
      )} */}
    </Container>
  );
}

export default TopSection;
