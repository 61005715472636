import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  oresConstraints: [],
  lotes: [],
};

const oreConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_ORECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_ORECONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        oresConstraints: [...state.oresConstraints, action.payload.data],
        lotes: [...state.lotes, Number(action.payload.data.lote_number)],
      };

    case Types.CREATE_ORECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_ORECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_ORECONSTRAINT_SUCCESS:
      return {
        ...state,
        oresConstraints: action.payload.data,
        lotes: action.payload.data.map((oree) => oree.lote_number),
        loading: false,
        error: null,
      };

    case Types.GET_ORECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_ORECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_ORECONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        oresConstraints: [
          ...state.oresConstraints.map((oree) =>
            oree.id === action.payload.id ? action.payload.data : oree
          ),
        ],
        lotes: [
          ...state.oresConstraints.map((oree) =>
            oree.id === action.payload.id
              ? Number(action.payload.data.lote_number)
              : Number(oree.lote_number)
          ),
        ],
      };

    case Types.UPDATE_ORECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_ORECONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_ORECONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        oresConstraints: [...state.oresConstraints.filter((oree) => oree.id !== action.payload.id)],
        lotes: [
          ...state.oresConstraints
            .filter((oree) => oree.id !== action.payload.id)
            .map((lote) => Number(lote.lote_number)),
        ],
      };

    case Types.DELETE_ORECONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default oreConstraint;
