import jwt from 'jsonwebtoken';

export function checkIfTokenExpired(token) {
  if (token && jwt.decode(token)) {
    const expiry = jwt.decode(token).exp;
    const now = new Date();
    return now.getTime() > expiry * 1000;
  }
  return false;
}
