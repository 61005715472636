import React from 'react';
import {MIN_SIZE_DESKTOP_2} from '../../../constants/BREAKPOINTS';
import useScreenSize from '../../../hooks/useScreenSize';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

const LeitoPage = () => {
  const screenSize = useScreenSize();
  return screenSize.width > MIN_SIZE_DESKTOP_2 ? (
    <DesktopLayout />
  ) : (
    <MobileLayout />
  );
};

export default LeitoPage;
