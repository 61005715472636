import styled, {css} from 'styled-components';
import colors from '../../assets/styles/colors';

export const Container = styled.div.attrs((props) => ({
  disabled: props.open,
}))`
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: -100px;
  padding: 1rem;
  width: 100%;
  height: 100vh;
  background: ${colors.secondary};
  -webkit-box-shadow: 1px 1px 3px 1px ${colors.quaternary};
  -moz-box-shadow: 1px 1px 3px 1px ${colors.quaternary};
  box-shadow: 1px 1px 3px 1px ${colors.quaternary};

  ${(props) =>
    props.open
    ? css`
      transition: all ease 0.5s;
      transform: translateX(-100px);
      `
    : css`
      transition: all ease 0.5s;
      transform: translateX(20rem);
  `}

  @media (min-width: 700px) {
    width: 40rem;
    right: 0;

    ${(props) =>
      props.open
      ? css`
        transition: all ease 0.5s;
        transform: translateX(0);
      `
      : css`
        transition: all ease 0.5s;
        transform: translateX(40rem);
    `}
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid ${colors.primary};
  color: ${colors.primary};
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto
  margin: 0.5rem 0;
`;

export const Title = styled.h2`
  color: ${colors.primary};
  
  @media(max-width: 900px) {
    font-size: 1.2rem;
  }
`;
