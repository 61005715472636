import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  residuesGlobalConstraints: [],
};

const residueConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_RESIDUEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_RESIDUEGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        residuesGlobalConstraints: [...state.residuesGlobalConstraints, action.payload.data],
      };

    case Types.CREATE_RESIDUEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_RESIDUEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_RESIDUEGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        residuesGlobalConstraints: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_RESIDUEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_RESIDUEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_RESIDUEGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        residuesGlobalConstraints: [
          ...state.residuesGlobalConstraints.map((residue) =>
            residue.id === action.payload.id ? action.payload.data : residue
          ),
        ],
      };

    case Types.UPDATE_RESIDUEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_RESIDUEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_RESIDUEGLOBALCONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        residuesGlobalConstraints: [
          ...state.residuesGlobalConstraints.filter(
            (residue) => residue.id !== action.payload.id
          ),
        ],
      };

    case Types.DELETE_RESIDUEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default residueConstraint;
