export const Types = {
  CREATE_WASTEGLOBALCONSTRAINT_REQUEST: '@waste/CREATE_WASTEGLOBALCONSTRAINT_REQUEST',
  CREATE_WASTEGLOBALCONSTRAINT_SUCCESS: '@waste/CREATE_WASTEGLOBALCONSTRAINT_SUCCESS',
  CREATE_WASTEGLOBALCONSTRAINT_FAILURE: '@waste/CREATE_WASTEGLOBALCONSTRAINT_FAILURE',
  GET_WASTEGLOBALCONSTRAINT_REQUEST: '@waste/GET_WASTEGLOBALCONSTRAINT_REQUEST',
  GET_WASTEGLOBALCONSTRAINT_SUCCESS: '@waste/GET_WASTEGLOBALCONSTRAINT_SUCCESS',
  GET_WASTEGLOBALCONSTRAINT_FAILURE: '@waste/GET_WASTEGLOBALCONSTRAINT_FAILURE',
  UPDATE_WASTEGLOBALCONSTRAINT_REQUEST: '@waste/UPDATE_WASTEGLOBALCONSTRAINT_REQUEST',
  UPDATE_WASTEGLOBALCONSTRAINT_SUCCESS: '@waste/UPDATE_WASTEGLOBALCONSTRAINT_SUCCESS',
  UPDATE_WASTEGLOBALCONSTRAINT_FAILURE: '@waste/UPDATE_WASTEGLOBALCONSTRAINT_FAILURE',
  DELETE_WASTEGLOBALCONSTRAINT_REQUEST: '@waste/DELETE_WASTEGLOBALCONSTRAINT_REQUEST',
  DELETE_WASTEGLOBALCONSTRAINT_SUCCESS: '@waste/DELETE_WASTEGLOBALCONSTRAINT_SUCCESS',
  DELETE_WASTEGLOBALCONSTRAINT_FAILURE: '@waste/DELETE_WASTEGLOBALCONSTRAINT_FAILURE',
};
