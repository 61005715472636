import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  fondantsConstraints: [],
};

const fondantConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_FONDANTCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_FONDANTCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fondantsConstraints: [...state.fondantsConstraints, action.payload.data],
      };

    case Types.CREATE_FONDANTCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_FONDANTCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_FONDANTCONSTRAINT_SUCCESS:
      return {
        ...state,
        fondantsConstraints: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_FONDANTCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_FONDANTCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_FONDANTCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fondantsConstraints: [
          ...state.fondantsConstraints.map((fondantt) =>
            fondantt.id === action.payload.id ? action.payload.data : fondantt
          ),
        ],
      };

    case Types.UPDATE_FONDANTCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_FONDANTCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_FONDANTCONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        fondantsConstraints: [
          ...state.fondantsConstraints.filter(
            (fondantt) => fondantt.id !== action.payload.id
          ),
        ],
      };

    case Types.DELETE_FONDANTCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default fondantConstraint;
