import styled, {css} from 'styled-components';
import {colors} from '../../assets/styles';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${colors.tertiary};
  color: ${colors.quaternary};
  border-radius: 1rem;
  border: 2px solid ${colors.tertiary};
  padding: 0.5rem;
  width: 100%;
  transition: 0.8s;
  margin-top: 0.5rem;

  & + div {
    margin-top: 0.5rem;
  }

  &:hover {
    border: 2px solid ${colors.primary};
  }

  ${(props) =>
    props.isFocused &&
    css`
      color: ${colors.primary};
      border-color: ${colors.primary};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${colors.primary};
    `}

    ${(props) =>
    props.isErrored &&
    css`
      border-color: ${colors.failure};
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    padding: 0.5rem;
    color: ${colors.quaternary};
  }

  svg {
    margin-right: 0.4rem;
  }

  @media(max-width: 900px) {
    padding: 0.2rem;
    border-radius: 0.5rem;

    input {
      padding: 0.3rem;
    }
  }
`;
export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 0.4rem;

  svg {
    margin: 0;
  }

  span {
    background: ${colors.failure};
    color: ${colors.secondary};

    &::before {
      border-color: ${colors.failure} transparent;
    }
  }
`;
