import styled from 'styled-components';
import {MIN_SIZE_DESKTOP_2} from '../../../../constants/BREAKPOINTS';

export const ContainerInput = styled.div`
  width: 100%;
  max-width: 120px;
  max-height: 30px;

  input {
    max-width: 120px;
    max-height: 6px;
  }

  select {
    margin-top: 0.5rem;
  }

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    max-height: 60px;

    input {
      max-width: 120px;
      max-height: 28px;
    }
  }
`;
