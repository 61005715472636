import styled from 'styled-components';
import {colors, metrics} from '../../../assets/styles';

export const Container = styled.div`
  width: 100%;
  height: 10rem;
  background: ${colors.primary};
  color: ${colors.secondary};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${metrics.space.medium}rem;
`;

export const ContentProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 185px;
  margin-left: 1rem;

  strong {
    font-size: 1.5rem;
  }

  span {
    font-size: 1.1rem;
  }

  a {
    color: ${colors.secondary};
    margin-top: 0.2rem;
    font-size: 1.1rem;
    font-weight: bold;
  }

  a:hover {
    color: ${colors.tertiary};
  }

  @media (max-width: 600px) {
    max-width: 110px;

    strong {
      font-size: 1rem;
    }
    span {
      font-size: 0.8rem;
    }
    p {
      font-size: 0.75rem;
    }
  }
`;

export const ContentAddUsers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 80px;
  background: ${colors.tertiary};

  @media (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`;

export const Username = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg {
    margin: 0.5rem 0.7rem 0.5rem 0;
  }
`;
