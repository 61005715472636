import styled from 'styled-components';
import {colors, metrics} from '../../assets/styles';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 3rem;
  width: 100%;
  background: ${colors.primary};
  bottom: 0;
  position: fixed;
`;

export const Copyright = styled.span`
  color: ${colors.secondary};
  font-size: ${metrics.font.bigger};
  
  @media(max-width: 500px) {
    font-size: 0.8rem;
  }
`;
