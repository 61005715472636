import styled, {css} from 'styled-components';
import {animated} from 'react-spring';
import {colors} from '../../../../assets/styles';

const toastTypeVariations = {
  info: css`
    background: #ebf8ff;
    color: ${colors.primary};
  `,
  confirm: css`
    background: #ebf8ff;
    color: ${colors.primary};
  `,
  success: css`
    background: #e6fffa;
    color: ${colors.success};
  `,
  error: css`
    background: #fddede;
    color: ${colors.failure};
  `,
};

export const Container = styled(animated.div)`
  width: 100%;
  max-width: 370px;
  position: relative;
  padding: 16px 30px 30px 16px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  display: flex;

  & + div {
    margin-top: 8px;
  }

  background: #ebf8ff;
  color: ${colors.primary};

  ${(props) => toastTypeVariations[props.type || 'info']}

  > svg {
    margin: 4px 12px 0 0;
  }

  div {
    flex: 1;

    strong {
      margin-right: 4px;
    }

    p {
      margin-top: 4px;
      font-size: 1.2rem;
      opacity: 0.8;
      line-height: 20px;

      @media (max-width: 900px) {
        font-size: 0.9rem;
      }
    }
  }

  .btn_remove_toast {
    position: absolute;
    right: 14px;
    top: 19px;
    opacity: 0.6;
    background: transparent;
    color: inherit;
  }

  #container_confirm_toast {
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 70%;
    bottom: 0;

    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      padding-bottom: 0.5rem;
      color: ${colors.failure};
      border-radius: 2px;
    }

    * + button {
      color: ${colors.success};
      margin-left: 3rem;
    }

    @media (max-width: 900px) {
      button {
        top: 20px;
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  ${(props) =>
    !props.hasDescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}

  @media(max-width: 900px) {
    div > strong {
      font-size: 1rem;
    }
  }
`;
