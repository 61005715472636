import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Container, Title} from './styles';

const Menu = () => {
  const [active, setActive] = useState(window.location.pathname);

  const handleGetActualRoute = () => {
    const actualRoute = window.location.pathname;
    setActive(actualRoute);
  };

  useEffect(() => {
    handleGetActualRoute();
  }, []);

  return (
    <Container>
      <ul>
        <li className={['/leito'].includes(active) ? 'active' : ''}>
          <Link to="/leito" onClick={() => setActive('/leito')}>
            <Title>Leito</Title>
          </Link>
        </li>
        <li
          className={
            ['/minerios/cadastro', '/minerios/restricoes'].includes(active)
              ? 'active'
              : ''
          }>
          <Link
            to="/minerios/cadastro"
            onClick={() => setActive('/minerios/cadastro')}>
            <Title>Minérios</Title>
          </Link>
        </li>
        <li
          className={
            ['/sucatas/cadastro', '/sucatas/restricoes'].includes(active)
              ? 'active'
              : ''
          }>
          <Link
            to="/sucatas/cadastro"
            onClick={() => setActive('/sucatas/cadastro')}>
            <Title>Sucatas</Title>
          </Link>
        </li>
        <li
          className={
            ['/fundentes/cadastro', '/fundentes/restricoes'].includes(active)
              ? 'active'
              : ''
          }>
          <Link
            to="/fundentes/cadastro"
            onClick={() => setActive('/fundentes/cadastro')}>
            <Title>Fundentes</Title>
          </Link>
        </li>
        <li
          className={
            ['/residuos/cadastro', '/residuos/restricoes'].includes(active)
              ? 'active'
              : ''
          }>
          <Link
            to="/residuos/cadastro"
            onClick={() => setActive('/residuos/cadastro')}>
            <Title>Resíduos</Title>
          </Link>
        </li>
        <li
          className={
            [
              '/produtos/restricoesdeproducao',
              '/produtos/restricoesdeespecificacao',
              '/produtos/restricoesdecriticidades',
              '/produtos/restricoesdepesos',
            ].includes(active)
              ? 'active'
              : ''
          }>
          <Link
            to="/produtos/restricoesdeproducao"
            onClick={() => setActive('/produtos/restricoesdeproducao')}>
            <Title>Produção</Title>
          </Link>
        </li>
      </ul>
    </Container>
  );
};

export default Menu;
