import React, {useCallback, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEmail, MdLock} from 'react-icons/md';
import {ButtonWithLoading} from '../../assets/styles/CommonStyled';
import dataProcessing from '../../assets/images/data_processing.svg';

import animationLoadingButton from '../../assets/lotties/loading_button.json';

import {Container, Content, HeaderForm, Title, ImageHeader} from './styles';

import Input from '../../components/Input';
import Footer from '../../components/Footer';
import LottieAnimation from '../../components/Animation/LottieAnimation';

import getValidationErrors from '../../utils/getValidationErrors';
import {Actions as AuthActions} from '../../store/ducks/auth/actions';

import {useToast} from '../../contexts/ToastContext';

const SignIn = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const {loading, error} = useSelector((state) => state.auth);
  const {addToast} = useToast();

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string('Senha obrigatória').min(
            6,
            'No mínimo 6 dígitos'
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const {email, password} = data;
        dispatch(AuthActions.signInRequest(email, password));

        if (error) {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Ocorreu um erro ao tentar logar, cheque as credenciais.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro na autenticação',
          description:
            'Ocorreu um erro ao tentar logar, cheque as credenciais.',
        });
      }
    },
    [addToast, error, dispatch]
  );

  return (
    <>
      <Container>
        <Content>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <HeaderForm>
              <Title>Login</Title>
              <ImageHeader src={dataProcessing} alt="" />
            </HeaderForm>
            <Input
              name="email"
              icon={MdEmail}
              type="email"
              placeholder="E-mail"
            />

            <Input
              name="password"
              icon={MdLock}
              type="password"
              placeholder="******"
              isFieldPass
            />
            <ButtonWithLoading type="submit" isLoading={loading}>
              {loading ? (
                <LottieAnimation
                  width={40}
                  height={40}
                  animation={animationLoadingButton}
                />
              ) : (
                'Acessar o sistema'
              )}
            </ButtonWithLoading>
          </Form>
        </Content>
      </Container>

      <Footer />
    </>
  );
};

export default SignIn;
