import {Types} from './types';

export const Actions = {
  signInRequest: (email, password) => ({
    type: Types.SIGN_IN_REQUEST,
    payload: {email, password},
  }),
  signInSuccess: (token, user) => ({
    type: Types.SIGN_IN_SUCCESS,
    payload: {token, user},
  }),
  signInFailure: (error) => ({
    type: Types.SIGN_IN_FAILURE,
    payload: {error},
  }),
  signUpRequest: (name, email, password, occupation, class_type) => ({
    type: Types.SIGN_UP_REQUEST,
    payload: {name, email, password, occupation, class_type},
  }),
  signUpSuccess: () => ({
    type: Types.SIGN_UP_SUCCESS,
  }),
  signUpFailure: (error) => ({
    type: Types.SIGN_UP_FAILURE,
    payload: {error},
  }),
  signOut: () => ({
    type: Types.SIGN_OUT,
  }),
};
