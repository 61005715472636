import styled from 'styled-components';
import {colors} from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  height: 5rem;

  strong {
    color: ${colors.primary};
    margin: 0 1rem 0 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  ul {
    display: inline-flex;
    list-style: none;
  }

  .li_active {
    background: ${colors.primary};
  }

  .li_inative {
    background: ${colors.secondary};
  }

  .link_active {
    color: ${colors.secondary};
  }

  .link_inative {
    color: ${colors.primary};
  }

  ul li {
    justify-content: flex-start;
    align-items: center;
    margin: 0.4rem;
    padding: 0.4rem;
    border: 1px solid ${colors.primary};
    border-radius: 2px;
    cursor: pointer;
  }

  ul li:hover {
    background: ${colors.primary};
  }

  ul li a {
    color: ${colors.primary};
    font-size: 1rem;
    font-weight: bold;
  }

  ul li a:hover {
    color: ${colors.secondary};
  }
`;
