import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as PanActions} from '../../ducks/pan/actions';

function* createPan({payload}) {
  const {
    date,
    user_id,
    pan_number,
    hour_initial,
    hour_final,
    burn_time,
    humidity,
  } = payload.data;

  try {
    yield call(api.post, '/pans', {
      date,
      user_id,
      pan_number,
      hour_initial,
      hour_final,
      burn_time,
      humidity,
    });

    yield put(PanActions.createPanSuccess());
  } catch (error) {
    yield put(PanActions.createPanFailure(error.message));
  }
}

function* updatePan({payload}) {
  try {
    const {
      id,
      date,
      pan_number,
      hour_initial,
      hour_final,
      burn_time,
      humidity,
    } = payload.data;

    const pan = {
      date,
      pan_number,
      hour_initial,
      hour_final,
      burn_time,
      humidity,
    };

    yield call(api.put, `/pans/${id}`, pan);

    yield put(PanActions.updatePanSuccess());
  } catch (error) {
    yield put(PanActions.updatePanFailure(error.message));
  }
}

function* deletePan({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/pans/${id}`);

    yield put(PanActions.deletePanSuccess());
  } catch (error) {
    yield put(PanActions.deletePanFailure(error.message));
  }
}
export {createPan, updatePan, deletePan};
