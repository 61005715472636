/* eslint-disable import/no-cycle */
import React, {createContext, useState, useContext} from 'react';
import LeftSidebar from '../../components/Sidebar';

export const SidebarContext = createContext({});

const SidebarProvider = ({children}) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <SidebarContext.Provider value={{showSidebar, setShowSidebar}}>
      {children}
      <LeftSidebar />
    </SidebarContext.Provider>
  );
};

function useSidebar() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }

  return context;
}

export {SidebarProvider, useSidebar};
