/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import pt from 'date-fns/locale/pt';

import {
  MdClose,
  MdEdit,
  MdDelete,
  MdVisibility,
  MdBlock,
  MdSearch,
} from 'react-icons/md';

import animationButtonAdd from '../../assets/lotties/button_add.json';
import animationLoadingButton from '../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonCloseForm,
  ButtonSecondary,
  ContentInput,
  DatePickerStyled,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
} from '../../assets/styles/CommonStyled';
import {Content, HeaderForm, TitleForm, ContentInRow} from './styles';
import {colors} from '../../assets/styles';

import CalendarContainer from '../../components/Container/CalendarContainer';
import Paginate from '../../components/Paginate';
import Input from '../../components/Input';
import SimpleSelect from '../../components/Select';
import LottieAnimation from '../../components/Animation/LottieAnimation';

import getValidationErrors from '../../utils/getValidationErrors';
import {
  formatDateToShow,
  formatHourToShow,
  getDaysInMonth,
  getDifferenceInHours,
} from '../../utils/date';
import {formatDecimal} from '../../utils/format';

import {Actions as PanActions} from '../../store/ducks/pan/actions';

import {useToast} from '../../contexts/ToastContext';
import {usePaginate} from '../../contexts/PaginateContext';

import api from '../../services/api';

registerLocale('pt', pt);

function PanControl() {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [pans, setPans] = useState([]);
  const [page, setPage] = useState(1);
  const MAX_ITEMS = 15;
  const [totalPage, setTotalPage] = useState(1);
  const [countRegister, setCountRegister] = useState(0);
  const [countCallApi, setCountCallApi] = useState(0);
  const [hasMoreRegister, setHasMoreRegister] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  // const [error, setError] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [panToEdit, setPanToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const {filters, setFilters} = usePaginate();

  const initialValuesInput = {
    date: new Date(),
    pan_number: 1,
    hour_initial: new Date(),
    hour_final: new Date(),
    burn_time: '00:00',
    humidity: 0,
  };
  const [values, setValues] = useState(initialValuesInput);

  const initialValuesFilters = {
    orderBy: 'Data',
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear().toString(),
  };

  useEffect(() => {
    setValues({
      ...values,
      burn_time: getDifferenceInHours(values.hour_initial, values.hour_final),
    });
  }, [values.hour_initial, values.hour_final]);

  useEffect(() => {
    setFilters(initialValuesFilters);
    return () => {
      setFilters(initialValuesFilters);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const days_lastMonthOfYear = getDaysInMonth(filters.month, filters.year);

    setLoadingPage(true);
    setHasMoreRegister(true);

    try {
      api
        .get('/pans', {
          params: {
            _page: page,
            _limit: MAX_ITEMS,
            _interval_date:
              filters.month < 10
                ? `${filters.year}-0${filters.month}-01|${filters.year}-0${filters.month}-${days_lastMonthOfYear}`
                : `${filters.year}-${filters.month}-01|${filters.year}-${filters.month}-${days_lastMonthOfYear}`,
            _order: filters.orderBy === 'Data' ? `date|DESC` : `pan_number|ASC`,
          },
          signal: abortController.signal,
        })
        .then((response) => {
          if (isMounted) {
            setTotalPage(Math.ceil(response.data.count / MAX_ITEMS));
            setCountRegister(response.data.count);
            page <= totalPage || totalPage === 0
              ? setPans([...pans, ...response.data.rows])
              : setHasMoreRegister(false);
          }
        });
    } catch (err) {
      // error
    }

    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);

    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [page, countCallApi]);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(PanActions.deletePanRequest(event.id));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  function handleSearchPans() {
    setPans([]);
    setPage(1);
    setCountCallApi(countCallApi + 1);
  }

  function setFormToAdd(show) {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
  }

  function setFormToEdit(show, pan) {
    const {date, pan_number, hour_initial, hour_final, burn_time, humidity} =
      pan;

    setValues({
      date: new Date(date),
      pan_number,
      hour_initial: new Date(hour_initial),
      hour_final: new Date(hour_final),
      burn_time,
      humidity,
    });
    setPanToEdit(pan);
    setShowFormToEdit(show);
    setActionAllowed(false);
  }

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        const schema = Yup.object().shape({
          burn_time: Yup.string().required(
            'É necessário informar o tempo de queima da panela'
          ),
          humidity: Yup.string().required('É necessário informar a umidade'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newPan = {
          user_id: profile.id,
          date: values.date,
          pan_number: data.pan_number,
          hour_initial: values.hour_initial,
          hour_final: values.hour_final,
          burn_time: data.burn_time,
          humidity: data.humidity,
        };

        // console.tron.log(newPan);
        // if (!actionAllowed) {
        //   await addToast({
        //     type: 'confirm',
        //     title: `Confirmação de permissão de acesso`,
        //     description: `${email} é uma pessoa colaboradora na empresa e permito que ela receba um código único de acesso ao sistema?`,
        //   });
        // }

        // if (actionAllowed) {
        dispatch(PanActions.createPanRequest(newPan));

        // if (!error) {

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        //   setSignUpSuccess(true);
        // } else {
        //   addToast({
        //     type: 'info',
        //     title: 'Não foi possível adicionar colaborador(a)',
        //     description:
        //       'Verifique se o e-mail informado ja está cadastrado no sistema.',
        //   });
        //   setSignUpSuccess(false);
        // }
        setFormToAdd(false);
        // }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        const schema = Yup.object().shape({
          burn_time: Yup.string().required(
            'É necessário informar o tempo de queima da panela'
          ),
          humidity: Yup.string().required('É necessário informar a umidade'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const pan = {
          id: panToEdit.id,
          date: values.date,
          pan_number: data.pan_number,
          hour_initial: values.hour_initial,
          hour_final: values.hour_final,
          burn_time: data.burn_time,
          humidity: data.humidity,
        };

        dispatch(PanActions.updatePanRequest(pan));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });
        setShowFormToEdit(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemovePan = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `O registro será removido do banco de dados do sistema e esta ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  return (
    <Container>
      <ContainerTitlePage className="container_title_page">
        <TitlePage className="title_page">Controle de queima</TitlePage>
        {profile.occupation !== 'Analista de Laboratório' && (
          <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
            <LottieAnimation
              width={50}
              height={50}
              animation={animationButtonAdd}
            />
            <p>Adicionar</p>
          </ContainerButtonAdd>
        )}
      </ContainerTitlePage>

      <ContentInRow>
        <ButtonSecondary
          width={12}
          height={3}
          onClick={() => handleSearchPans()}>
          <MdSearch size={18} fill={colors.primary} />
          Buscar registros
        </ButtonSecondary>
        <Paginate
          orderBy={[{value: 'Data'}, {value: 'Panela'}]}
          years={[
            {value: '2020'},
            {value: '2021'},
            {value: '2022'},
            {value: '2023'},
            {value: '2024'},
            {value: '2025'},
          ]}
        />
      </ContentInRow>

      {showFormToAdd && (
        <BackgroundOpacity className="overlay">
          <Content showForm={showFormToAdd}>
            <Form ref={formRefAdd} onSubmit={handleAddForm}>
              <HeaderForm>
                <TitleForm>Novo Registro</TitleForm>
                <ButtonCloseForm onClick={() => setFormToAdd(false)}>
                  <MdClose size={25} color={colors.primary} />
                </ButtonCloseForm>
              </HeaderForm>

              <ContentInRow>
                <DatePickerStyled>
                  <label htmlFor="date">Data:</label>
                  <DatePicker
                    name="date"
                    selected={values.date}
                    onChange={(date) => setValues({...values, date})}
                    customInput={<input className="input-datapicker" />}
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    calendarContainer={CalendarContainer}
                  />
                </DatePickerStyled>
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="pan_number">Panela:</label>
                <SimpleSelect
                  name="pan_number"
                  options={[
                    {value: '1', label: '1'},
                    {value: '2', label: '2'},
                    {value: '3', label: '3'},
                    {value: '4', label: '4'},
                    {value: '5', label: '5'},
                    {value: '6', label: '6'},
                    {value: '7', label: '7'},
                    {value: '8', label: '8'},
                  ]}
                />
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <DatePickerStyled
                  style={{display: 'flex', flexDirection: 'column'}}>
                  <label htmlFor="hour_initial">Horário de início:</label>

                  <DatePicker
                    name="hour_initial"
                    selected={values.hour_initial}
                    onChange={(hour_initial) =>
                      setValues({...values, hour_initial})
                    }
                    customInput={<input className="input-datapicker" />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Início"
                    timeIntervals={5}
                    dateFormat="HH:mm"
                    locale="pt"
                    calendarContainer={CalendarContainer}
                  />
                </DatePickerStyled>

                <DatePickerStyled
                  style={{display: 'flex', flexDirection: 'column'}}>
                  <label htmlFor="hour_final">Horário de término:</label>
                  <DatePicker
                    name="hour_final"
                    selected={values.hour_final}
                    onChange={(hour_final) =>
                      setValues({...values, hour_final})
                    }
                    customInput={<input className="input-datapicker" />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Término"
                    timeIntervals={5}
                    dateFormat="HH:mm"
                    locale="pt"
                    calendarContainer={CalendarContainer}
                  />
                </DatePickerStyled>
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="burn_time">Tempo de queima:</label>
                <ContentInput style={{width: '40%'}} className="input-block">
                  <Input
                    name="burn_time"
                    type="text"
                    value={values.burn_time}
                    placeholder="Exemplo: 00:00"
                    onChange={(e) =>
                      setValues({...values, burn_time: e.target.value})
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="humidity">Umidade:</label>
                <ContentInput style={{width: '40%'}} className="input-block">
                  <Input
                    name="humidity"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step="0.01"
                    min="0"
                    placeholder="Exemplo: 5,85"
                    onChange={(e) =>
                      setValues({...values, humidity: e.target.value})
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ButtonSecondary type="submit" className="button_submit">
                {loading ? (
                  <LottieAnimation
                    width={40}
                    height={40}
                    animation={animationLoadingButton}
                  />
                ) : (
                  <p>Adicionar</p>
                )}
              </ButtonSecondary>
            </Form>
          </Content>
        </BackgroundOpacity>
      )}

      {showFormToEdit && (
        <BackgroundOpacity className="overlay">
          <Content showForm={showFormToEdit}>
            <Form
              initialData={panToEdit}
              ref={formRefEdit}
              onSubmit={handleEditForm}>
              <HeaderForm>
                <TitleForm>Editar Registro</TitleForm>
                <ButtonCloseForm onClick={() => setShowFormToEdit(false)}>
                  <MdClose size={25} color={colors.primary} />
                </ButtonCloseForm>
              </HeaderForm>

              <ContentInRow>
                <DatePickerStyled>
                  <label htmlFor="date">Data:</label>
                  <DatePicker
                    name="date"
                    selected={values.date}
                    onChange={(date) => setValues({...values, date})}
                    customInput={<input className="input-datapicker" />}
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    calendarContainer={CalendarContainer}
                  />
                </DatePickerStyled>
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="pan_number">Panela:</label>
                <SimpleSelect
                  name="pan_number"
                  options={[
                    {value: '1', label: '1'},
                    {value: '2', label: '2'},
                    {value: '3', label: '3'},
                    {value: '4', label: '4'},
                    {value: '5', label: '5'},
                    {value: '6', label: '6'},
                    {value: '7', label: '7'},
                    {value: '8', label: '8'},
                  ]}
                />
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <DatePickerStyled
                  style={{display: 'flex', flexDirection: 'column'}}>
                  <label htmlFor="hour_initial">Horário de início:</label>

                  <DatePicker
                    name="hour_initial"
                    selected={values.hour_initial}
                    onChange={(hour_initial) =>
                      setValues({...values, hour_initial})
                    }
                    customInput={<input className="input-datapicker" />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Início"
                    timeIntervals={5}
                    dateFormat="HH:mm"
                    locale="pt"
                    calendarContainer={CalendarContainer}
                  />
                </DatePickerStyled>

                <DatePickerStyled
                  style={{display: 'flex', flexDirection: 'column'}}>
                  <label htmlFor="hour_final">Horário de término:</label>
                  <DatePicker
                    name="hour_final"
                    selected={values.hour_final}
                    onChange={(hour_final) =>
                      setValues({...values, hour_final})
                    }
                    customInput={<input className="input-datapicker" />}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Término"
                    timeIntervals={5}
                    dateFormat="HH:mm"
                    locale="pt"
                    calendarContainer={CalendarContainer}
                  />
                </DatePickerStyled>
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="burn_time">Tempo de queima:</label>
                <ContentInput style={{width: '40%'}} className="input-block">
                  <Input
                    name="burn_time"
                    type="text"
                    value={values.burn_time}
                    placeholder="Exemplo: 00:00"
                    onChange={(e) =>
                      setValues({...values, burn_time: e.target.value})
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ContentInRow>
                <label htmlFor="humidity">Umidade:</label>
                <ContentInput style={{width: '40%'}} className="input-block">
                  <Input
                    name="humidity"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    step="0.01"
                    min="0"
                    placeholder="Exemplo: 5,85"
                    onChange={(e) =>
                      setValues({...values, humidity: e.target.value})
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <LineHorizontal />

              <ButtonSecondary type="submit" className="button_submit">
                {loading ? (
                  <LottieAnimation
                    width={40}
                    height={40}
                    animation={animationLoadingButton}
                  />
                ) : (
                  <p>Salvar alterações</p>
                )}
              </ButtonSecondary>
            </Form>
          </Content>
        </BackgroundOpacity>
      )}

      <Table>
        <TableHeader>
          <tr>
            <th className="th-edit">Editar</th>
            <th>Data</th>
            <th>Colaborador(a)</th>
            <th>Turma</th>
            <th>Panela</th>
            <th>Horário de início</th>
            <th>Horário de término</th>
            <th>Tempo de queima</th>
            <th>Umidade</th>
            <th className="th-delete">Excluir</th>
          </tr>
        </TableHeader>

        {loadingPage ? (
          <TableBody>
            <tr>
              <td>
                <BackgroundOpacity className="overlay">
                  <LottieAnimation
                    width={100}
                    height={100}
                    animation={animationLoadingPage}
                  />
                </BackgroundOpacity>
              </td>
            </tr>
          </TableBody>
        ) : (
          <TableBody>
            {pans.map((pan) => (
              <tr key={pan.id}>
                {profile.occupation !== 'Analista de Laboratório' &&
                profile.id === pan.user_id ? (
                  <td
                    className="td-edit"
                    onClick={() => setFormToEdit(true, pan)}>
                    <MdEdit size={16} color={colors.primary} />
                  </td>
                ) : (
                  <td className="td-edit">
                    <MdVisibility size={16} color={colors.primary} />
                  </td>
                )}

                <td>{formatDateToShow(pan.date)}</td>
                <td>
                  {pan.user ? pan.user.name : pan.user_backup.split(':=')[1]}
                </td>
                <td>
                  {pan.user
                    ? pan.user.class_type
                    : pan.user_backup.split(':=')[2]}
                </td>
                <td>{pan.pan_number}</td>
                <td>{formatHourToShow(pan.hour_initial)}</td>
                <td>{formatHourToShow(pan.hour_final)}</td>
                <td>{pan.burn_time}</td>
                <td>{formatDecimal(pan.humidity)}</td>

                {profile.occupation !== 'Analista de Laboratório' &&
                profile.id === pan.user_id ? (
                  <td
                    className="td-delete"
                    onClick={() => handleRemovePan(pan.id)}>
                    <MdDelete size={16} color={colors.failure} />
                  </td>
                ) : (
                  <td className="td-delete">
                    <MdBlock size={16} color={colors.failure} />
                  </td>
                )}
              </tr>
            ))}
          </TableBody>
        )}
      </Table>
      {pans.length === 0 ? (
        <div className="container_information">
          <p>
            Você pode registrar uma nova informação clicando sobre o botão
            "Adicionar".
          </p>
        </div>
      ) : (
        hasMoreRegister &&
        !loadingPage &&
        pans.length !== countRegister && (
          <>
            <div style={{margin: 5, color: `${colors.primary}`}}>
              <span>
                <strong>
                  {pans.length} / {countRegister}
                </strong>{' '}
                registros encontrados
              </span>
            </div>
            <ButtonSecondary onClick={() => setPage(page + 1)}>
              Mostrar mais
            </ButtonSecondary>
          </>
        )
      )}
    </Container>
  );
}

export default PanControl;
