import React, {useRef, useEffect} from 'react';
import {useField} from '@unform/core';

import {Select} from './styles';

const SimpleSelect = ({name, options, ...rest}) => {
  const selectRef = useRef(null);

  const {fieldName, defaultValue, registerField} = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Select ref={selectRef} {...rest} defaultValue={defaultValue}>
      {options.map((option) => (
        <option key={option.value}>{option.label}</option>
      ))}
    </Select>
  );
};
export default SimpleSelect;
