import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  products: [],
};

const product = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: [...state.products, action.payload.data],
      };

    case Types.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: [
          ...state.products.map((productt) =>
            productt.id === action.payload.id ? action.payload.data : productt
          ),
        ],
      };

    case Types.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_PRODUCT_SUCCESS:
      return {
        loading: false,
        error: null,
        products: [
          ...state.products.filter(
            (productt) => productt.id !== action.payload.id
          ),
        ],
      };

    case Types.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default product;
