export const Types = {
  CREATE_PRODUCT_REQUEST: '@product/CREATE_PRODUCT_REQUEST',
  CREATE_PRODUCT_SUCCESS: '@product/CREATE_PRODUCT_SUCCESS',
  CREATE_PRODUCT_FAILURE: '@product/CREATE_PRODUCT_FAILURE',
  GET_PRODUCT_REQUEST: '@product/GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS: '@product/GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILURE: '@product/GET_PRODUCT_FAILURE',
  UPDATE_PRODUCT_REQUEST: '@product/UPDATE_PRODUCT_REQUEST',
  UPDATE_PRODUCT_SUCCESS: '@product/UPDATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_FAILURE: '@product/UPDATE_PRODUCT_FAILURE',
  DELETE_PRODUCT_REQUEST: '@product/DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: '@product/DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILURE: '@product/DELETE_PRODUCT_FAILURE',
};
