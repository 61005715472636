import React, {createContext, useState, useContext} from 'react';

export const PaginateContext = createContext();

const PaginateProvider = ({children}) => {
  const initialValues = {
    orderBy: 'Data',
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear().toString(),
  };

  const [filters, setFilters] = useState(initialValues);

  return (
    <PaginateContext.Provider value={{filters, setFilters}}>
      {children}
    </PaginateContext.Provider>
  );
};

function usePaginate() {
  const context = useContext(PaginateContext);

  if (!context) {
    throw new Error('usePaginate must be used within a PaginateProvider');
  }

  return context;
}

export {PaginateProvider, usePaginate};
