import {Types} from './types';
import {resetToken} from '../../../utils/resetToken';

const INITIAL_STATE = {
  signed: false,
  token: null,
  loading: false,
  error: null,
};

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SIGN_IN_REQUEST:
      return {...state, loading: true};

    case Types.SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        signed: true,
        loading: false,
        error: null,
      };

    case Types.SIGN_IN_FAILURE:
      return {
        ...state,
        signed: false,
        error: action.payload.error,
        loading: false,
      };

    case Types.SIGN_UP_REQUEST:
      return {...state, loading: true};

    case Types.SIGN_UP_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case Types.SIGN_UP_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.SIGN_OUT:
      resetToken();
      return {
        signed: false,
        token: null,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

export default auth;
