import {Types} from './types';

export const Actions = {
  updateProfileRequest: (data) => ({
    type: Types.UPDATE_PROFILE_REQUEST,
    payload: {data},
  }),
  updateProfileSuccess: (profile) => ({
    type: Types.UPDATE_PROFILE_SUCCESS,
    payload: {profile},
  }),
  updateProfileFailure: (error) => ({
    type: Types.UPDATE_PROFILE_FAILURE,
    payload: {error},
  }),
  deleteAccountRequest: (id) => ({
    type: Types.DELETE_ACCOUNT_REQUEST,
    payload: {id},
  }),
  deleteAccountSuccess: () => ({
    type: Types.DELETE_ACCOUNT_SUCCESS,
  }),
  deleteAccountFailure: (error) => ({
    type: Types.DELETE_ACCOUNT_FAILURE,
    payload: {error},
  }),
};
