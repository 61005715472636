/* eslint-disable no-underscore-dangle */
import styled, {css} from 'styled-components';
import {colors} from '../../assets/styles';
import {MIN_SIZE_DESKTOP_2} from '../../constants/BREAKPOINTS';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 48px;
  background: ${colors.white._100};

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0.15rem 0;

  :first-child {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    border: none;
  }
`;

export const Retangle = styled.div`
  width: ${(props) => (props.enableCustomBorder ? '50' : '10')}px;
  background: ${(props) => props.customColor};
  position: relative;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 26px solid transparent;
  border-bottom: 26px solid transparent;
  border-left: 26px solid ${(props) => props.customColor};
  position: relative;
  left: 50px;

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    left: 49px;
  }
`;

export const Title = styled.h2`
  color: ${colors.white._100};
  font-size: 1.2rem;
  font-weight: normal;
  position: relative;
  bottom: 36px;
  left: 6px;

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    font-size: 0.9rem;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-height: 100%;
`;

export const Description = styled.span`
  font-size: 1rem;
  font-weight: normal;
  line-height: 22px;
  width: 100%;

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    font-size: 1rem;
    line-height: 20px;
    font-size: 0.9rem;
    margin-top: 20px;
  }
`;

export const Value = styled.strong`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 14px;
  width: 100%;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    font-size: 1rem;
    line-height: 18px;
  }
`;
