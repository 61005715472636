export const Types = {
  CREATE_FONDANT_REQUEST: '@fondant/CREATE_FONDANT_REQUEST',
  CREATE_FONDANT_SUCCESS: '@fondant/CREATE_FONDANT_SUCCESS',
  CREATE_FONDANT_FAILURE: '@fondant/CREATE_FONDANT_FAILURE',
  GET_FONDANT_REQUEST: '@fondant/GET_FONDANT_REQUEST',
  GET_FONDANT_SUCCESS: '@fondant/GET_FONDANT_SUCCESS',
  GET_FONDANT_FAILURE: '@fondant/GET_FONDANT_FAILURE',
  UPDATE_FONDANT_REQUEST: '@fondant/UPDATE_FONDANT_REQUEST',
  UPDATE_FONDANT_SUCCESS: '@fondant/UPDATE_FONDANT_SUCCESS',
  UPDATE_FONDANT_FAILURE: '@fondant/UPDATE_FONDANT_FAILURE',
  DELETE_FONDANT_REQUEST: '@fondant/DELETE_FONDANT_REQUEST',
  DELETE_FONDANT_SUCCESS: '@fondant/DELETE_FONDANT_SUCCESS',
  DELETE_FONDANT_FAILURE: '@fondant/DELETE_FONDANT_FAILURE',
};
