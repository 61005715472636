import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as FondantConstraintActions} from '../../ducks/fondant/constraints/actions';

function* createFondantConstraint({payload}) {
  const {
    fondant_id,
    fondants,
    status_constraint,
    min_amount,
    max_amount,
    composition,
  } = payload.data;

  try {
    const response = yield call(api.post, '/fondants/constraints', {
      fondant_id,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    });

    const newData = {
      ...response.data,
      fondants,
    };

    yield put(FondantConstraintActions.createFondantSuccess(newData));
  } catch (error) {
    yield put(FondantConstraintActions.createFondantFailure(error.message));
  }
}

function* getFondantConstraint() {
  try {
    const response = yield call(api.get, '/fondants/constraints');
    yield put(FondantConstraintActions.getFondantSuccess(response.data));
  } catch (error) {
    yield put(FondantConstraintActions.getFondantFailure(error.message));
  }
}

function* updateFondantConstraint({payload}) {
  try {
    const {
      id,
      fondant_id,
      fondants,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    } = payload.data;

    const fondant = {
      fondant_id,
      status_constraint,
      min_amount,
      max_amount,
      composition,
    };

    const {data} = yield call(api.put, `/fondants/constraints/${id}`, fondant);

    const fondantUpdated = {
      id: data.id,
      fondant_id: data.fondant_id,
      fondants,
      status_constraint: data.status_constraint,
      min_amount: data.min_amount,
      max_amount: data.max_amount,
      composition: data.composition,
    };

    yield put(
      FondantConstraintActions.updateFondantSuccess(id, fondantUpdated)
    );
  } catch (error) {
    yield put(FondantConstraintActions.updateFondantFailure(error.message));
  }
}

function* deleteFondantConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/fondants/constraints/${id}`);

    yield put(FondantConstraintActions.deleteFondantSuccess(id));
  } catch (error) {
    yield put(FondantConstraintActions.deleteFondantFailure(error.message));
  }
}

export {
  createFondantConstraint,
  getFondantConstraint,
  updateFondantConstraint,
  deleteFondantConstraint,
};
