import React from 'react';
import {Link} from 'react-router-dom';
import {
  MenuBottomContainer,
  MenuBottomContent,
  ButtonItem,
} from '../../../../assets/styles/CommonStyled';

const MenuBottom = ({btnPrimary}) => {
  return (
    <MenuBottomContainer>
      <MenuBottomContent>
        <ButtonItem
          className={btnPrimary === '1' ? 'btn-primary' : 'btn-secondary'}>
          <Link to="/produtos/restricoesdeproducao">
            Restrições de produção
          </Link>
        </ButtonItem>
        <ButtonItem
          className={btnPrimary === '2' ? 'btn-primary' : 'btn-secondary'}>
          <Link to="/produtos/restricoesdeespecificacao">
            Restrições de especificação
          </Link>
        </ButtonItem>
        <ButtonItem
          className={btnPrimary === '3' ? 'btn-primary' : 'btn-secondary'}>
          <Link to="/produtos/restricoesdecriticidades">
            Restrições de criticidades
          </Link>
        </ButtonItem>
        <ButtonItem
          className={btnPrimary === '4' ? 'btn-primary' : 'btn-secondary'}>
          <Link to="/produtos/restricoesdepesos">Restrições de pesos</Link>
        </ButtonItem>
      </MenuBottomContent>
    </MenuBottomContainer>
  );
};

export default MenuBottom;
