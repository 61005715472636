import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
};

const silo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_SILO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_SILO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case Types.CREATE_SILO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_SILO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_SILO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case Types.UPDATE_SILO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_SILO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_SILO_SUCCESS:
      return {
        loading: false,
        error: null,
      };

    case Types.DELETE_SILO_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default silo;
