import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  residues: [],
};

const residueRegister = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_RESIDUE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_RESIDUE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        residues: [...state.residues, action.payload.data],
      };

    case Types.CREATE_RESIDUE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_RESIDUE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_RESIDUE_SUCCESS:
      return {
        ...state,
        residues: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_RESIDUE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_RESIDUE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_RESIDUE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        residues: [
          ...state.residues.map((residue) =>
            residue.id === action.payload.id ? action.payload.data : residue
          ),
        ],
      };

    case Types.UPDATE_RESIDUE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_RESIDUE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_RESIDUE_SUCCESS:
      return {
        loading: false,
        error: null,
        residues: [
          ...state.residues.filter(
            (residue) => residue.id !== action.payload.id
          ),
        ],
      };

    case Types.DELETE_RESIDUE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default residueRegister;
