/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEdit, MdArrowBack} from 'react-icons/md';

import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  HorizontalArrow,
  ContainerTable,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {
  Content,
  ContentInRow,
  ButtonContainer,
  Space,
  SpaceBottom,
} from './styles';

import {colors} from '../../../../assets/styles';

import Input from '../../../../components/Input';
import LateralModal from '../../../../components/LateralModal';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import Menu from '../../../../components/Menu';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as ProductActions} from '../../../../store/ducks/product/actions';

import {useToast} from '../../../../contexts/ToastContext';

import {formatPercentage} from '../../../../utils/format';

import useKeys from '../../../../hooks/useKeys';
import MenuBottom from '../MenuBottom';

const EspecificationConstraint = () => {
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {products} = useSelector((state) => state.product);
  const {addToast, setActionAllowed} = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();

  const initialValuesInput = {
    name: 'ACIARIA',
    gusa_min: 100,
    gusa_goal: 1200,
    slag_min: 200,
    slag_max: 700,
    ib_goal: 70,
    ib_max: 71,
    composition_gusa: {
      Fe_original: 92,
      SiO2_original: 2.5,
      Al2O3_original: 0.0,
      CaOMgO_original: 0.0,
      Mn_original: 0.4,
      P_original: 0.15,
    },
    composition_slag: {
      Fe_original: 1,
      SiO2_original: 20,
      Al2O3_original: 16,
      CaOMgO_original: 30,
      Mn_original: 0.5,
      P_original: 0.0,
    },
    criticities: {
      Fe: 'HIPER CRITICO',
      Si: 'MUITO CRITICO',
      Al2O3: 'MUITO CRITICO',
      CaO: 'MUITO CRITICO',
      MgO: 'IRRELEVANTE',
      Mn: 'CRITICO',
      P: 'CRITICO',
    },
    global_weights: {
      quality_original: 65,
      basicity_original: 10,
      slag_volume_original: 2,
      cost_original: 20,
      gusa_original: 3,
    },
  };

  const [values, setValues] = useState(initialValuesInput);

  const getProducts = async () => {
    setLoadingPage(true);

    try {
      dispatch(ProductActions.getProductRequest());
      setTimeout(() => {
        setLoadingPage(false);
      }, 1000);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const setFormToAdd = (show) => {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
    setProductToEdit(null);
  };

  const setFormToEdit = (show, product) => {
    const {
      name,
      gusa_min,
      gusa_goal,
      slag_min,
      slag_max,
      ib_goal,
      ib_max,
      composition_gusa,
      composition_slag,
      criticities,
      global_weights,
    } = product;

    setValues({
      name,
      gusa_min,
      gusa_goal,
      slag_min,
      slag_max,
      ib_goal,
      ib_max,
      composition_gusa,
      composition_slag,
      criticities,
      global_weights,
    });

    setProductToEdit(product);
    setShowFormToEdit(show);
    setActionAllowed(false);
  };

  const onCloseForms = () => {
    setFormToAdd(false);
    setShowFormToEdit(false);
    setValues(initialValuesInput);
    setProductToEdit(null);
  };

  const schemaYup = Yup.object().shape({
    Fe_original_gusa: Yup.number()
      .required('É necessário informar a taxa de Fe no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    SiO2_original_gusa: Yup.number()
      .required('É necessário informar a taxa de SiO2 no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    Al2O3_original_gusa: Yup.number()
      .required('É necessário informar a taxa de Al2O3 no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    CaOMgO_original_gusa: Yup.number()
      .required('É necessário informar a taxa de CaOMgO no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    Mn_original_gusa: Yup.number()
      .required('É necessário informar a taxa de Mn no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    P_original_gusa: Yup.number()
      .required('É necessário informar a taxa de P no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),

    Fe_original_slag: Yup.number()
      .required('É necessário informar a taxa de Fe no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    SiO2_original_slag: Yup.number()
      .required('É necessário informar a taxa de SiO2 no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    Al2O3_original_slag: Yup.number()
      .required('É necessário informar a taxa de Al2O3 no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    CaOMgO_original_slag: Yup.number()
      .required('É necessário informar a taxa de CaOMgO no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    Mn_original_slag: Yup.number()
      .required('É necessário informar a taxa de Mn no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
    P_original_slag: Yup.number()
      .required('É necessário informar a taxa de P no minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
  });

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const productUpdated = {
          ...productToEdit,
          name: data.name,
          gusa_min: Number(data.gusa_min),
          gusa_goal: Number(data.gusa_goal),
          slag_min: Number(data.slag_min),
          slag_max: Number(data.slag_max),
          ib_goal: Number(data.ib_goal),
          ib_max: Number(data.ib_max),
          composition_gusa: {
            Fe_original: Number(data.Fe_original_gusa),
            SiO2_original: Number(data.SiO2_original_gusa),
            Al2O3_original: Number(data.Al2O3_original_gusa),
            CaOMgO_original: Number(data.CaOMgO_original_gusa),
            Mn_original: Number(data.Mn_original_gusa),
            P_original: Number(data.P_original_gusa),
          },
          composition_slag: {
            Fe_original: Number(data.Fe_original_slag),
            SiO2_original: Number(data.SiO2_original_slag),
            Al2O3_original: Number(data.Al2O3_original_slag),
            CaOMgO_original: Number(data.CaOMgO_original_slag),
            Mn_original: Number(data.Mn_original_slag),
            P_original: Number(data.P_original_slag),
          },
          criticities: {
            ...productToEdit.criticities,
          },
          global_weights: {
            ...productToEdit.global_weights,
          },
        };

        dispatch(
          ProductActions.updateProductRequest(
            Number(productToEdit.id),
            productUpdated
          )
        );

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  useEffect(() => {
    if (leftArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/residuos/cadastro');
    }
    if (rightArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/produtos/restricoesdeproducao');
    }
  }, [leftArrow, rightArrow, showFormToAdd, showFormToEdit]);

  return (
    <>
      <Container>
        <Menu />
        <HorizontalArrow>
          <Link to="/residuos/cadastro">
            <div className="icon">
              <MdArrowBack />
            </div>
          </Link>
        </HorizontalArrow>

        {(showFormToAdd || showFormToEdit) && (
          <BackgroundOpacity
            className="overlay"
            onClick={() => onCloseForms()}
          />
        )}

        <LateralModal
          open={showFormToAdd || showFormToEdit}
          title={showFormToAdd ? 'Novo Registro' : 'Editar Registro'}
          onClose={showFormToAdd ? setShowFormToAdd : setShowFormToEdit}>
          <Content>
            <Form
              initialData={productToEdit}
              ref={formRefEdit}
              onSubmit={handleEditForm}>
              <SpaceBottom />

              <strong>Meta de especificação de gusa (%): </strong>
              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="Fe_original_gusa">Fe:</label>
                  <Input
                    name="Fe_original_gusa"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_gusa?.Fe_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_gusa: {
                          ...values?.composition_gusa,
                          Fe_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="SiO2_original_gusa">SiO2:</label>
                  <Input
                    name="SiO2_original_gusa"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_gusa?.SiO2_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_gusa: {
                          ...values?.composition_gusa,
                          SiO2_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="Al2O3_original_gusa">Al2O3:</label>
                  <Input
                    name="Al2O3_original_gusa"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_gusa?.Al2O3_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_gusa: {
                          ...values?.composition_gusa,
                          Al2O3_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="CaOMgO_original_gusa">CaO+MgO:</label>
                  <Input
                    name="CaOMgO_original_gusa"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_gusa?.CaOMgO_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_gusa: {
                          ...values?.composition_gusa,
                          CaOMgO_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="Mn_original_gusa">Mn:</label>
                  <Input
                    name="Mn_original_gusa"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_gusa?.Mn_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_gusa: {
                          ...values?.composition_gusa,
                          Mn_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="P_original_gusa">P:</label>
                  <Input
                    name="P_original_gusa"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values.composition_gusa?.P_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_gusa: {
                          ...values?.composition_gusa,
                          P_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <div>
                <LineHorizontal />
              </div>

              <strong>Meta de especificação de escória (%): </strong>
              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="Fe_original_slag">Fe:</label>
                  <Input
                    name="Fe_original_slag"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_slag?.Fe_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_slag: {
                          ...values?.composition_slag,
                          Fe_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="SiO2_original_slag">SiO2:</label>
                  <Input
                    name="SiO2_original_slag"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_slag?.SiO2_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_slag: {
                          ...values?.composition_slag,
                          SiO2_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>

                <ContentInput className="input-block">
                  <label htmlFor="Al2O3_original_slag">Al2O3:</label>
                  <Input
                    name="Al2O3_original_slag"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_slag?.Al2O3_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_slag: {
                          ...values?.composition_slag,
                          Al2O3_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>

              <ContentInRow>
                <ContentInput className="input-block">
                  <label htmlFor="CaOMgO_original_slag">CaO+MgO:</label>
                  <Input
                    name="CaOMgO_original_slag"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_slag?.CaOMgO_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_slag: {
                          ...values?.composition_slag,
                          CaOMgO_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="Mn_original_slag">Mn:</label>
                  <Input
                    name="Mn_original_slag"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_slag?.Mn_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_slag: {
                          ...values?.composition_slag,
                          Mn_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
                <ContentInput className="input-block">
                  <label htmlFor="P_original_slag">P:</label>
                  <Input
                    name="P_original_slag"
                    type="number"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    placeholder="0"
                    value={values?.composition_slag?.P_original || 0}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        composition_slag: {
                          ...values?.composition_slag,
                          P_original: e.target.value,
                        },
                      })
                    }
                  />
                </ContentInput>
              </ContentInRow>
              <div>
                <LineHorizontal />
              </div>

              <ButtonContainer>
                <ButtonSecondary type="submit" className="button_submit">
                  {loading ? (
                    <LottieAnimation
                      width={40}
                      height={40}
                      animation={animationLoadingButton}
                    />
                  ) : (
                    <p>Salvar alterações</p>
                  )}
                </ButtonSecondary>
              </ButtonContainer>
              <Space />
            </Form>
          </Content>
        </LateralModal>

        <BoxContainer>
          <ContainerTitlePage className="container_title_page">
            <TitlePage className="title_page">Produção</TitlePage>
          </ContainerTitlePage>

          <ContainerTable>
            <Table>
              <TableHeader>
                <tr>
                  <th rowSpan="2" className="th-edit">
                    Editar
                  </th>
                  <th rowSpan="2">PRODUTO</th>
                  <th colSpan="6">GUSA (%)</th>
                  <th colSpan="6">ESCÓRIA (%)</th>
                </tr>

                <tr>
                  <th>Fe</th>
                  <th>Si</th>
                  <th>Al2O3</th>
                  <th>CaO</th>
                  <th>Mn</th>
                  <th>P</th>
                  <th>Fe</th>
                  <th>Si</th>
                  <th>Al2O3</th>
                  <th>CaO</th>
                  <th>Mn</th>
                  <th>P</th>
                </tr>
              </TableHeader>

              {loadingPage ? (
                <TableBody>
                  <tr>
                    <td>
                      <BackgroundOpacity className="overlay">
                        <LottieAnimation
                          width={100}
                          height={100}
                          animation={animationLoadingPage}
                        />
                      </BackgroundOpacity>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {products.map((product) => (
                    <tr key={product.id}>
                      <td
                        className="td-edit"
                        onClick={() => setFormToEdit(true, product)}>
                        <MdEdit size={16} color={colors.primary} />
                      </td>

                      <td>{product.name}</td>

                      <td>
                        {formatPercentage(
                          product?.composition_gusa?.Fe_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_gusa?.SiO2_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_gusa?.Al2O3_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_gusa?.CaOMgO_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_gusa?.Mn_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_gusa?.P_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>

                      {/* escória */}
                      <td>
                        {formatPercentage(
                          product?.composition_slag?.Fe_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_slag?.SiO2_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_slag?.Al2O3_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_slag?.CaOMgO_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_slag?.Mn_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                      <td>
                        {formatPercentage(
                          product?.composition_slag?.P_original || 0,
                          3,
                          '.',
                          ','
                        )}
                      </td>
                    </tr>
                  ))}
                </TableBody>
              )}
            </Table>

            {products.length === 0 && (
              <div className="container_information">
                <p>
                  Insira um novo registro clicando sobre o botão "Adicionar"
                </p>
              </div>
            )}
          </ContainerTable>
        </BoxContainer>
      </Container>
      <MenuBottom btnPrimary="2" />
    </>
  );
};

export default EspecificationConstraint;
