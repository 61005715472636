/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef, useCallback} from 'react';

import {Form} from '@unform/web';
import * as Yup from 'yup';

import {useDispatch, useSelector} from 'react-redux';

import {MdEdit, MdCheck, MdClose} from 'react-icons/md';

import Input from '../../../../components/Input';

import {colors} from '../../../../assets/styles';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as WasteActions} from '../../../../store/ducks/waste/globalConstraints/actions';

import {useToast} from '../../../../contexts/ToastContext';

import {
  WrapperConstraint,
  ConstraintContainer,
  ConstraintContent,
  ConstraintLabel,
  ConstraintValue,
  ContainerEditForm,
  ButtonEditForm,
} from '../../../../assets/styles/CommonStyled';

import {ContainerInput} from './styles';

const WasteGlobalConstraint = ({open = false, onClose}) => {
  const dispatch = useDispatch();
  const {wastesGlobalConstraints} = useSelector(
    (state) => state.wasteGlobalConstraint
  );
  const {addToast} = useToast();
  const formRefEdit = useRef(null);

  const initialValues = {
    waste_min_amount: wastesGlobalConstraints?.[0]?.waste_min_amount || 0,
    waste_max_amount: wastesGlobalConstraints?.[0]?.waste_max_amount || 700,
  };

  const [values, setValues] = useState(initialValues);

  const [loading, setLoading] = useState(false);

  const schemaYup = Yup.object().shape({
    waste_min_amount: Yup.number().required(
      'Informe a quantidade mínima de sucata a usar na mistura'
    ),
    waste_max_amount: Yup.number().required(
      'Informe a quantidade máxima de sucata a usar na mistura'
    ),
  });

  const getWastes = () => {
    setLoading(true);

    try {
      dispatch(WasteActions.getWasteRequest());
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWastes();
  }, []);

  useEffect(() => {
    if (wastesGlobalConstraints?.length !== 0) {
      setValues({
        waste_min_amount: wastesGlobalConstraints[0].waste_min_amount,
        waste_max_amount: wastesGlobalConstraints[0].waste_max_amount,
      });
    }
  }, [wastesGlobalConstraints]);

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const wasteUpdated = {
          id: Number(wastesGlobalConstraints?.[0]?.id || 1),
          waste_min_amount: Number(data.waste_min_amount),
          waste_max_amount: Number(data.waste_max_amount),
        };

        dispatch(WasteActions.updateWasteRequest(wasteUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setLoading(false);
    },
    [addToast, dispatch]
  );

  const onCloseForms = () => {
    onClose(false);
    setValues(initialValues);
  };

  return (
    <Form initialData={values} ref={formRefEdit} onSubmit={handleEditForm}>
      <WrapperConstraint>
        {!open ? (
          <ContainerEditForm>
            <MdEdit
              size={16}
              color={colors.primary}
              onClick={() => onClose(true)}
            />
          </ContainerEditForm>
        ) : (
          <ButtonEditForm type="submit">
            <MdCheck size={16} color={colors.primary} />
          </ButtonEditForm>
        )}

        <ConstraintContainer>
          <ConstraintContent>
            <ConstraintLabel>
              Quantidade mínima de sucata a usar na mistura (kg)
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>{`${values.waste_min_amount}`}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="waste_min_amount"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.waste_min_amount || 0}
                  onChange={(e) =>
                    setValues({...values, waste_min_amount: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>
              Quantidade máxima de sucata a usar na mistura (kg)
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>{`${values.waste_max_amount}`}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="waste_max_amount"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.waste_max_amount || 0}
                  onChange={(e) =>
                    setValues({...values, waste_max_amount: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>
        </ConstraintContainer>

        {open && (
          <ContainerEditForm>
            <MdClose
              size={16}
              color={colors.primary}
              onClick={() => onCloseForms()}
            />
          </ContainerEditForm>
        )}
      </WrapperConstraint>
    </Form>
  );
};

export default WasteGlobalConstraint;
