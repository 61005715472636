export default {
  primary:
    'radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(46,57,79,1) 0%, rgba(93,62,62,1) 100%, rgba(176,176,255,1) 100%, rgba(92,109,205,1) 100%, rgba(0,212,255,1) 100%)',
  secondary: '#E1E1E1',
  tertiary: '#CCCCCC',
  quaternary: '#333333',

  info: '#36679E',
  success: '#3F7636',
  failure: '#DA5C5C',

  grey: {
    _50: '#555555',
    _100: '#666666',
    _150: '#777777',
    _200: '#888888',
    _250: '#999999',
    _300: '#C1C1C1',
  },

  white: {
    _50: '#FFFFFF',
    _100: '#F1F1F1',
  },
};
