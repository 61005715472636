const addZeroInTime = (time) => (time.length === 1 ? `0${time}` : time);

export function formatDateToShow(d) {
  if (!d) {
    return;
  }
  const date = new Date(d);
  let day = date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month.toString();
  const year = date.getFullYear().toString();
  day = addZeroInTime(day);
  month = addZeroInTime(month);
  return `${day}/${month}/${year}`;
}

export function formatHourToShow(h) {
  if (!h) {
    return;
  }
  const date = new Date(h);
  const hour = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  // let seconds = date.getSeconds().toString();
  minutes = addZeroInTime(minutes);
  // seconds = addZeroInTime(seconds);
  return `${hour}:${minutes}`;
}

export function getDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getTodayOrTomorrow(date) {
  const dayToday = new Date().getDay();
  if (dayToday === new Date(date).getDay()) {
    return 'Hoje';
  }
  return 'Amanhã';
}

export function getDifferenceInDays(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}

export function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  let hours = diffInMs / 1000 / 60 / 60;
  let minutes = (diffInMs / 1000 / 60) % 60;

  hours = hours.toString().split('.');
  minutes = Math.round(minutes);

  const hh = hours[0] < 10 ? `0${hours[0]}` : hours[0];
  const mm = minutes < 10 ? `0${minutes}` : minutes;
  return `${hh}:${mm}`;
}

export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60);
}

export function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}
