/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdEdit, MdDelete, MdArrowForward, MdArrowBack} from 'react-icons/md';

import animationButtonAdd from '../../../../assets/lotties/button_add.json';
import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  HorizontalArrow,
  ContainerTable,
  ButtonItem,
  MenuBottomContainer,
  MenuBottomContent,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {Content, ButtonContainer} from './styles';

import {colors} from '../../../../assets/styles';

import Input from '../../../../components/Input';
import LateralModal from '../../../../components/LateralModal';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import Menu from '../../../../components/Menu';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as ResidueActions} from '../../../../store/ducks/residue/register/actions';

import {useToast} from '../../../../contexts/ToastContext';

import useKeys from '../../../../hooks/useKeys';

const ResidueRegister = () => {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {residues} = useSelector((state) => state.residueRegister);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [residueToEdit, setResidueToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const leftArrow = useKeys('ArrowLeft');
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);

  const windowResize = () => {
    const handleResize = () => {
      setWidthWindow(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  };

  useEffect(() => {
    windowResize();
  }, []);

  const initialValuesInput = {
    name: '',
  };

  const [values, setValues] = useState(initialValuesInput);

  const getResidues = () => {
    setLoadingPage(true);

    try {
      dispatch(ResidueActions.getResidueRequest());
      setTimeout(() => {
        setLoadingPage(false);
      }, 1000);
    } catch (err) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    getResidues();
  }, []);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(ResidueActions.deleteResidueRequest(Number(event.id)));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  const setFormToAdd = (show) => {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
    setResidueToEdit(null);
  };

  const setFormToEdit = (show, residue) => {
    const {name} = residue;

    setValues({
      name,
    });

    setResidueToEdit(residue);
    setShowFormToEdit(show);
    setActionAllowed(false);
  };

  const onCloseForms = () => {
    setFormToAdd(false);
    setShowFormToEdit(false);
    setValues(initialValuesInput);
    setResidueToEdit(null);
  };

  const schemaYup = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Mínimo 3 caracteres')
      .required('É necessário informar o nome do resíduo'),
  });

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const newResidue = {
          name: data.name,
        };

        dispatch(ResidueActions.createResidueRequest(newResidue));

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const residueUpdated = {
          id: Number(residueToEdit.id),
          name: data.name,
        };

        dispatch(ResidueActions.updateResidueRequest(residueUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemoveResidue = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `O registro será removido do banco de dados do sistema e esta ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  useEffect(() => {
    if (leftArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/fundentes/cadastro');
    }
    if (rightArrow && !showFormToAdd && !showFormToEdit) {
      history.push('/produtos/restricoesdeproducao');
    }
  }, [leftArrow, rightArrow, showFormToAdd, showFormToEdit]);

  return (
    <>
      <Container>
        <Menu />
        <HorizontalArrow>
          <Link to="/fundentes/cadastro">
            <div className="icon">
              <MdArrowBack />
            </div>
          </Link>
        </HorizontalArrow>
        <HorizontalArrow anchor="right">
          <Link to="/produtos/restricoesdeproducao">
            <div className="icon">
              <MdArrowForward />
            </div>
          </Link>
        </HorizontalArrow>

        {(showFormToAdd || showFormToEdit) && (
          <BackgroundOpacity
            className="overlay"
            onClick={() => onCloseForms()}
          />
        )}

        <LateralModal
          open={showFormToAdd || showFormToEdit}
          title={showFormToAdd ? 'Novo Registro' : 'Editar Registro'}
          onClose={showFormToAdd ? setShowFormToAdd : setShowFormToEdit}>
          <Content>
            <Form
              initialData={showFormToAdd ? values : residueToEdit}
              ref={showFormToAdd ? formRefAdd : formRefEdit}
              onSubmit={showFormToAdd ? handleAddForm : handleEditForm}>
              <ContentInput className="input-block">
                <label htmlFor="name">
                  <strong>Nome:</strong>
                </label>
                <Input
                  name="name"
                  type="text"
                  placeholder="PODEBALAO"
                  value={values.name.toUpperCase().trim()}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      name: e.target.value.toUpperCase().trim(),
                    })
                  }
                />
              </ContentInput>
              <LineHorizontal />

              <ButtonContainer>
                <ButtonSecondary type="submit" className="button_submit">
                  {loading ? (
                    <LottieAnimation
                      width={40}
                      height={40}
                      animation={animationLoadingButton}
                    />
                  ) : (
                    <p>{showFormToAdd ? 'Adicionar' : 'Salvar alterações'}</p>
                  )}
                </ButtonSecondary>
              </ButtonContainer>
            </Form>
          </Content>
        </LateralModal>

        <BoxContainer>
          <ContainerTitlePage className="container_title_page">
            <TitlePage className="title_page">Resíduos</TitlePage>
            {profile.occupation !== 'Analista de Laboratório' && (
              <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
                <LottieAnimation
                  width={50}
                  height={50}
                  animation={animationButtonAdd}
                />
                {widthWindow > 500 && <p>Adicionar</p>}
              </ContainerButtonAdd>
            )}
          </ContainerTitlePage>

          <ContainerTable>
            <Table>
              <TableHeader>
                <tr>
                  <th className="th-edit">Editar</th>
                  <th>RESÍDUO</th>
                  <th className="th-delete">Excluir</th>
                </tr>
              </TableHeader>

              {loadingPage ? (
                <TableBody>
                  <tr>
                    <td>
                      <BackgroundOpacity className="overlay">
                        <LottieAnimation
                          width={100}
                          height={100}
                          animation={animationLoadingPage}
                        />
                      </BackgroundOpacity>
                    </td>
                  </tr>
                </TableBody>
              ) : (
                <TableBody>
                  {residues?.length !== 0 &&
                    residues.map((residue) => (
                      <tr key={residue.id}>
                        <td
                          className="td-edit"
                          onClick={() => setFormToEdit(true, residue)}>
                          <MdEdit size={16} color={colors.primary} />
                        </td>
                        <td>{residue.name}</td>
                        <td
                          className="td-delete"
                          onClick={() => handleRemoveResidue(residue.id)}>
                          <MdDelete size={16} color={colors.failure} />
                        </td>
                      </tr>
                    ))}
                </TableBody>
              )}
            </Table>

            {residues.length === 0 && (
              <div className="container_information">
                <p>
                  Insira um novo registro clicando sobre o botão "Adicionar"
                </p>
              </div>
            )}
          </ContainerTable>
        </BoxContainer>
      </Container>

      <MenuBottomContainer>
        <MenuBottomContent>
          <ButtonItem className="btn-primary">
            <Link to="/residuos/cadastro">Cadastro de resíduos</Link>
          </ButtonItem>
          <ButtonItem className="btn-secondary">
            <Link to="/residuos/restricoes">Restrições de resíduos</Link>
          </ButtonItem>
        </MenuBottomContent>
      </MenuBottomContainer>
    </>
  );
};

export default ResidueRegister;
