export const Types = {
  CREATE_WASTE_REQUEST: '@waste/CREATE_WASTE_REQUEST',
  CREATE_WASTE_SUCCESS: '@waste/CREATE_WASTE_SUCCESS',
  CREATE_WASTE_FAILURE: '@waste/CREATE_WASTE_FAILURE',
  GET_WASTE_REQUEST: '@waste/GET_WASTE_REQUEST',
  GET_WASTE_SUCCESS: '@waste/GET_WASTE_SUCCESS',
  GET_WASTE_FAILURE: '@waste/GET_WASTE_FAILURE',
  UPDATE_WASTE_REQUEST: '@waste/UPDATE_WASTE_REQUEST',
  UPDATE_WASTE_SUCCESS: '@waste/UPDATE_WASTE_SUCCESS',
  UPDATE_WASTE_FAILURE: '@waste/UPDATE_WASTE_FAILURE',
  DELETE_WASTE_REQUEST: '@waste/DELETE_WASTE_REQUEST',
  DELETE_WASTE_SUCCESS: '@waste/DELETE_WASTE_SUCCESS',
  DELETE_WASTE_FAILURE: '@waste/DELETE_WASTE_FAILURE',
};
