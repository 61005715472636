import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  wastesGlobalConstraints: [],
};

const wasteGlobalConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_WASTEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_WASTEGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wastesGlobalConstraints: [...state.wastesGlobalConstraints, action.payload.data],
      };

    case Types.CREATE_WASTEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_WASTEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_WASTEGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        wastesGlobalConstraints: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_WASTEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_WASTEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_WASTEGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wastesGlobalConstraints: [
          ...state.wastesGlobalConstraints.map((wastee) =>
            wastee.id === action.payload.id ? action.payload.data : wastee
          ),
        ],
      };

    case Types.UPDATE_WASTEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_WASTEGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_WASTEGLOBALCONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        wastesGlobalConstraints: [
          ...state.wastesGlobalConstraints.filter((wastee) => wastee.id !== action.payload.id),
        ],
      };

    case Types.DELETE_WASTEGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default wasteGlobalConstraint;
