import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as UserActions} from '../../ducks/user/actions';

function* updateProfile({payload}) {
  try {
    const {id, name, email, occupation, class_type, ...rest} = payload.data;
    const profile = {
      name,
      email,
      occupation,
      class_type,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, `/users/${id}`, profile);

    yield put(UserActions.updateProfileSuccess(response.data));
  } catch (error) {
    yield put(UserActions.updateProfileFailure(error.message));
  }
}

function* deleteAccount({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/users/${id}`);

    yield put(UserActions.deleteAccountSuccess());
  } catch (error) {
    yield put(UserActions.deleteAccountFailure(error.message));
  }
}
export {updateProfile, deleteAccount};
