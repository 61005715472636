import {Types} from './types';

export const Actions = {
  createSiloRequest: (data) => ({
    type: Types.CREATE_SILO_REQUEST,
    payload: {data},
  }),
  createSiloSuccess: () => ({
    type: Types.CREATE_SILO_SUCCESS,
  }),
  createSiloFailure: (error) => ({
    type: Types.CREATE_SILO_FAILURE,
    payload: {error},
  }),
  updateSiloRequest: (data) => ({
    type: Types.UPDATE_SILO_REQUEST,
    payload: {data},
  }),
  updateSiloSuccess: () => ({
    type: Types.UPDATE_SILO_SUCCESS,
  }),
  updateSiloFailure: (error) => ({
    type: Types.UPDATE_SILO_FAILURE,
    payload: {error},
  }),
  deleteSiloRequest: (id) => ({
    type: Types.DELETE_SILO_REQUEST,
    payload: {id},
  }),
  deleteSiloSuccess: () => ({
    type: Types.DELETE_SILO_SUCCESS,
  }),
  deleteSiloFailure: (error) => ({
    type: Types.DELETE_SILO_FAILURE,
    payload: {error},
  }),
};
