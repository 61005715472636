import {Types} from './types';

export const Actions = {
  createProductRequest: (data) => ({
    type: Types.CREATE_PRODUCT_REQUEST,
    payload: {data},
  }),
  createProductSuccess: (data) => ({
    type: Types.CREATE_PRODUCT_SUCCESS,
    payload: {data},
  }),
  createProductFailure: (error) => ({
    type: Types.CREATE_PRODUCT_FAILURE,
    payload: {error},
  }),
  getProductRequest: () => ({
    type: Types.GET_PRODUCT_REQUEST,
  }),
  getProductSuccess: (data) => ({
    type: Types.GET_PRODUCT_SUCCESS,
    payload: {data},
  }),
  getProductFailure: (error) => ({
    type: Types.UPDATE_PRODUCT_FAILURE,
    payload: {error},
  }),
  updateProductRequest: (id, data) => ({
    type: Types.UPDATE_PRODUCT_REQUEST,
    payload: {id, data},
  }),
  updateProductSuccess: (id, data) => ({
    type: Types.UPDATE_PRODUCT_SUCCESS,
    payload: {id, data},
  }),
  updateProductFailure: (error) => ({
    type: Types.UPDATE_PRODUCT_FAILURE,
    payload: {error},
  }),
  deleteProductRequest: (id) => ({
    type: Types.DELETE_PRODUCT_REQUEST,
    payload: {id},
  }),
  deleteProductSuccess: (id) => ({
    type: Types.DELETE_PRODUCT_SUCCESS,
    payload: {id},
  }),
  deleteProductFailure: (error) => ({
    type: Types.DELETE_PRODUCT_FAILURE,
    payload: {error},
  }),
};
