import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import RegisterCollaborators from '../pages/RegisterCollaborators';
import EditProfile from '../pages/EditProfile';
import Dashboard from '../pages/Dashboard';
import SilosControl from '../pages/SilosControl';
import PanControl from '../pages/PanControl';
import LaboratoryControl from '../pages/LaboratoryControl';
import FrequencyControl from '../pages/FrequencyControl';
import Settings from '../pages/Settings';
import OreRegister from '../pages/AFCIMPModel/OrePage/OreRegister';
import OreConstraint from '../pages/AFCIMPModel/OrePage/OreConstraint';
import WasteRegister from '../pages/AFCIMPModel/WastePage/WasteRegister';
import WasteConstraint from '../pages/AFCIMPModel/WastePage/WasteConstraint';
import FondantRegister from '../pages/AFCIMPModel/FondantPage/FondantRegister';
import FondantConstraint from '../pages/AFCIMPModel/FondantPage/FondantConstraint';
import ResidueRegister from '../pages/AFCIMPModel/ResiduePage/ResidueRegister';
import ResidueConstraint from '../pages/AFCIMPModel/ResiduePage/ResidueConstraint';
import ProductionConstraint from '../pages/AFCIMPModel/ProductionPage/ProductionConstraint';
import EspecificationConstraint from '../pages/AFCIMPModel/ProductionPage/EspecificationConstraint';
import CriticalConstraint from '../pages/AFCIMPModel/ProductionPage/CriticalConstraint';
import WeightConstraint from '../pages/AFCIMPModel/ProductionPage/WeightConstraint';
import Leito from '../pages/AFCIMPModel/LeitoPage';

import AppProvider from '../contexts';
import {PaginateProvider} from '../contexts/PaginateContext';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <AppProvider>
          <Route path="/login" component={SignIn} />
          <Route path="/" exact component={Dashboard} isPrivate />
          <Route path="/perfil" component={EditProfile} isPrivate />
          <Route path="/leito" component={Leito} isPrivate />
          <Route path="/minerios/cadastro" component={OreRegister} isPrivate />
          <Route
            path="/minerios/restricoes"
            component={OreConstraint}
            isPrivate
          />
          <Route path="/sucatas/cadastro" component={WasteRegister} isPrivate />
          <Route
            path="/sucatas/restricoes"
            component={WasteConstraint}
            isPrivate
          />
          <Route
            path="/fundentes/cadastro"
            component={FondantRegister}
            isPrivate
          />
          <Route
            path="/fundentes/restricoes"
            component={FondantConstraint}
            isPrivate
          />
          <Route
            path="/residuos/cadastro"
            component={ResidueRegister}
            isPrivate
          />
          <Route
            path="/residuos/restricoes"
            component={ResidueConstraint}
            isPrivate
          />
          <Route
            path="/produtos/restricoesdeproducao"
            component={ProductionConstraint}
            isPrivate
          />
          <Route
            path="/produtos/restricoesdeespecificacao"
            component={EspecificationConstraint}
            isPrivate
          />
          <Route
            path="/produtos/restricoesdecriticidades"
            component={CriticalConstraint}
            isPrivate
          />
          <Route
            path="/produtos/restricoesdepesos"
            component={WeightConstraint}
            isPrivate
          />
          <PaginateProvider>
            <Route path="/silos" component={SilosControl} isPrivate />
            <Route path="/panelas" component={PanControl} isPrivate />
          </PaginateProvider>
          <Route path="/laboratorio" component={LaboratoryControl} isPrivate />
          <Route path="/frequencia" component={FrequencyControl} isPrivate />
          <Route path="/cadastro" component={RegisterCollaborators} isPrivate />
          <Route path="/configuracoes" component={Settings} isPrivate />
        </AppProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
