import {combineReducers} from 'redux';

import auth from './auth';
import user from './user';
import silo from './silo';
import pan from './pan';
import oreRegister from './ore/register';
import oreConstraint from './ore/constraints';
import oreGlobalConstraint from './ore/globalConstraints';
import wasteRegister from './waste/register';
import wasteConstraint from './waste/constraints';
import wasteGlobalConstraint from './waste/globalConstraints';
import fondantRegister from './fondant/register';
import fondantConstraint from './fondant/constraints';
import residueRegister from './residue/register';
import residueConstraint from './residue/constraints';
import residueGlobalConstraint from './residue/globalConstraints';
import product from './product';
import leito from './leito';

export default combineReducers({
  auth,
  user,
  silo,
  pan,
  oreRegister,
  oreConstraint,
  oreGlobalConstraint,
  wasteRegister,
  wasteConstraint,
  wasteGlobalConstraint,
  fondantRegister,
  fondantConstraint,
  residueRegister,
  residueConstraint,
  residueGlobalConstraint,
  product,
  leito,
});
