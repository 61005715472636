import {Types} from './types';

export const Actions = {
  createWasteRequest: (data) => ({
    type: Types.CREATE_WASTECONSTRAINT_REQUEST,
    payload: {data},
  }),
  createWasteSuccess: (data) => ({
    type: Types.CREATE_WASTECONSTRAINT_SUCCESS,
    payload: {data},
  }),
  createWasteFailure: (error) => ({
    type: Types.CREATE_WASTECONSTRAINT_FAILURE,
    payload: {error},
  }),
  getWasteRequest: () => ({
    type: Types.GET_WASTECONSTRAINT_REQUEST,
  }),
  getWasteSuccess: (data) => ({
    type: Types.GET_WASTECONSTRAINT_SUCCESS,
    payload: {data},
  }),
  getWasteFailure: (error) => ({
    type: Types.UPDATE_WASTECONSTRAINT_FAILURE,
    payload: {error},
  }),
  updateWasteRequest: (data) => ({
    type: Types.UPDATE_WASTECONSTRAINT_REQUEST,
    payload: {data},
  }),
  updateWasteSuccess: (id, data) => ({
    type: Types.UPDATE_WASTECONSTRAINT_SUCCESS,
    payload: {id, data},
  }),
  updateWasteFailure: (error) => ({
    type: Types.UPDATE_WASTECONSTRAINT_FAILURE,
    payload: {error},
  }),
  deleteWasteRequest: (id) => ({
    type: Types.DELETE_WASTECONSTRAINT_REQUEST,
    payload: {id},
  }),
  deleteWasteSuccess: (id) => ({
    type: Types.DELETE_WASTECONSTRAINT_SUCCESS,
    payload: {id},
  }),
  deleteWasteFailure: (error) => ({
    type: Types.DELETE_WASTECONSTRAINT_FAILURE,
    payload: {error},
  }),
};
