import {Types} from './types';

export const Actions = {
  createResidueRequest: (data) => ({
    type: Types.CREATE_RESIDUEGLOBALCONSTRAINT_REQUEST,
    payload: {data},
  }),
  createResidueSuccess: (data) => ({
    type: Types.CREATE_RESIDUEGLOBALCONSTRAINT_SUCCESS,
    payload: {data},
  }),
  createResidueFailure: (error) => ({
    type: Types.CREATE_RESIDUEGLOBALCONSTRAINT_FAILURE,
    payload: {error},
  }),
  getResidueRequest: () => ({
    type: Types.GET_RESIDUEGLOBALCONSTRAINT_REQUEST,
  }),
  getResidueSuccess: (data) => ({
    type: Types.GET_RESIDUEGLOBALCONSTRAINT_SUCCESS,
    payload: {data},
  }),
  getResidueFailure: (error) => ({
    type: Types.UPDATE_RESIDUEGLOBALCONSTRAINT_FAILURE,
    payload: {error},
  }),
  updateResidueRequest: (data) => ({
    type: Types.UPDATE_RESIDUEGLOBALCONSTRAINT_REQUEST,
    payload: {data},
  }),
  updateResidueSuccess: (id, data) => ({
    type: Types.UPDATE_RESIDUEGLOBALCONSTRAINT_SUCCESS,
    payload: {id, data},
  }),
  updateResidueFailure: (error) => ({
    type: Types.UPDATE_RESIDUEGLOBALCONSTRAINT_FAILURE,
    payload: {error},
  }),
  deleteResidueRequest: (id) => ({
    type: Types.DELETE_RESIDUEGLOBALCONSTRAINT_REQUEST,
    payload: {id},
  }),
  deleteResidueSuccess: (id) => ({
    type: Types.DELETE_RESIDUEGLOBALCONSTRAINT_SUCCESS,
    payload: {id},
  }),
  deleteResidueFailure: (error) => ({
    type: Types.DELETE_RESIDUEGLOBALCONSTRAINT_FAILURE,
    payload: {error},
  }),
};
