import styled, {css} from 'styled-components';
import colors from '../../assets/styles/colors';

export const Container = styled.div.attrs((props) => ({
  disabled: props.showSidebar,
}))`
  position: fixed;
  z-index: 20;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15rem;
  height: 100%;
  background: ${colors.primary};
  -webkit-box-shadow: 1px 1px 3px 1px ${colors.quaternary};
  -moz-box-shadow: 1px 1px 3px 1px ${colors.quaternary};
  box-shadow: 1px 1px 3px 1px ${colors.quaternary};

  ${(props) =>
    props.showSidebar
      ? css`
          transition: all ease 0.5s;
          transform: translateX(0);
        `
      : css`
          transition: all ease 0.5s;
          transform: translateX(-15rem);
        `}

  @media (min-width: 700px) {
    width: 26rem;

    ${(props) =>
      props.showSidebar
        ? css`
            transition: all ease 0.5s;
            transform: translateX(0);
          `
        : css`
            transition: all ease 0.5s;
            transform: translateX(-26rem);
          `}
  }
`;
