import {Types} from './types';

export const Actions = {
  createOreRequest: (data) => ({
    type: Types.CREATE_ORECONSTRAINT_REQUEST,
    payload: {data},
  }),
  createOreSuccess: (data) => ({
    type: Types.CREATE_ORECONSTRAINT_SUCCESS,
    payload: {data},
  }),
  createOreFailure: (error) => ({
    type: Types.CREATE_ORECONSTRAINT_FAILURE,
    payload: {error},
  }),
  getOreRequest: () => ({
    type: Types.GET_ORECONSTRAINT_REQUEST,
  }),
  getOreSuccess: (data) => ({
    type: Types.GET_ORECONSTRAINT_SUCCESS,
    payload: {data},
  }),
  getOreFailure: (error) => ({
    type: Types.UPDATE_ORECONSTRAINT_FAILURE,
    payload: {error},
  }),
  updateOreRequest: (data) => ({
    type: Types.UPDATE_ORECONSTRAINT_REQUEST,
    payload: {data},
  }),
  updateOreSuccess: (id, data) => ({
    type: Types.UPDATE_ORECONSTRAINT_SUCCESS,
    payload: {id, data},
  }),
  updateOreFailure: (error) => ({
    type: Types.UPDATE_ORECONSTRAINT_FAILURE,
    payload: {error},
  }),
  deleteOreRequest: (id) => ({
    type: Types.DELETE_ORECONSTRAINT_REQUEST,
    payload: {id},
  }),
  deleteOreSuccess: (id) => ({
    type: Types.DELETE_ORECONSTRAINT_SUCCESS,
    payload: {id},
  }),
  deleteOreFailure: (error) => ({
    type: Types.DELETE_ORECONSTRAINT_FAILURE,
    payload: {error},
  }),
};
