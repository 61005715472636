import styled, {keyframes, css} from 'styled-components';
import {colors} from '../../assets/styles';

const fadeIn = keyframes`
   from {
     opacity: 0;
  }
   to {
     opacity: 1;
   }
`;

const fadeOut = keyframes`
   from {
     opacity: 1;
  }
   to {
     opacity: 0;
   }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 700px;

  form {
    background: ${colors.secondary};
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    margin: 0.8rem 0;
    padding: 2rem;
    width: 400px;
    border-radius: 8px;
  }

  div > label {
    color: ${colors.primary};
    font-size: 1.2rem;
  }

  div > select {
    height: 2rem;
  }

  .button_submit {
    transition: 0.5s;
    background: ${colors.primary};
    color: ${colors.secondary};

    &:hover {
      opacity: 0.8;
      color: ${colors.primary};
    }
  }

  ${(props) =>
    props.showForm
      ? css`
          form {
            animation: ${fadeIn} 0.5s;
          }
        `
      : css`
          form {
            animation: ${fadeOut} 0.5s;
          }
        `}
`;

export const HeaderForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  border-top: 2px solid ${colors.primary};
`;

export const TitleForm = styled.h2`
  color: ${colors.primary};
`;

export const ContentInRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  input {
    width: 100%;
    margin: 0.2rem;
    padding: 0.2rem;
  }
`;
