/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import {MdPerson, MdEdit, MdDelete} from 'react-icons/md';

import animationButtonAdd from '../../assets/lotties/button_add.json';
import animationLoadingButton from '../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonSecondary,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
  ContentInput,
} from '../../assets/styles/CommonStyled';
import {Content, ContentInRow} from './styles';
import {colors} from '../../assets/styles';

import Input from '../../components/Input';
import SimpleSelect from '../../components/Select';
import LateralModal from '../../components/LateralModal';
import LottieAnimation from '../../components/Animation/LottieAnimation';

import getValidationErrors from '../../utils/getValidationErrors';
import {Actions as AuthActions} from '../../store/ducks/auth/actions';
import {Actions as UserActions} from '../../store/ducks/user/actions';
import {useToast} from '../../contexts/ToastContext';

import api from '../../services/api';
import {ButtonContainer} from '../AFCIMPModel/OrePage/OreRegister/styles';

function RegisterCollaborators() {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  // const {profile} = useSelector((state) => state.user);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [users, setUsers] = useState([]);
  const [countCallApi, setCountCallApi] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    setLoading(true);
    try {
      api.get('/users', {signal: abortController.signal}).then((response) => {
        if (isMounted) {
          setUsers(response.data);
        }
      });
    } catch (err) {
      console.tron.log('Erro', err);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [countCallApi]);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(UserActions.deleteAccountRequest(event.id));
      addToast({
        type: 'success',
        title: `Colaborador(a) removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
      setCountCallApi(countCallApi + 1);
    }
  }, [actionAllowed]);

  function setFormToAdd(show) {
    setShowFormToAdd(show);
    setActionAllowed(false);
  }

  function setFormToEdit(show) {
    setShowFormToEdit(show);
    setActionAllowed(false);
  }

  function handleEditUser(show, user) {
    setUserToEdit(user);
    setShowFormToEdit(show);
    setActionAllowed(false);
  }

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Informe um e-mail válido'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const {name, email, occupation, class_type} = data;
        const password = Math.random().toString(36).slice(-10).toUpperCase();

        if (!actionAllowed) {
          await addToast({
            type: 'confirm',
            title: `Confirmação de permissão de acesso`,
            description: `${email} é uma pessoa colaboradora na empresa e permito que ela receba um código único de acesso ao sistema?`,
          });
        }

        if (actionAllowed) {
          dispatch(
            AuthActions.signUpRequest(
              name,
              email,
              password,
              occupation,
              class_type
            )
          );

          // if (!error) {
          addToast({
            type: 'success',
            title: `Colaborador(a) ${name} adicionado com sucesso`,
            description:
              'Enviamos um e-mail com o código de acesso a esta pessoa.',
          });

          setCountCallApi(countCallApi + 1);
          //   setSignUpSuccess(true);
          // } else {
          //   addToast({
          //     type: 'info',
          //     title: 'Não foi possível adicionar colaborador(a)',
          //     description:
          //       'Verifique se o e-mail informado ja está cadastrado no sistema.',
          //   });
          //   setSignUpSuccess(false);
          // }
          setFormToAdd(false);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar colaborador(a)',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, actionAllowed, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Informe um e-mail válido'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const user = {id: userToEdit.id, ...data};
        dispatch(UserActions.updateProfileRequest(user));

        addToast({
          type: 'success',
          title: 'Os dados do colaborador(a) foram atualizados',
        });

        setCountCallApi(countCallApi + 1);
        setFormToEdit(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar dados do colaborador(a)',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, actionAllowed, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemoveUser = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `Ao excluir está pessoa ela perderá o acesso ao sistema e está ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  return (
    <Container>
      <ContainerTitlePage className="container_title_page">
        <TitlePage className="title_page">Cadastro de colaboradores</TitlePage>
        <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
          <LottieAnimation
            width={50}
            height={50}
            animation={animationButtonAdd}
          />
          <p>Adicionar</p>
        </ContainerButtonAdd>
      </ContainerTitlePage>

      {showFormToAdd && <BackgroundOpacity className="overlay" />}
      <LateralModal
        open={showFormToAdd}
        title="Dados Pessoais"
        onClose={setShowFormToAdd}>
        <Content>
          <Form ref={formRefAdd} onSubmit={handleAddForm}>
            <ContentInRow>
              <ContentInput className="input-block">
                <label htmlFor="name">
                  <strong>Nome:</strong>
                </label>
                <Input
                  name="email"
                  icon={MdPerson}
                  type="name"
                  placeholder="Nome"
                />
              </ContentInput>
            </ContentInRow>

            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="occupation">Função:</label>
              <SimpleSelect
                name="occupation"
                options={[
                  {value: 'Superintendente', label: 'Superintendente'},
                  {value: 'Supervisor', label: 'Supervisor'},
                  {value: 'Líder de Projeto', label: 'Líder de Projeto'},
                  {
                    value: 'Analista de Laboratório',
                    label: 'Analista de Laboratório',
                  },
                ]}
              />
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="class_type">Turma:</label>
              <SimpleSelect
                name="class_type"
                options={[
                  {value: '---', label: '---'},
                  {value: 'A', label: 'A'},
                  {value: 'B', label: 'B'},
                ]}
              />
            </ContentInRow>
            <LineHorizontal />

            <ButtonContainer>
              <ButtonSecondary type="submit" className="button_submit">
                {!actionAllowed ? (
                  <p>Adicionar</p>
                ) : loading ? (
                  <LottieAnimation
                    width={40}
                    height={40}
                    animation={animationLoadingButton}
                  />
                ) : (
                  <p>Finalizar cadastro</p>
                )}
              </ButtonSecondary>
            </ButtonContainer>
          </Form>
        </Content>
      </LateralModal>

      {showFormToEdit && <BackgroundOpacity className="overlay" />}
      <LateralModal
        open={showFormToEdit}
        title="Dados Pessoais"
        onClose={setShowFormToEdit}>
        <Content>
          <Form
            initialData={userToEdit}
            ref={formRefEdit}
            onSubmit={handleEditForm}>
            <Input name="name" icon={MdPerson} type="name" placeholder="Nome" />
            <LineHorizontal />

            <ContentInRow>
              <ContentInput className="input-block">
                <label htmlFor="name">
                  <strong>Nome:</strong>
                </label>
                <Input
                  name="email"
                  icon={MdPerson}
                  type="name"
                  placeholder="Nome"
                />
              </ContentInput>
            </ContentInRow>

            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="occupation">Função:</label>
              <SimpleSelect
                name="occupation"
                options={[
                  {value: 'Superintendente', label: 'Superintendente'},
                  {value: 'Supervisor', label: 'Supervisor'},
                  {value: 'Líder de Projeto', label: 'Líder de Projeto'},
                  {
                    value: 'Analista de Laboratório',
                    label: 'Analista de Laboratório',
                  },
                ]}
              />
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="class_type">Turma:</label>
              <SimpleSelect
                name="class_type"
                options={[
                  {value: '---', label: '---'},
                  {value: 'A', label: 'A'},
                  {value: 'B', label: 'B'},
                ]}
              />
            </ContentInRow>
            <LineHorizontal />

            <ButtonSecondary type="submit" className="button_submit">
              {loading ? (
                <LottieAnimation
                  width={40}
                  height={40}
                  animation={animationLoadingButton}
                />
              ) : (
                <p>Salvar alterações</p>
              )}
            </ButtonSecondary>
          </Form>
        </Content>
      </LateralModal>

      <Table>
        <TableHeader>
          <tr>
            <th className="th-edit">Editar</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Função</th>
            <th>Turma</th>
            <th className="th-delete">Excluir</th>
          </tr>
        </TableHeader>

        {loading ? (
          <TableBody>
            <tr>
              <td>
                <BackgroundOpacity className="overlay">
                  <LottieAnimation
                    width={100}
                    height={100}
                    animation={animationLoadingPage}
                  />
                </BackgroundOpacity>
              </td>
            </tr>
          </TableBody>
        ) : (
          <TableBody>
            {users.map((user) => (
              <tr key={user.id}>
                <td
                  className="td-edit"
                  onClick={() => handleEditUser(true, user)}>
                  <MdEdit
                    style={{cursor: 'pointer'}}
                    size={16}
                    color={colors.primary}
                  />
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.occupation}</td>
                <td>{user.class_type}</td>
                <td
                  className="td-delete"
                  onClick={() => handleRemoveUser(user.id)}>
                  <MdDelete
                    style={{cursor: 'pointer'}}
                    size={16}
                    color={colors.failure}
                  />
                </td>
              </tr>
            ))}
          </TableBody>
        )}
      </Table>

      {users.length === 0 && (
        <div className="container_information">
          <p>
            Você pode registrar uma nova informação clicando sobre o botão
            "Adicionar".
          </p>
        </div>
      )}
    </Container>
  );
}

export default RegisterCollaborators;
