/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';
import {colors} from '../../../../assets/styles';

export const Content = styled.div`
  width: 93vw;
  height: 100vh;
  margin: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-areas: 'leito card';
`;

export const TableLeito = styled.div`
  grid-area: leito;
  width: 100%;
  height: auto;
  max-width: 280px;
  max-height: 73vh;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  background: ${colors.white._100};
  border-radius: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

export const LeitoHeader = styled.div`
  width: 100%;
  max-width: 280px;
  min-height: 40px;
  height: 30px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background: ${colors.primary};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
`;

export const LeitoHeaderLabel = styled.strong`
  color: ${colors.white._100};
  font-size: 10px;
  width: 100%;
  text-align: center;
`;

export const TitleLeito = styled.h1`
  color: ${colors.white._100};
  font-size: 25px;
  font-weight: bold;
  margin-left: 3rem;
  padding-bottom: 0.2rem;
`;

export const LeitoBody = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const MaterialContainer = styled.div`
  width: 100%;
  height: 35px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.tertiary};
`;

export const MaterialName = styled.span`
  width: 100%;
  color: #9542ff;
  font-size: 1.2rem;
  font-weight: bold;
  font-style: normal;
`;

export const MaterialValue = styled(MaterialName)`
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
`;

export const Wrapper = styled.div`
  grid-area: card;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

export const CardContent = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  background: ${colors.white._100};
  border: 5px solid ${(props) => props.customColor};
  margin: 10px 55px 0 58px;
  padding-bottom: 10px;
`;

export const DeviationContainer = styled.div`
  grid-area: card;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0px 5px 10px;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectContent = styled.div`
  margin: 10px 0px 0px 40px;

  label {
    font-size: 0.9.75rem;
    font-weight: 600;
  }

  select {
    margin-top: 10px;
  }
`;

export const Space = styled.div`
  margin: 5px 0;
`;

export const ButtonContainer = styled.div`
  width: 90%;
  height: auto;
  max-height: 80px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;

  .btn-primary {
    border-radius: 10px;
    background: ${colors.primary};
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

    a {
      color: ${colors.secondary};
    }
  }
`;
