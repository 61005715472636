import React from 'react';

import {
  Container,
  Retangle,
  Triangle,
  Title,
  Content,
  Description,
  Value,
} from './styles';

const Tag = ({
  title = '',
  description = '',
  value = '',
  customColor = '#6B8C6A',
  enableCustomBorder = false,
  valueColor,
}) => {
  return (
    <Container>
      <Retangle
        customColor={customColor}
        enableCustomBorder={enableCustomBorder}>
        {enableCustomBorder && <Triangle customColor={customColor} />}
        {enableCustomBorder && <Title>{title}</Title>}
      </Retangle>

      {description !== '' && value !== '' && (
        <Content>
          <Description>{description}</Description>
          <Value color={valueColor}>{value}</Value>
        </Content>
      )}
    </Container>
  );
};

export default Tag;
