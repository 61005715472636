import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as FondantActions} from '../../ducks/fondant/register/actions';

function* createFondant({payload}) {
  const {status, name, price, mass_available, bought_in} = payload.data;

  try {
    const response = yield call(api.post, '/fondants/register', {
      status,
      name,
      price,
      mass_available,
      bought_in,
    });

    yield put(FondantActions.createFondantSuccess(response.data));
  } catch (error) {
    yield put(FondantActions.createFondantFailure(error.message));
  }
}

function* getFondant() {
  try {
    const response = yield call(api.get, '/fondants/register');
    yield put(FondantActions.getFondantSuccess(response.data));
  } catch (error) {
    yield put(FondantActions.getFondantFailure(error.message));
  }
}

function* updateFondant({payload}) {
  try {
    const {id, status, name, price, mass_available, bought_in} = payload.data;

    const fondant = {
      status,
      name,
      price,
      mass_available,
      bought_in,
    };

    const {data} = yield call(api.put, `/fondants/register/${id}`, fondant);

    const fondantUpdated = {
      id: data.id,
      status: data.status,
      name: data.name,
      price: data.price,
      mass_available: data.mass_available,
      bought_in: data.bought_in,
    };

    yield put(FondantActions.updateFondantSuccess(id, fondantUpdated));
  } catch (error) {
    yield put(FondantActions.updateFondantFailure(error.message));
  }
}

function* deleteFondant({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/fondants/register/${id}`);

    yield put(FondantActions.deleteFondantSuccess(id));
  } catch (error) {
    yield put(FondantActions.deleteFondantFailure(error.message));
  }
}

export {createFondant, getFondant, updateFondant, deleteFondant};
