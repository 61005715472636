import {useEffect, useState} from 'react';

function useScreenSize() {
  const [screenSize, setScreenSize] = useState(() => {
    if (window) {
      return {width: window.innerWidth, height: window.innerHeight};
    }
    return {width: 0, height: 0};
  });

  useEffect(() => {
    function handleResize() {
      setScreenSize({width: window.innerWidth, height: window.innerHeight});
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSize;
}

export default useScreenSize;
