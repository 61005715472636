/* eslint-disable no-underscore-dangle */
import styled, {keyframes, css} from 'styled-components';
import {colors, metrics} from '.';
import {MIN_SIZE_DESKTOP_2} from '../../constants/BREAKPOINTS';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const left = keyframes`
  from{
    opacity: 0;
    transform:  translateX(1rem);
  }
  to{
    opacity: 1;
  }
  `;

const right = keyframes`
  from{
    opacity: 0;
    transform:  translateX(-1rem);
  }
  to{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 95%;
  height: 100%;
  background: ${colors.secondary};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 10px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const BoxContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${colors.secondary};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.75);

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const ButtonWithLoading = styled.button`
  width: 100%;
  height: 3.8rem;
  background: ${colors.primary};
  color: ${colors.secondary};
  border-radius: 16px;
  border: 0;
  margin-top: 0.8rem;
  transition: 1s;

  &:hover {
    opacity: 0.7;
  }

  ${(props) =>
    props.isLoading &&
    css`
      svg {
        animation: ${rotate} 1s linear infinite;
      }
    `}

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    height: 2.8rem;
  }
`;

export const ContainerTitlePage = styled.div``;

export const ContainerButtonAdd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;

  p {
    color: ${colors.primary};
    font: 500 0.8rem Quicksand;
  }

  @media (min-width: 500px) {
    margin: 2rem;

    p {
      font: 700 ${metrics.font.smaller}rem Quicksand;
    }
  }
`;

export const TitlePage = styled.h1``;

export const BackgroundOpacity = styled.div`
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8rem 0 3rem 0;

  label {
    color: ${colors.quaternary};
    font-size: 0.8rem;
  }

  span {
    font-size: 0.8rem;
  }

  .input-block {
    position: relative;
  }

  .input-block {
    margin-top: ${metrics.space.medium}rem;
  }

  .input-block input {
    width: 100%;
    height: ${metrics.space.bigger}rem;
    margin-top: 0.2rem;
    border-radius: 0.2rem;
    border: 1px solid ${colors.tertiary};
    outline: 0;
    padding: 0 ${metrics.space.smaller}rem;
    font: 0.7rem Quicksand;
  }

  .input-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 3px;
    content: '';
    background: ${colors.primary};
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
  }

  .button-access {
    width: 100%;
    height: 3.2rem;
    margin-top: 2rem;
    background: ${colors.primary};
    color: ${colors.secondary};
    border-radius: 0.4rem;
    font: 500 ${metrics.font.smaller}rem Quicksand;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
  }

  .button-access a {
    color: ${colors.secondary};
  }

  .button-access:hover {
    background: ${colors.tertiary};
  }

  @media (min-width: 700px) {
    label {
      font-size: 1.2rem;
    }

    span {
      font-size: 1.2rem;
    }

    .input-block input {
      font-size: 1.2rem;
    }
  }
`;

export const HeaderForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: ${colors.tertiary};
  padding: 1rem;
`;

export const ContentForm = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentInput = styled.div`
  margin: 0.5rem;
  width: 100%;
`;

export const DatePickerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    margin-right: 0.5rem;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .input-datapicker {
    width: 7rem;
    height: 1.8rem;
    padding: 0.5rem;
    background: ${colors.tertiary};
    border-radius: 0.2rem;
    font: 400 0.7rem Quicksand;

    @media (min-width: 700px) {
      width: 10rem;
      height: 2.5rem;
      font: 400 1rem Quicksand;
    }
  }
`;

export const ButtonCloseForm = styled.button``;

export const ButtonSecondary = styled.button`
  width: ${(props) => props.width || 100}%;
  height: ${(props) => props.height || 4}rem;
  margin: 0.5rem 0 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  border: 3px solid ${colors.primary};
  color: ${colors.primary};
  font-weight: bold;

  &:hover {
    background: ${colors.primary};
    color: ${colors.secondary};
    svg {
      fill: ${colors.secondary};
    }
  }
`;

export const TextCloseForm = styled.p`
  color: ${colors.primary};
  font: 500 ${metrics.font.smaller}rem Quicksand;
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: 75%;
  overflow-y: auto;
  border: 1px solid ${colors.white._100};
`;

export const Table = styled.table`
  width: 100%;
  height: auto;
  padding: 1rem;
`;

export const TableHeader = styled.thead`
  background: ${colors.primary};
  color: ${colors.tertiary};

  th {
    font-size: 0.5rem;
    background: ${colors.primary};
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    color: ${colors.secondary};

    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

    min-width: 140px;
    max-width: 150px;
    padding: 0.2rem;
    font-size: 0.9rem;
    border: 1px solid ${colors.white._100};
    border-radius: 4px;

    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }

  .th-edit {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: ${colors.secondary};
    color: ${colors.quaternary} !important;
    border: 0;
    border-radius: 0;
  }

  .th-delete {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: ${colors.secondary};
    color: ${colors.failure};
    border: 0;
    border-radius: 0;
  }

  .th-hidden {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border: 0px;
  }

  @media (min-width: 700px) {
    font-size: 1.1rem;
  }
`;

export const TableBody = styled.tbody`
  background: ${colors.white._100};
  font-size: 0.5rem;
  text-align: center;

  td {
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    min-width: 140px;
    max-width: 150px;
    border-top: 1px solid ${colors.grey._300};
    border-bottom: 1px solid ${colors.grey._300};
    border-left: 0;
    border-right: 0;
    color: ${colors.quaternary};
    padding: 0.3rem 0;
    font-weight: normal;

    border: 2px solid ${colors.white._100};
    border-radius: 2px;

    @media (max-width: 500px) {
      font-weight: lighter;
      font-size: 0.7rem;
      padding: 0.1rem 0;
    }
  }

  .td-edit,
  .td-delete {
    background: ${colors.white._100};
    cursor: pointer;
  }

  .td-edit {
    border-radius: 10px 0px 0px 10px;
    border-left: 1px solid ${colors.grey._300};
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  }

  .td-delete {
    border-radius: 0px 10px 10px 0px;
    border-right: 1px solid ${colors.grey._300};
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
  }

  .td-edit:hover {
    background: ${colors.primary};
    svg {
      fill: ${colors.secondary};
    }
  }

  .td-delete:hover {
    background: ${colors.failure};
    svg {
      fill: ${colors.secondary};
    }
  }

  @media (min-width: 700px) {
    font-size: 1.1rem;
  }
`;

export const LineHorizontal = styled.div`
  width: 95%;
  height: 1px;
  background: ${colors.tertiary};
  margin: 1rem;
`;

export const HorizontalArrow = styled.div`
  width: 70px;
  height: 7.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  cursor: pointer;
  z-index: 10;

  ${({anchor}) =>
    anchor === 'right'
      ? css`
          right: 0rem;
          background: linear-gradient(to left, #c2c2c285, transparent);
          animation: ${left} 0.4s;
        `
      : css`
          left: 0rem;
          background: linear-gradient(to right, #c2c2c285, transparent);
          animation: ${right} 0.4s;
        `}

  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${colors.secondary};
    svg {
      fill: ${colors.quaternary};
    }
  }

  &:hover {
    .icon {
      border: 2px solid ${colors.secondary};
      background: linear-gradient(180deg, #3f3d56 0%, #999 100%);
      svg {
        fill: ${colors.quaternary};
      }
    }
  }

  @media (max-width: 900px) {
    bottom: ${(props) => props.bottomSpace || '51'}px;
  }
`;

export const MenuBottomContainer = styled.div`
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.primary};
`;

export const MenuBottomContent = styled.div`
  width: 25%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-primary {
    border: 5px solid ${colors.primary};
    position: relative;

    background: ${colors.quaternary};
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

    display: flex;
    justify-content: center;
    align-items: center;

    a {
      height: 100%;
      color: ${colors.secondary};
      font-size: 10px;
      text-align: center;
      padding: 1.25rem 0;
      text-decoration: none;
      font-weight: bold;
    }
  }

  .btn-secondary {
    border: 5px solid ${colors.primary};
    background: ${colors.secondary};
    -webkit-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    -moz-box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);
    box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.137);

    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: ${colors.quaternary};
      font-size: 10px;
      height: 100%;
      text-align: center;
      padding: 1.25rem 0;
      text-decoration: none;
      font-weight: bold;
    }
  }

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    width: 100%;
    left: 0;
    right: 0;

    .btn-primary {
      a {
        font-size: 10px;
        padding: 1rem 0;
      }
    }

    .btn-secondary {
      a {
        font-size: 10px;
        padding: 1rem 0;
      }
    }
  }
`;

export const ButtonItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.primary};
  color: ${colors.secondary};

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    a {
      font-size: 0.75rem;
    }
  }
`;

export const TdStatus = styled.td`
  .container-status {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-status-container {
    width: 13%;
  }

  .circle-status {
    width: 9px;
    height: 9px;
    border-radius: 50%;

    background: ${({status}) => {
      if (['DISPONIVEL', 'ABERTO'].includes(status)) {
        return `${colors.success}`;
      }
      if (['INDISPONIVEL', 'ESGOTADO'].includes(status)) {
        return `${colors.failure}`;
      }
      return `${colors.info}`;
    }};
  }

  .title-status {
    width: 45%;
    margin-left: 5px;
    text-align: start;

    text-decoration: ${({status}) =>
      ['INDISPONIVEL', 'ESGOTADO'].includes(status) ? 'line-through' : 'none'};
    color: ${({status}) =>
      ['INDISPONIVEL', 'ESGOTADO'].includes(status)
        ? colors.failure
        : '#333333'};
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0.5rem;
`;

export const AlertMessage = styled.strong`
  text-align: justify;
  color: ${colors.failure};
`;

export const WrapperConstraint = styled.div`
  width: 100%;
  height: 100%;
  max-height: 80px;

  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-template-areas: 'button content button';
  justify-content: center;
  align-items: center;

  border-right: 1px solid ${colors.white._50};
  border-left: 1px solid ${colors.white._50};

  padding: 1rem;

  :hover {
    border: 3px solid ${colors.primary};
    opacity: 0.8;
    transition: 0.2s;
  }

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    display: flex;
    width: max-content;
    max-height: 100px;
  }
`;

export const ConstraintContainer = styled.div`
  width: 100%;
  height: 100%;
  grid-area: 'content';
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2.5rem;
`;

export const ConstraintContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: 0.5rem 1rem;
  background: ${colors.secondary};
  border-right: 1px solid ${colors.white._50};

  :last-child {
    border-right: 0;
  }
`;

export const ConstraintLabel = styled.span`
  font-size: 0.9rem;
`;

export const ConstraintValue = styled.strong`
  font-size: 1.2rem;
  margin-top: 0.2rem;
`;

export const ContainerEditForm = styled.div`
  width: 100%;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  grid-area: 'button';
`;

export const ButtonEditForm = styled.button`
  width: 100%;
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 'button';
`;
