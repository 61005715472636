/* eslint-disable import/no-cycle */
import React, {createContext, useCallback, useContext, useState} from 'react';
import {v4 as uuid} from 'uuid';
import ToastContainer from '../../components/Container/ToastContainer';

export const ToastContext = createContext({});

const ToastProvider = ({children}) => {
  const [messages, setMessages] = useState([]);
  const [actionAllowed, setActionAllowed] = useState(false);

  const addToast = useCallback(({type, title, description}) => {
    const id = uuid();
    const toast = {
      id,
      type,
      title,
      description,
    };

    setMessages((state) => [...state, toast]);
  }, []);

  const removeToast = useCallback((id) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider
      value={{addToast, removeToast, actionAllowed, setActionAllowed}}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export {ToastProvider, useToast};
