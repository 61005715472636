import styled from 'styled-components';
import {colors, metrics} from '../../assets/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: ${colors.primary};
`;

export const Title = styled.h1`
  color: ${colors.primary};
  font-size: ${metrics.font.small}rem;
  margin-top: 3rem;
  @media (min-width: 700px) {
    font-size: ${metrics.font.bigger}rem;
  }
`;
