export const Types = {
  CREATE_PAN_REQUEST: '@pan/CREATE_PAN_REQUEST',
  CREATE_PAN_SUCCESS: '@pan/CREATE_PAN_SUCCESS',
  CREATE_PAN_FAILURE: '@pan/CREATE_PAN_FAILURE',
  UPDATE_PAN_REQUEST: '@pan/UPDATE_PAN_REQUEST',
  UPDATE_PAN_SUCCESS: '@pan/UPDATE_PAN_SUCCESS',
  UPDATE_PAN_FAILURE: '@pan/UPDATE_PAN_FAILURE',
  DELETE_PAN_REQUEST: '@pan/DELETE_PAN_REQUEST',
  DELETE_PAN_SUCCESS: '@pan/DELETE_PAN_SUCCESS',
  DELETE_PAN_FAILURE: '@pan/DELETE_PAN_FAILURE',
};
