/* eslint-disable import/no-cycle */
import React from 'react';

import {Container} from './styles';
import TopSection from './TopSection';
import LeftSection from './LeftSection';

import {useSidebar} from '../../contexts/SidebarContext';

function LeftSidebar() {
  const {showSidebar} = useSidebar();

  return (
    <Container showSidebar={showSidebar}>
      <TopSection />
      <LeftSection />
    </Container>
  );
}

export default LeftSidebar;
