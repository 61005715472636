import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  oresGlobalConstraints: [],
};

const oreGlobalConstraint = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_OREGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_OREGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        oresGlobalConstraints: [...state.oresGlobalConstraints, action.payload.data],
      };

    case Types.CREATE_OREGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_OREGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_OREGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        oresGlobalConstraints: action.payload.data,
        loading: false,
        error: null,
      };

    case Types.GET_OREGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_OREGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_OREGLOBALCONSTRAINT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        oresGlobalConstraints: [
          ...state.oresGlobalConstraints.map((oree) =>
            oree.id === action.payload.id ? action.payload.data : oree
          ),
        ],
      };

    case Types.UPDATE_OREGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_OREGLOBALCONSTRAINT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_OREGLOBALCONSTRAINT_SUCCESS:
      return {
        loading: false,
        error: null,
        oresGlobalConstraints: [...state.oresGlobalConstraints.filter((oree) => oree.id !== action.payload.id)],
      };

    case Types.DELETE_OREGLOBALCONSTRAINT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default oreGlobalConstraint;
