import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as WasteGlobalConstraintActions} from '../../ducks/waste/globalConstraints/actions';

function* createWasteGlobalConstraint({payload}) {
  const {waste_min_amount, waste_max_amount} = payload.data;

  try {
    const response = yield call(api.post, '/wastes/globalconstraints', {
      waste_min_amount,
      waste_max_amount,
    });

    yield put(WasteGlobalConstraintActions.createWasteSuccess(response.data));
  } catch (error) {
    yield put(WasteGlobalConstraintActions.createWasteFailure(error.message));
  }
}

function* getWasteGlobalConstraint() {
  try {
    const response = yield call(api.get, '/wastes/globalconstraints');
    yield put(WasteGlobalConstraintActions.getWasteSuccess(response.data));
  } catch (error) {
    yield put(WasteGlobalConstraintActions.getWasteFailure(error.message));
  }
}

function* updateWasteGlobalConstraint({payload}) {
  try {
    const {id, waste_min_amount, waste_max_amount} = payload.data;

    const waste = {
      waste_min_amount,
      waste_max_amount,
    };

    const {data} = yield call(
      api.patch,
      `/wastes/globalconstraints/${id}`,
      waste
    );

    const wasteUpdated = {
      id: data.id,
      waste_min_amount: data.waste_min_amount,
      waste_max_amount: data.waste_max_amount,
    };

    yield put(
      WasteGlobalConstraintActions.updateWasteSuccess(id, wasteUpdated)
    );
  } catch (error) {
    yield put(WasteGlobalConstraintActions.updateWasteFailure(error.message));
  }
}

function* deleteWasteGlobalConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/wastes/globalconstraints/${id}`);

    yield put(WasteGlobalConstraintActions.deleteWasteSuccess(id));
  } catch (error) {
    yield put(WasteGlobalConstraintActions.deleteWasteFailure(error.message));
  }
}

export {
  createWasteGlobalConstraint,
  getWasteGlobalConstraint,
  updateWasteGlobalConstraint,
  deleteWasteGlobalConstraint,
};
