// Unidades de medida em 'rem' ( Exemplo: 1rem = 9.6px )
export default {
  space: {
    smaller: 0.5,
    small: 1,
    medium: 1.5,
    big: 2,
    bigger: 2.5,
  },
  font: {
    smaller: 1.2,
    small: 1.6,
    medium: 2.2,
    big: 2.8,
    bigger: 3.2,
  },
};
