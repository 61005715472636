/* eslint-disable no-underscore-dangle */
import styled from 'styled-components';
import {colors} from '../../../../assets/styles';
import {MIN_SIZE_DESKTOP_2} from '../../../../constants/BREAKPOINTS';

export const DeviationContainer = styled.div`
  grid-area: card;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 25px 0px 5px 10px;
`;

export const DeviationContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .deviation-content {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const DeviationHeader = styled.div`
  width: 100%;
  max-width: 920px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.tertiary};

  strong {
    color: ${colors.primary};
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.05rem 3.5rem;
    font-size: 14px;
  }

  strong:first-child {
    background: ${colors.primary};
    width: fit-content;
    min-width: 155px;
    padding: 0.4rem;
    font-size: 11px;
    text-align: start;
    color: ${colors.white._100};
  }

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    min-width: 1300px;
  }
`;

export const DeviationBody = styled.div`
  width: 100%;
  max-width: 920px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white._100};

  span {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    padding: 0.3rem 2.3rem;
    font-size: 1.1rem;
    font-weight: 500;
    opacity: 0.8;
    text-align: start;
  }

  .title-body {
    width: 100%;
    min-width: 120px;
    font-size: 0.9rem;
    margin-left: 20px;
    min-width: 150px;
    text-align: start;
    color: ${colors.primary};
  }

  @media (max-width: ${MIN_SIZE_DESKTOP_2}px) {
    min-width: 1300px;
  }
`;
