import {all, takeLatest} from 'redux-saga/effects';

import {Types as AuthTypes} from '../ducks/auth/types';
import {Types as UserTypes} from '../ducks/user/types';
import {Types as SiloTypes} from '../ducks/silo/types';
import {Types as PanTypes} from '../ducks/pan/types';
import {Types as OreTypes} from '../ducks/ore/register/types';
import {Types as OreConstraintTypes} from '../ducks/ore/constraints/types';
import {Types as OreGlobalConstraintTypes} from '../ducks/ore/globalConstraints/types';
import {Types as WasteTypes} from '../ducks/waste/register/types';
import {Types as WasteConstraintTypes} from '../ducks/waste/constraints/types';
import {Types as WasteGlobalConstraintTypes} from '../ducks/waste/globalConstraints/types';
import {Types as FondantTypes} from '../ducks/fondant/register/types';
import {Types as FondantConstraintTypes} from '../ducks/fondant/constraints/types';
import {Types as ResidueTypes} from '../ducks/residue/register/types';
import {Types as ResidueConstraintTypes} from '../ducks/residue/constraints/types';
import {Types as ResidueGlobalConstraintTypes} from '../ducks/residue/globalConstraints/types';
import {Types as ProductTypes} from '../ducks/product/types';
import {Types as LeitoTypes} from '../ducks/leito/types';

import {signIn, signUp, signOut, setToken} from './auth/auth';
import {updateProfile, deleteAccount} from './user/user';
import {createSilo, updateSilo, deleteSilo} from './silo/silo';
import {createPan, updatePan, deletePan} from './pan/pan';
import {createOre, getOre, updateOre, deleteOre} from './ore/oreRegister';

import {
  createOreConstraint,
  getOreConstraint,
  updateOreConstraint,
  deleteOreConstraint,
} from './ore/oreConstraint';

import {
  createOreGlobalConstraint,
  getOreGlobalConstraint,
  updateOreGlobalConstraint,
  deleteOreGlobalConstraint,
} from './ore/oreGlobalConstraint';

import {
  createWaste,
  getWaste,
  updateWaste,
  deleteWaste,
} from './waste/wasteRegister';

import {
  createWasteConstraint,
  getWasteConstraint,
  updateWasteConstraint,
  deleteWasteConstraint,
} from './waste/wasteConstraint';

import {
  createWasteGlobalConstraint,
  getWasteGlobalConstraint,
  updateWasteGlobalConstraint,
  deleteWasteGlobalConstraint,
} from './waste/wasteGlobalConstraint';

import {
  createFondant,
  getFondant,
  updateFondant,
  deleteFondant,
} from './fondant/fondantRegister';

import {
  createFondantConstraint,
  getFondantConstraint,
  updateFondantConstraint,
  deleteFondantConstraint,
} from './fondant/fondantConstraint';

import {
  createResidue,
  getResidue,
  updateResidue,
  deleteResidue,
} from './residue/residueRegister';

import {
  createResidueConstraint,
  getResidueConstraint,
  updateResidueConstraint,
  deleteResidueConstraint,
} from './residue/residueConstraint';

import {
  createResidueGlobalConstraint,
  getResidueGlobalConstraint,
  updateResidueGlobalConstraint,
  deleteResidueGlobalConstraint,
} from './residue/residueGlobalConstraint';

import {
  createProduct,
  getProduct,
  updateProduct,
  deleteProduct,
} from './product/product';

import {createLeito, getLeito, updateLeito, deleteLeito} from './leito/leito';

export default function* rootSaga() {
  return yield all([
    takeLatest('persist/REHYDRATE', setToken),

    takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
    takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp),
    takeLatest(AuthTypes.SIGN_OUT, signOut),

    takeLatest(UserTypes.UPDATE_PROFILE_REQUEST, updateProfile),
    takeLatest(UserTypes.DELETE_ACCOUNT_REQUEST, deleteAccount),

    takeLatest(SiloTypes.CREATE_SILO_REQUEST, createSilo),
    takeLatest(SiloTypes.UPDATE_SILO_REQUEST, updateSilo),
    takeLatest(SiloTypes.DELETE_SILO_REQUEST, deleteSilo),

    takeLatest(PanTypes.CREATE_PAN_REQUEST, createPan),
    takeLatest(PanTypes.UPDATE_PAN_REQUEST, updatePan),
    takeLatest(PanTypes.DELETE_PAN_REQUEST, deletePan),

    takeLatest(OreTypes.CREATE_ORE_REQUEST, createOre),
    takeLatest(OreTypes.GET_ORE_REQUEST, getOre),
    takeLatest(OreTypes.UPDATE_ORE_REQUEST, updateOre),
    takeLatest(OreTypes.DELETE_ORE_REQUEST, deleteOre),

    takeLatest(
      OreConstraintTypes.CREATE_ORECONSTRAINT_REQUEST,
      createOreConstraint
    ),
    takeLatest(OreConstraintTypes.GET_ORECONSTRAINT_REQUEST, getOreConstraint),
    takeLatest(
      OreConstraintTypes.UPDATE_ORECONSTRAINT_REQUEST,
      updateOreConstraint
    ),
    takeLatest(
      OreConstraintTypes.DELETE_ORECONSTRAINT_REQUEST,
      deleteOreConstraint
    ),

    takeLatest(
      OreGlobalConstraintTypes.CREATE_OREGLOBALCONSTRAINT_REQUEST,
      createOreGlobalConstraint
    ),
    takeLatest(
      OreGlobalConstraintTypes.GET_OREGLOBALCONSTRAINT_REQUEST,
      getOreGlobalConstraint
    ),
    takeLatest(
      OreGlobalConstraintTypes.UPDATE_OREGLOBALCONSTRAINT_REQUEST,
      updateOreGlobalConstraint
    ),
    takeLatest(
      OreGlobalConstraintTypes.DELETE_OREGLOBALCONSTRAINT_REQUEST,
      deleteOreGlobalConstraint
    ),

    takeLatest(WasteTypes.CREATE_WASTE_REQUEST, createWaste),
    takeLatest(WasteTypes.GET_WASTE_REQUEST, getWaste),
    takeLatest(WasteTypes.UPDATE_WASTE_REQUEST, updateWaste),
    takeLatest(WasteTypes.DELETE_WASTE_REQUEST, deleteWaste),

    takeLatest(
      WasteConstraintTypes.CREATE_WASTECONSTRAINT_REQUEST,
      createWasteConstraint
    ),
    takeLatest(
      WasteConstraintTypes.GET_WASTECONSTRAINT_REQUEST,
      getWasteConstraint
    ),
    takeLatest(
      WasteConstraintTypes.UPDATE_WASTECONSTRAINT_REQUEST,
      updateWasteConstraint
    ),
    takeLatest(
      WasteConstraintTypes.DELETE_WASTECONSTRAINT_REQUEST,
      deleteWasteConstraint
    ),

    takeLatest(
      WasteGlobalConstraintTypes.CREATE_WASTEGLOBALCONSTRAINT_REQUEST,
      createWasteGlobalConstraint
    ),
    takeLatest(
      WasteGlobalConstraintTypes.GET_WASTEGLOBALCONSTRAINT_REQUEST,
      getWasteGlobalConstraint
    ),
    takeLatest(
      WasteGlobalConstraintTypes.UPDATE_WASTEGLOBALCONSTRAINT_REQUEST,
      updateWasteGlobalConstraint
    ),
    takeLatest(
      WasteGlobalConstraintTypes.DELETE_WASTEGLOBALCONSTRAINT_REQUEST,
      deleteWasteGlobalConstraint
    ),

    takeLatest(FondantTypes.CREATE_FONDANT_REQUEST, createFondant),
    takeLatest(FondantTypes.GET_FONDANT_REQUEST, getFondant),
    takeLatest(FondantTypes.UPDATE_FONDANT_REQUEST, updateFondant),
    takeLatest(FondantTypes.DELETE_FONDANT_REQUEST, deleteFondant),

    takeLatest(
      FondantConstraintTypes.CREATE_FONDANTCONSTRAINT_REQUEST,
      createFondantConstraint
    ),
    takeLatest(
      FondantConstraintTypes.GET_FONDANTCONSTRAINT_REQUEST,
      getFondantConstraint
    ),
    takeLatest(
      FondantConstraintTypes.UPDATE_FONDANTCONSTRAINT_REQUEST,
      updateFondantConstraint
    ),
    takeLatest(
      FondantConstraintTypes.DELETE_FONDANTCONSTRAINT_REQUEST,
      deleteFondantConstraint
    ),

    takeLatest(ResidueTypes.CREATE_RESIDUE_REQUEST, createResidue),
    takeLatest(ResidueTypes.GET_RESIDUE_REQUEST, getResidue),
    takeLatest(ResidueTypes.UPDATE_RESIDUE_REQUEST, updateResidue),
    takeLatest(ResidueTypes.DELETE_RESIDUE_REQUEST, deleteResidue),

    takeLatest(
      ResidueConstraintTypes.CREATE_RESIDUECONSTRAINT_REQUEST,
      createResidueConstraint
    ),
    takeLatest(
      ResidueConstraintTypes.GET_RESIDUECONSTRAINT_REQUEST,
      getResidueConstraint
    ),
    takeLatest(
      ResidueConstraintTypes.UPDATE_RESIDUECONSTRAINT_REQUEST,
      updateResidueConstraint
    ),
    takeLatest(
      ResidueConstraintTypes.DELETE_RESIDUECONSTRAINT_REQUEST,
      deleteResidueConstraint
    ),

    takeLatest(
      ResidueGlobalConstraintTypes.CREATE_RESIDUEGLOBALCONSTRAINT_REQUEST,
      createResidueGlobalConstraint
    ),
    takeLatest(
      ResidueGlobalConstraintTypes.GET_RESIDUEGLOBALCONSTRAINT_REQUEST,
      getResidueGlobalConstraint
    ),
    takeLatest(
      ResidueGlobalConstraintTypes.UPDATE_RESIDUEGLOBALCONSTRAINT_REQUEST,
      updateResidueGlobalConstraint
    ),
    takeLatest(
      ResidueGlobalConstraintTypes.DELETE_RESIDUEGLOBALCONSTRAINT_REQUEST,
      deleteResidueGlobalConstraint
    ),

    takeLatest(ProductTypes.CREATE_PRODUCT_REQUEST, createProduct),
    takeLatest(ProductTypes.GET_PRODUCT_REQUEST, getProduct),
    takeLatest(ProductTypes.UPDATE_PRODUCT_REQUEST, updateProduct),
    takeLatest(ProductTypes.DELETE_PRODUCT_REQUEST, deleteProduct),

    takeLatest(LeitoTypes.CREATE_LEITO_REQUEST, createLeito),
    takeLatest(LeitoTypes.GET_LEITO_REQUEST, getLeito),
    takeLatest(LeitoTypes.UPDATE_LEITO_REQUEST, updateLeito),
    takeLatest(LeitoTypes.DELETE_LEITO_REQUEST, deleteLeito),
  ]);
}
