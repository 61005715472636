import styled from "styled-components";
import colors from "../../assets/styles/colors";
 
export const SelectContainer = styled.div``;

export const Select = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;

  background: #f1f1f1;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  padding: 0.3rem;

  max-width: 100%;
  width: 10rem;
  height: 2.5rem;
  
  @media(max-width: 900px) {
    padding: 0.2rem;
    height: 1.6rem;
    max-width: 120px;
  }
`;