export const Types = {
  CREATE_WASTECONSTRAINT_REQUEST: '@waste/CREATE_WASTECONSTRAINT_REQUEST',
  CREATE_WASTECONSTRAINT_SUCCESS: '@waste/CREATE_WASTECONSTRAINT_SUCCESS',
  CREATE_WASTECONSTRAINT_FAILURE: '@waste/CREATE_WASTECONSTRAINT_FAILURE',
  GET_WASTECONSTRAINT_REQUEST: '@waste/GET_WASTECONSTRAINT_REQUEST',
  GET_WASTECONSTRAINT_SUCCESS: '@waste/GET_WASTECONSTRAINT_SUCCESS',
  GET_WASTECONSTRAINT_FAILURE: '@waste/GET_WASTECONSTRAINT_FAILURE',
  UPDATE_WASTECONSTRAINT_REQUEST: '@waste/UPDATE_WASTECONSTRAINT_REQUEST',
  UPDATE_WASTECONSTRAINT_SUCCESS: '@waste/UPDATE_WASTECONSTRAINT_SUCCESS',
  UPDATE_WASTECONSTRAINT_FAILURE: '@waste/UPDATE_WASTECONSTRAINT_FAILURE',
  DELETE_WASTECONSTRAINT_REQUEST: '@waste/DELETE_WASTECONSTRAINT_REQUEST',
  DELETE_WASTECONSTRAINT_SUCCESS: '@waste/DELETE_WASTECONSTRAINT_SUCCESS',
  DELETE_WASTECONSTRAINT_FAILURE: '@waste/DELETE_WASTECONSTRAINT_FAILURE',
};
