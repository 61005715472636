import {Types} from './types';
import {Types as AuthTypes} from '../auth/types';

const initialProfile = {
  id: 'null',
  name: 'null',
  email: 'null',
  occupation: 'null',
  class_type: 'null',
};

const INITIAL_STATE = {
  profile: initialProfile,
  loading: false,
  error: null,
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        profile: action.payload.user,
        loading: false,
        error: null,
      };

    case AuthTypes.SIGN_OUT:
      return {
        profile: initialProfile,
        loading: false,
        error: null,
      };

    case Types.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_PROFILE_SUCCESS:
      if (state.profile.id === action.payload.profile.id) {
        return {
          profile: action.payload.profile,
          loading: false,
          error: null,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
      };

    case Types.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_ACCOUNT_SUCCESS:
      return {
        loading: false,
        error: null,
      };

    case Types.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default user;
