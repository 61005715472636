import React from 'react';

import {Container} from './styles';

const CalendarContainer = ({className, children}) => {
  return (
    <Container className={className}>
      <div style={{position: 'relative'}}>{children}</div>
    </Container>
  );
};

export default CalendarContainer;
