export const Types = {
  CREATE_SILO_REQUEST: '@silo/CREATE_SILO_REQUEST',
  CREATE_SILO_SUCCESS: '@silo/CREATE_SILO_SUCCESS',
  CREATE_SILO_FAILURE: '@silo/CREATE_SILO_FAILURE',
  UPDATE_SILO_REQUEST: '@silo/UPDATE_SILO_REQUEST',
  UPDATE_SILO_SUCCESS: '@silo/UPDATE_SILO_SUCCESS',
  UPDATE_SILO_FAILURE: '@silo/UPDATE_SILO_FAILURE',
  DELETE_SILO_REQUEST: '@silo/DELETE_SILO_REQUEST',
  DELETE_SILO_SUCCESS: '@silo/DELETE_SILO_SUCCESS',
  DELETE_SILO_FAILURE: '@silo/DELETE_SILO_FAILURE',
};
