export const {format: formatDecimal} = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

export const {format: formatPrice} = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const currencyToNumber = (value = '') =>
  Number(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));

export const formatAmount = (value, decimals, termOne, termTwo) =>
  Number(value).toFixed(decimals).toString().replace(termOne, termTwo);

export const formatPercentage = (value, decimals, termOne, termTwo) =>
  Number(value).toFixed(decimals).toString().replace(termOne, termTwo);
