export const Types = {
  CREATE_ORE_REQUEST: '@ore/CREATE_ORE_REQUEST',
  CREATE_ORE_SUCCESS: '@ore/CREATE_ORE_SUCCESS',
  CREATE_ORE_FAILURE: '@ore/CREATE_ORE_FAILURE',
  GET_ORE_REQUEST: '@ore/GET_ORE_REQUEST',
  GET_ORE_SUCCESS: '@ore/GET_ORE_SUCCESS',
  GET_ORE_FAILURE: '@ore/GET_ORE_FAILURE',
  UPDATE_ORE_REQUEST: '@ore/UPDATE_ORE_REQUEST',
  UPDATE_ORE_SUCCESS: '@ore/UPDATE_ORE_SUCCESS',
  UPDATE_ORE_FAILURE: '@ore/UPDATE_ORE_FAILURE',
  DELETE_ORE_REQUEST: '@ore/DELETE_ORE_REQUEST',
  DELETE_ORE_SUCCESS: '@ore/DELETE_ORE_SUCCESS',
  DELETE_ORE_FAILURE: '@ore/DELETE_ORE_FAILURE',
};
