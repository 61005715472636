/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef, useCallback} from 'react';

import {Form} from '@unform/web';
import * as Yup from 'yup';

import {useDispatch, useSelector} from 'react-redux';

import {MdEdit, MdCheck, MdClose} from 'react-icons/md';

import Input from '../../../../components/Input';
import SimpleSelect from '../../../../components/Select';

import {colors} from '../../../../assets/styles';

import getValidationErrors from '../../../../utils/getValidationErrors';

import {Actions as OreActions} from '../../../../store/ducks/ore/globalConstraints/actions';

import {formatPercentage} from '../../../../utils/format';

import {useToast} from '../../../../contexts/ToastContext';

import {
  WrapperConstraint,
  ConstraintContainer,
  ConstraintContent,
  ConstraintLabel,
  ConstraintValue,
  ContainerEditForm,
  ButtonEditForm,
} from '../../../../assets/styles/CommonStyled';

import {ContainerInput} from './styles';

const OreGlobalConstraint = ({open = false, onClose}) => {
  const dispatch = useDispatch();
  const {oresGlobalConstraints} = useSelector(
    (state) => state.oreGlobalConstraint
  );
  const {addToast} = useToast();
  const formRefEdit = useRef(null);

  const initialValues = {
    ore_amount_goal: oresGlobalConstraints?.[0]?.ore_amount_goal || 1700,
    ore_number_in_silo: oresGlobalConstraints?.[0]?.ore_number_in_silo || 6,
    choose_just_one: oresGlobalConstraints?.[0]?.choose_just_one || 'SIM',
    ore_percentual_min: oresGlobalConstraints?.[0]?.ore_percentual_min || 5,
  };

  const [values, setValues] = useState(initialValues);

  const [loading, setLoading] = useState(false);

  const getOres = () => {
    setLoading(true);

    try {
      dispatch(OreActions.getOreRequest());
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOres();
  }, []);

  useEffect(() => {
    if (oresGlobalConstraints?.length !== 0) {
      setValues({
        ore_amount_goal: oresGlobalConstraints[0].ore_amount_goal,
        ore_number_in_silo: oresGlobalConstraints[0].ore_number_in_silo,
        choose_just_one: oresGlobalConstraints[0].choose_just_one,
        ore_percentual_min: oresGlobalConstraints[0].ore_percentual_min,
      });
    }
  }, [oresGlobalConstraints]);

  const schemaYup = Yup.object().shape({
    ore_amount_goal: Yup.number().required(
      'Informe a quantidade total de minério a usar na mistura'
    ),
    ore_number_in_silo: Yup.number().required(
      'Informe o número de minérios a usar'
    ),
    ore_percentual_min: Yup.number()
      .required('Informe a porcentagem mínima a usar de cada minério')
      .min(0, 'Valor mínimo = 0')
      .max(100, 'Valor Máximo = 100'),
  });

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);

      try {
        formRefEdit.current.setErrors({});

        await schemaYup.validate(data, {
          abortEarly: false,
        });

        const oreUpdated = {
          id: Number(oresGlobalConstraints?.[0]?.id || 1),
          ore_amount_goal: Number(data.ore_amount_goal),
          ore_number_in_silo: Number(data.ore_number_in_silo),
          choose_just_one: data.choose_just_one,
          ore_percentual_min: Number(data.ore_percentual_min),
        };

        dispatch(OreActions.updateOreRequest(oreUpdated));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });

        onCloseForms();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setLoading(false);
    },
    [addToast, dispatch]
  );

  const onCloseForms = () => {
    onClose(false);
    setValues(initialValues);
  };

  return (
    <Form initialData={values} ref={formRefEdit} onSubmit={handleEditForm}>
      <WrapperConstraint>
        {!open ? (
          <ContainerEditForm>
            <MdEdit
              size={16}
              color={colors.primary}
              onClick={() => onClose(true)}
            />
          </ContainerEditForm>
        ) : (
          <ButtonEditForm type="submit">
            <MdCheck size={16} color={colors.primary} />
          </ButtonEditForm>
        )}

        <ConstraintContainer>
          <ConstraintContent>
            <ConstraintLabel>
              Quantidade total de minério a usar na mistura (kg)
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>{`${values.ore_amount_goal}`}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="ore_amount_goal"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.ore_amount_goal || 0}
                  onChange={(e) =>
                    setValues({...values, ore_amount_goal: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>Número de mínerios a usar</ConstraintLabel>
            {!open ? (
              <ConstraintValue>{values.ore_number_in_silo}</ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="ore_number_in_silo"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.ore_number_in_silo || 0}
                  onChange={(e) =>
                    setValues({...values, ore_number_in_silo: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>
              Escolher apenas um lote de cada minério?
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>{values.choose_just_one}</ConstraintValue>
            ) : (
              <ContainerInput>
                <SimpleSelect
                  name="choose_just_one"
                  value={values?.choose_just_one || 'SIM'}
                  options={[
                    {value: 'SIM', label: 'SIM'},
                    {value: 'NÃO', label: 'NÃO'},
                  ]}
                  onChange={(e) =>
                    setValues({
                      ...values,
                      choose_just_one: e.target.value,
                    })
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>

          <ConstraintContent>
            <ConstraintLabel>
              Porcentagem mínima a usar de cada minério (%)
            </ConstraintLabel>
            {!open ? (
              <ConstraintValue>
                {`${formatPercentage(
                  values.ore_percentual_min,
                  2,
                  '.',
                  ','
                )}% -> equivalente a ${
                  (values.ore_percentual_min / 100) * values.ore_amount_goal
                } kg`}
              </ConstraintValue>
            ) : (
              <ContainerInput>
                <Input
                  name="ore_percentual_min"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  placeholder="0"
                  value={values?.ore_percentual_min || 0}
                  onChange={(e) =>
                    setValues({...values, ore_percentual_min: e.target.value})
                  }
                />
              </ContainerInput>
            )}
          </ConstraintContent>
        </ConstraintContainer>

        {open && (
          <ContainerEditForm>
            <MdClose
              size={16}
              color={colors.primary}
              onClick={() => onCloseForms()}
            />
          </ContainerEditForm>
        )}
      </WrapperConstraint>
    </Form>
  );
};

export default OreGlobalConstraint;
