import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {store} from '../store';
import {checkIfTokenExpired} from '../utils/checkIfTokenExpired';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const {signed, token} = store.getState().auth;
  const tokenExpired = checkIfTokenExpired(token);

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (signed && !isPrivate && !tokenExpired) {
    return <Redirect to="/" />;
  }

  return <Route {...rest} component={Component} />;
}

RouteWrapper.defaultProps = {
  isPrivate: false,
};
