/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState, useRef} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from '@unform/web';

import {MdArrowForward} from 'react-icons/md';

import api from '../../../../services/api';

import {
  Container,
  ContainerTitlePage,
  TitlePage,
  HorizontalArrow,
  ButtonWithLoading,
  MenuBottomContainer,
  BoxContainer,
} from '../../../../assets/styles/CommonStyled';

import {
  Content,
  TableLeito,
  LeitoHeader,
  LeitoHeaderLabel,
  LeitoBody,
  MaterialContainer,
  MaterialName,
  MaterialValue,
  Wrapper,
  CardContainer,
  CardContent,
  DeviationContainer,
  SelectContainer,
  SelectContent,
  ButtonContainer,
} from './styles';

import Menu from '../../../../components/Menu';
import Tag from '../../../../components/Tag';

import {Actions as LeitoActions} from '../../../../store/ducks/leito/actions';
import {Actions as ProductActions} from '../../../../store/ducks/product/actions';

import useKeys from '../../../../hooks/useKeys';
import {formatAmount, formatPrice} from '../../../../utils/format';
import SimpleSelect from '../../../../components/Select';

import animationLoadingButton from '../../../../assets/lotties/loading_button.json';
import LottieAnimation from '../../../../components/Animation/LottieAnimation';
import {useToast} from '../../../../contexts/ToastContext';
import EspecificationsAndDeviations from '../EspecificationsAndDeviations';

const DesktopLayout = () => {
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {token} = useSelector((state) => state.auth);
  const {leitos} = useSelector((state) => state.leito);
  const {products} = useSelector((state) => state.product);
  const [product, setProduct] = useState(products?.[0] || {});
  const rightArrow = useKeys('ArrowRight');
  const history = useHistory();
  const formRef = useRef(null);
  const {addToast} = useToast();
  const [loading, setLoading] = useState(false);
  const initialValuesInput = {
    gusa_type: 'ACIÁRIA',
  };

  const [values, setValues] = useState(initialValuesInput);

  const getProducts = () => {
    try {
      dispatch(ProductActions.getProductRequest());
    } catch (err) {
      // TO DO
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getLeito = () => {
    try {
      dispatch(LeitoActions.getLeitoRequest());
    } catch (err) {
      // TO DO
    }
  };

  useEffect(() => {
    getLeito();
  }, []);

  useEffect(() => {
    if (rightArrow) {
      history.push('/minerios/cadastro');
    }
  }, [rightArrow]);

  const solver = async () => {
    if ('id' in product) {
      await api.get(`/solver/?token=${token}&productid=${product.id}`);
      getLeito();
    }
  };

  const hasPermission = () => {
    return profile?.occupation?.trim()?.toLowerCase() === 'superintendente';
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await solver();

      setTimeout(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: `O leito foi atualizado`,
        });
      }, 500);

      setLoading(true);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const findProduct = products.find(
      (item) => `${item.name}`.toUpperCase() === `${values.gusa_type}`
    );

    if (findProduct) {
      setProduct(findProduct);
    }
  }, [values.gusa_type]);

  return (
    <>
      <Container>
        <Form initialData={values} ref={formRef} onSubmit={handleSubmit}>
          <Menu />
          <HorizontalArrow bottomSpace="0" anchor="right">
            <Link to="/minerios/cadastro">
              <div className="icon">
                <MdArrowForward />
              </div>
            </Link>
          </HorizontalArrow>

          <BoxContainer>
            <ContainerTitlePage className="container_title_page">
              <TitlePage className="title_page">Leito</TitlePage>
            </ContainerTitlePage>

            <Content>
              <TableLeito>
                <LeitoHeader>
                  <LeitoHeaderLabel>Material</LeitoHeaderLabel>
                  <LeitoHeaderLabel>Lote</LeitoHeaderLabel>
                  <LeitoHeaderLabel>kg</LeitoHeaderLabel>
                </LeitoHeader>

                <LeitoBody>
                  {leitos?.[0]?.mixture?.map((item, index) => (
                    <MaterialContainer
                      key={item?.lote ? item?.lote * index : index}>
                      <MaterialName>{item?.name || ''}</MaterialName>
                      <MaterialValue>
                        {item?.lote !== -1 ? item?.lote : ''}
                      </MaterialValue>
                      <MaterialValue>
                        {formatAmount(item?.amount || 0)}
                      </MaterialValue>
                    </MaterialContainer>
                  ))}
                </LeitoBody>
                <ButtonContainer>
                  <ButtonWithLoading type="submit" isLoading={loading}>
                    {loading ? (
                      <LottieAnimation
                        width={40}
                        height={40}
                        animation={animationLoadingButton}
                      />
                    ) : (
                      'Atualizar leito'
                    )}
                  </ButtonWithLoading>
                </ButtonContainer>
              </TableLeito>

              <SelectContainer>
                <SelectContent>
                  <label htmlFor="gusa_type">Tipo de gusa:</label>

                  <SimpleSelect
                    name="gusa_type"
                    options={products.map((item) => {
                      return {
                        value: item.id,
                        label: `${item.name}`.toUpperCase(),
                      };
                    })}
                    onChange={(e) =>
                      setValues({
                        ...values,
                        gusa_type: e.target.value,
                      })
                    }
                  />
                </SelectContent>

                <Wrapper>
                  <CardContainer>
                    <CardContent customColor="#6B8C6A">
                      <Tag
                        title="Gusa"
                        customColor="#6B8C6A"
                        enableCustomBorder
                      />
                      <Tag
                        customColor="#6B8C6A"
                        description="Meta de produção"
                        value={`${formatAmount(
                          product?.gusa_goal || 0,
                          2,
                          '.',
                          ','
                        )} kg`}
                      />
                      <Tag
                        customColor="#6B8C6A"
                        description="Quantidade produzida"
                        value={`${formatAmount(
                          leitos?.[0]?.gusa_amount || 0,
                          2,
                          '.',
                          ','
                        )} kg`}
                      />
                      <Tag
                        customColor="#6B8C6A"
                        description="Desvio da meta"
                        value={`${formatAmount(
                          leitos?.[0]?.gusa_deviated || 0,
                          2,
                          '.',
                          ','
                        )} kg`}
                        valueColor={
                          Number(
                            formatAmount(
                              leitos?.[0]?.gusa_amount || 0,
                              2,
                              '.',
                              ','
                            )
                          ) >=
                          Number(
                            formatAmount(
                              leitos?.[0]?.gusa_goal || 0,
                              2,
                              '.',
                              ','
                            )
                          )
                            ? '#3F7636'
                            : '#DA5C5C'
                        }
                      />
                      {hasPermission() && (
                        <Tag
                          customColor="#6B8C6A"
                          description="Custo total da produção"
                          value={`${formatPrice(
                            leitos?.[0]?.cost_total || 0,
                            2,
                            '.',
                            ','
                          )}`}
                        />
                      )}
                    </CardContent>

                    <CardContent customColor="#E1CE67">
                      <Tag
                        title="Escória"
                        customColor="#E1CE67"
                        enableCustomBorder
                      />
                      <Tag
                        customColor="#E1CE67"
                        description="Quantidade total gerada"
                        value={`${formatAmount(
                          leitos?.[0]?.slag_amount || 0,
                          2,
                          '.',
                          ','
                        )} kg`}
                      />
                    </CardContent>

                    <CardContent customColor="#7DA0FA">
                      <Tag
                        title="Minérios"
                        customColor="#7DA0FA"
                        enableCustomBorder
                      />
                      <Tag
                        customColor="#7DA0FA"
                        description="Quantidade total usada"
                        value={`${formatAmount(
                          leitos?.[0]?.ores_amount || 0,
                          2,
                          '.',
                          ','
                        )} kg`}
                      />
                      {hasPermission() && (
                        <Tag
                          customColor="#7DA0FA"
                          description="Custo total dos minérios"
                          value={`${formatPrice(
                            leitos?.[0]?.cost_ore || 0,
                            2,
                            '.',
                            ','
                          )}`}
                        />
                      )}
                    </CardContent>
                  </CardContainer>

                  <DeviationContainer>
                    <EspecificationsAndDeviations
                      headerTitle="ESPECIFICAÇÕES (%)"
                      bodyTitle1="GUSA"
                      bodyTitle2="ESCÓRIA"
                      Fe_1={product?.composition_gusa?.Fe_original}
                      Si_1={product?.composition_gusa?.Si_original}
                      Al2O3_1={product?.composition_gusa?.Al2O3_original}
                      CaO_1={product?.composition_gusa?.CaO_original}
                      MgO_1={product?.composition_gusa?.MgO_original}
                      Mn_1={product?.composition_gusa?.Mn_original}
                      P_1={product?.composition_gusa?.P_original}
                      Fe_2={product?.composition_slag?.Fe_original}
                      Si_2={product?.composition_slag?.Si_original}
                      Al2O3_2={product?.composition_slag?.Al2O3_original}
                      CaO_2={product?.composition_slag?.CaO_original}
                      MgO_2={product?.composition_slag?.MgO_original}
                      Mn_2={product?.composition_slag?.Mn_original}
                      P_2={product?.composition_slag?.P_original}
                    />
                    <EspecificationsAndDeviations
                      headerTitle="DESVIOS META GUSA (%)"
                      disableSubtitles
                      bodyTitle1="NEGATIVO"
                      bodyTitle2="POSITIVO"
                      Fe_1={leitos?.[0]?.negative_deviation_goal?.Fe}
                      Si_1={leitos?.[0]?.negative_deviation_goal?.Si}
                      Al2O3_1={leitos?.[0]?.negative_deviation_goal?.Al2O3}
                      CaO_1={leitos?.[0]?.negative_deviation_goal?.CaO}
                      MgO_1={leitos?.[0]?.negative_deviation_goal?.MgO}
                      Mn_1={leitos?.[0]?.negative_deviation_goal?.Mn}
                      P_1={leitos?.[0]?.negative_deviation_goal?.P}
                      Fe_2={leitos?.[0]?.positive_deviation_goal?.Fe}
                      Si_2={leitos?.[0]?.positive_deviation_goal?.Si}
                      Al2O3_2={leitos?.[0]?.positive_deviation_goal?.Al2O3}
                      CaO_2={leitos?.[0]?.positive_deviation_goal?.CaO}
                      MgO_2={leitos?.[0]?.positive_deviation_goal?.MgO}
                      Mn_2={leitos?.[0]?.positive_deviation_goal?.Mn}
                      P_2={leitos?.[0]?.positive_deviation_goal?.P}
                    />
                    <EspecificationsAndDeviations
                      headerTitle="MÉDIA MINÉRIOS (kg)"
                      disableSubtitles
                      bodyTitle1="MÉDIA"
                      Fe_1={leitos?.[0]?.ores_average?.Fe}
                      Si_1={leitos?.[0]?.ores_average?.Si}
                      Al2O3_1={leitos?.[0]?.ores_average?.Al2O3}
                      CaO_1={leitos?.[0]?.ores_average?.CaO}
                      MgO_1={leitos?.[0]?.ores_average?.MgO}
                      Mn_1={leitos?.[0]?.ores_average?.Mn}
                      P_1={leitos?.[0]?.ores_average?.P}
                    />
                  </DeviationContainer>
                </Wrapper>
              </SelectContainer>
            </Content>
          </BoxContainer>
        </Form>
        <MenuBottomContainer />
      </Container>
    </>
  );
};

export default DesktopLayout;
