import {Types} from './types';

export const Actions = {
  createFondantRequest: (data) => ({
    type: Types.CREATE_FONDANT_REQUEST,
    payload: {data},
  }),
  createFondantSuccess: (data) => ({
    type: Types.CREATE_FONDANT_SUCCESS,
    payload: {data},
  }),
  createFondantFailure: (error) => ({
    type: Types.CREATE_FONDANT_FAILURE,
    payload: {error},
  }),
  getFondantRequest: () => ({
    type: Types.GET_FONDANT_REQUEST,
  }),
  getFondantSuccess: (data) => ({
    type: Types.GET_FONDANT_SUCCESS,
    payload: {data},
  }),
  getFondantFailure: (error) => ({
    type: Types.UPDATE_FONDANT_FAILURE,
    payload: {error},
  }),
  updateFondantRequest: (data) => ({
    type: Types.UPDATE_FONDANT_REQUEST,
    payload: {data},
  }),
  updateFondantSuccess: (id, data) => ({
    type: Types.UPDATE_FONDANT_SUCCESS,
    payload: {id, data},
  }),
  updateFondantFailure: (error) => ({
    type: Types.UPDATE_FONDANT_FAILURE,
    payload: {error},
  }),
  deleteFondantRequest: (id) => ({
    type: Types.DELETE_FONDANT_REQUEST,
    payload: {id},
  }),
  deleteFondantSuccess: (id) => ({
    type: Types.DELETE_FONDANT_SUCCESS,
    payload: {id},
  }),
  deleteFondantFailure: (error) => ({
    type: Types.DELETE_FONDANT_FAILURE,
    payload: {error},
  }),
};
