import styled from 'styled-components';
import {colors} from '../../assets/styles';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;

  width: 100%;
  max-width: 700px;

  form {
    margin: 0.8rem 0;
    width: 320px;
    text-align: center;
  }

  button {
    width: 100%;
    height: 3.8rem;
    background: ${colors.primary};
    color: ${colors.secondary};
    border-radius: 16px;
    border: 0;
    margin-top: 0.8rem;
    transition: 1s;

    &:hover {
      background: ${colors.tertiary};
    }
  }
`;

export const Background = styled.img`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 10rem;
  height: auto;
  opacity: 0.7;
  align-self: flex-end;
  margin-left: 3rem;
  @media (min-width: 700px) {
    width: 50rem;
    align-self: center;
  }
`;

export const HeaderForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  color: ${colors.secondary};
`;

export const ImageHeader = styled.img`
  width: 14rem;
  height: auto;
`;

export const ContainerInput = styled.div``;
