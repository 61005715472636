/* eslint-disable import/no-cycle */
import React from 'react';

import {FiMenu} from 'react-icons/fi';
import colors from '../../../assets/styles/colors';
import {MenuContainer} from './styles';

import {useSidebar} from '../../../contexts/SidebarContext';

function MenuButton({showSidebar}) {
  const {setShowSidebar} = useSidebar();

  return (
    <MenuContainer onClick={() => setShowSidebar(showSidebar)}>
      <FiMenu
        size={34}
        color={
          ['/'].includes(window.location.pathname) || !showSidebar
            ? colors.secondary
            : colors.primary
        }
      />
    </MenuContainer>
  );
}

export default MenuButton;
