import React from 'react';

import {Container, Copyright} from './styles';

function Footer() {
  return (
    <Container>
      <Copyright>
        © Copyright - {new Date().getFullYear()}. Todos os direitos reservados.
      </Copyright>
    </Container>
  );
}

export default Footer;
