export const Types = {
  CREATE_LEITO_REQUEST: '@leito/CREATE_LEITO_REQUEST',
  CREATE_LEITO_SUCCESS: '@leito/CREATE_LEITO_SUCCESS',
  CREATE_LEITO_FAILURE: '@leito/CREATE_LEITO_FAILURE',
  GET_LEITO_REQUEST: '@leito/GET_LEITO_REQUEST',
  GET_LEITO_SUCCESS: '@leito/GET_LEITO_SUCCESS',
  GET_LEITO_FAILURE: '@leito/GET_LEITO_FAILURE',
  UPDATE_LEITO_REQUEST: '@leito/UPDATE_LEITO_REQUEST',
  UPDATE_LEITO_SUCCESS: '@leito/UPDATE_LEITO_SUCCESS',
  UPDATE_LEITO_FAILURE: '@leito/UPDATE_LEITO_FAILURE',
  DELETE_LEITO_REQUEST: '@leito/DELETE_LEITO_REQUEST',
  DELETE_LEITO_SUCCESS: '@leito/DELETE_LEITO_SUCCESS',
  DELETE_LEITO_FAILURE: '@leito/DELETE_LEITO_FAILURE',
};
