/* eslint-disable import/no-cycle */
import React, {createContext, useState, useContext} from 'react';
import {useSelector} from 'react-redux';
import Header from '../../components/Header';

export const HeaderContext = createContext({});

const HeaderProvider = ({children}) => {
  const {signed} = useSelector((state) => state.auth);
  const [showHeader, setShowHeader] = useState(signed);
  return (
    <HeaderContext.Provider value={{showHeader, setShowHeader}}>
      {children}
      <Header />
    </HeaderContext.Provider>
  );
};

function useHeader() {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }

  return context;
}

export {HeaderProvider, useHeader};
