/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Form} from '@unform/web';
import * as Yup from 'yup';

import pt from 'date-fns/locale/pt';

import {
  MdEdit,
  MdDelete,
  MdVisibility,
  MdBlock,
  MdSearch,
} from 'react-icons/md';

import animationButtonAdd from '../../assets/lotties/button_add.json';
import animationLoadingButton from '../../assets/lotties/loading_button.json';
import animationLoadingPage from '../../assets/lotties/loading_page.json';

import {
  Container,
  ContainerTitlePage,
  ContainerButtonAdd,
  BackgroundOpacity,
  ButtonSecondary,
  ContentInput,
  DatePickerStyled,
  TitlePage,
  Table,
  TableHeader,
  TableBody,
  LineHorizontal,
} from '../../assets/styles/CommonStyled';
import {Content, ContentInRow} from './styles';
import {colors} from '../../assets/styles';

import CalendarContainer from '../../components/Container/CalendarContainer';
import Paginate from '../../components/Paginate';
import Input from '../../components/Input';
import SimpleSelect from '../../components/Select';
import LateralModal from '../../components/LateralModal';
import LottieAnimation from '../../components/Animation/LottieAnimation';

import getValidationErrors from '../../utils/getValidationErrors';
import {
  formatDateToShow,
  formatHourToShow,
  getDaysInMonth,
} from '../../utils/date';
import {formatDecimal} from '../../utils/format';

import {Actions as SiloActions} from '../../store/ducks/silo/actions';

import {useToast} from '../../contexts/ToastContext';
import {usePaginate} from '../../contexts/PaginateContext';

import api from '../../services/api';

registerLocale('pt', pt);

function SilosControl() {
  const formRefAdd = useRef(null);
  const formRefEdit = useRef(null);
  const dispatch = useDispatch();
  const {profile} = useSelector((state) => state.user);
  const {addToast, actionAllowed, setActionAllowed} = useToast();
  const [silos, setSilos] = useState([]);
  const [page, setPage] = useState(1);
  const MAX_ITEMS = 15;
  const [totalPage, setTotalPage] = useState(1);
  const [countRegister, setCountRegister] = useState(0);
  const [countCallApi, setCountCallApi] = useState(0);
  const [hasMoreRegister, setHasMoreRegister] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  // const [error, setError] = useState(false);
  const [showFormToAdd, setShowFormToAdd] = useState(false);
  const [showFormToEdit, setShowFormToEdit] = useState(false);
  const [siloToEdit, setSiloToEdit] = useState(null);
  const [event, setEvent] = useState({type: '', id: null});
  const {filters, setFilters} = usePaginate();

  const initialValuesInput = {
    date: new Date(),
    silo_number: 1,
    rpm_used: 0,
    measured_time: 0,
    mass_found: 1,
    mass_objective: 0,
    rpm_calc: 0,
  };
  const [values, setValues] = useState(initialValuesInput);

  const initialValuesFilters = {
    orderBy: 'Data',
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear().toString(),
  };

  useEffect(() => {
    setValues({
      ...values,
      rpm_calc:
        (((values.measured_time * values.mass_objective) / values.mass_found) *
          values.rpm_used) /
        60,
    });
  }, [
    values.rpm_used,
    values.measured_time,
    values.mass_found,
    values.mass_objective,
  ]);

  useEffect(() => {
    setFilters(initialValuesFilters);
    return () => {
      setFilters(initialValuesFilters);
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    const days_lastMonthOfYear = getDaysInMonth(filters.month, filters.year);

    setLoadingPage(true);
    setHasMoreRegister(true);

    try {
      api
        .get('/silos', {
          params: {
            _page: page,
            _limit: MAX_ITEMS,
            _interval_date:
              filters.month < 10
                ? `${filters.year}-0${filters.month}-01|${filters.year}-0${filters.month}-${days_lastMonthOfYear}`
                : `${filters.year}-${filters.month}-01|${filters.year}-${filters.month}-${days_lastMonthOfYear}`,
            _order:
              filters.orderBy === 'Data' ? `date|DESC` : `silo_number|ASC`,
          },
          signal: abortController.signal,
        })
        .then((response) => {
          if (isMounted) {
            setTotalPage(Math.ceil(response.data.count / MAX_ITEMS));
            setCountRegister(response.data.count);
            page <= totalPage || totalPage === 0
              ? setSilos([...silos, ...response.data.rows])
              : setHasMoreRegister(false);
          }
        });
    } catch (err) {
      // error
    }

    setTimeout(() => {
      setLoadingPage(false);
    }, 1000);

    return () => {
      abortController.abort();
      isMounted = false;
    };
  }, [page, countCallApi]);

  useEffect(() => {
    if (actionAllowed && event.type === 'remove') {
      dispatch(SiloActions.deleteSiloRequest(event.id));
      addToast({
        type: 'success',
        title: `Registro removido com sucesso`,
      });
      setEvent({type: '', id: null});
      setActionAllowed(false);
    }
  }, [actionAllowed]);

  function handleSearchSilos() {
    setSilos([]);
    setPage(1);
    setCountCallApi(countCallApi + 1);
  }

  function setFormToAdd(show) {
    setShowFormToAdd(show);
    setActionAllowed(false);
    setValues(initialValuesInput);
  }

  function setFormToEdit(show, silo) {
    const {
      date,
      silo_number,
      rpm_used,
      measured_time,
      mass_found,
      mass_objective,
      rpm_calc,
    } = silo;

    setValues({
      date: new Date(date),
      silo_number,
      rpm_used,
      measured_time,
      mass_found,
      mass_objective,
      rpm_calc,
    });
    setSiloToEdit(silo);
    setShowFormToEdit(show);
    setActionAllowed(false);
  }

  const handleAddForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefAdd.current.setErrors({});

        const schema = Yup.object().shape({
          rpm_used: Yup.string().required(
            'É necessário informar o RPM utilizado'
          ),
          measured_time: Yup.string().required(
            'É necessário informar o tempo medido'
          ),
          mass_found: Yup.string().required(
            'É necessário informar a massa encontrada'
          ),
          mass_objective: Yup.string().required(
            'É necessário informar a massa desejada'
          ),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const newSilo = {
          user_id: profile.id,
          date: values.date,
          ...data,
          rpm_calc: values.rpm_calc,
        };

        // console.tron.log(newSilo);
        // if (!actionAllowed) {
        //   await addToast({
        //     type: 'confirm',
        //     title: `Confirmação de permissão de acesso`,
        //     description: `${email} é uma pessoa colaboradora na empresa e permito que ela receba um código único de acesso ao sistema?`,
        //   });
        // }

        // if (actionAllowed) {
        dispatch(SiloActions.createSiloRequest(newSilo));

        // if (!error) {

        addToast({
          type: 'success',
          title: `Registro adicionado com sucesso`,
        });

        //   setSignUpSuccess(true);
        // } else {
        //   addToast({
        //     type: 'info',
        //     title: 'Não foi possível adicionar colaborador(a)',
        //     description:
        //       'Verifique se o e-mail informado ja está cadastrado no sistema.',
        //   });
        //   setSignUpSuccess(false);
        // }
        setFormToAdd(false);
        // }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdd.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao adicionar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToAdd]
  );

  const handleEditForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        formRefEdit.current.setErrors({});

        const schema = Yup.object().shape({
          rpm_used: Yup.string().required(
            'É necessário informar o RPM utilizado'
          ),
          measured_time: Yup.string().required(
            'É necessário informar o tempo medido'
          ),
          mass_found: Yup.string().required(
            'É necessário informar a massa encontrada'
          ),
          mass_objective: Yup.string().required(
            'É necessário informar a massa desejada'
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const silo = {
          id: siloToEdit.id,
          date: values.date,
          ...data,
          rpm_calc: values.rpm_calc,
        };

        dispatch(SiloActions.updateSiloRequest(silo));

        addToast({
          type: 'success',
          title: 'Registro atualizado com sucesso',
        });
        setShowFormToEdit(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefEdit.current.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro ao atualizar registro',
          description:
            'Verifique se todos os campos foram preenchidos corretamente.',
        });
      }
      setActionAllowed(false);
      setLoading(false);
    },
    [addToast, setActionAllowed, dispatch, setFormToEdit]
  );

  const handleRemoveSilo = useCallback(
    (id) => {
      setEvent({type: 'remove', id});
      try {
        if (!actionAllowed) {
          addToast({
            type: 'confirm',
            title: `Confirmação de exclusão`,
            description: `O registro será removido do banco de dados do sistema e esta ação não poderá ser revertida.`,
          });
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ocorreu um erro ao remover o registro',
          description: 'Tente novamente.',
        });
      }
    },
    [addToast]
  );

  return (
    <Container>
      <ContainerTitlePage className="container_title_page">
        <TitlePage className="title_page">Controle de carregamento</TitlePage>
        {profile.occupation !== 'Analista de Laboratório' && (
          <ContainerButtonAdd onClick={() => setFormToAdd(true)}>
            <LottieAnimation
              width={50}
              height={50}
              animation={animationButtonAdd}
            />
            <p>Adicionar</p>
          </ContainerButtonAdd>
        )}
      </ContainerTitlePage>

      <ContentInRow>
        <ButtonSecondary
          width={12}
          height={3}
          onClick={() => handleSearchSilos()}>
          <MdSearch size={18} fill={colors.primary} />
          Buscar registros
        </ButtonSecondary>
        <Paginate
          orderBy={[{value: 'Data'}, {value: 'Silo'}]}
          years={[
            {value: '2020'},
            {value: '2021'},
            {value: '2022'},
            {value: '2023'},
            {value: '2024'},
            {value: '2025'},
          ]}
        />
      </ContentInRow>

      {showFormToAdd && <BackgroundOpacity className="overlay" />}

      <LateralModal
        open={showFormToAdd}
        title="Novo Registro"
        onClose={setShowFormToAdd}>
        <Content>
          <Form ref={formRefAdd} onSubmit={handleAddForm}>
            <ContentInRow>
              <DatePickerStyled>
                <label htmlFor="date">Data:</label>
                <DatePicker
                  name="date"
                  selected={values.date}
                  onChange={(date) => setValues({...values, date})}
                  customInput={<input className="input-datapicker" />}
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeCaption="Horário"
                  timeIntervals={15}
                  // isClearable
                  locale="pt"
                  calendarContainer={CalendarContainer}
                />
              </DatePickerStyled>
              <div>
                <label htmlFor="hour" style={{marginRight: 3}}>
                  Horário:
                </label>
                <span>{formatHourToShow(values.date)}</span>
              </div>
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="silo">Silo:</label>
              <SimpleSelect
                name="silo_number"
                options={[
                  {value: '1', label: '1'},
                  {value: '2', label: '2'},
                  {value: '3', label: '3'},
                  {value: '4', label: '4'},
                  {value: '5', label: '5'},
                  {value: '6', label: '6'},
                  {value: '7', label: '7'},
                  {value: '8', label: '8'},
                ]}
              />
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <ContentInput className="input-block">
                <label htmlFor="rpm_used">RPM utilizado:</label>
                <Input
                  name="rpm_used"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 100"
                  onChange={(e) =>
                    setValues({...values, rpm_used: e.target.value})
                  }
                />
              </ContentInput>
              <ContentInput className="input-block">
                <label htmlFor="measured_time">Tempo medido (s):</label>
                <Input
                  name="measured_time"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 20,32"
                  onChange={(e) =>
                    setValues({...values, measured_time: e.target.value})
                  }
                />
              </ContentInput>
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <ContentInput className="input-block">
                <label htmlFor="mass_found">
                  Massa <br />
                  encontrada (kg):
                </label>
                <Input
                  name="mass_found"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 3,71"
                  onChange={(e) =>
                    setValues({...values, mass_found: e.target.value})
                  }
                />
              </ContentInput>
              <ContentInput className="input-block">
                <label htmlFor="mass_objective">
                  Massa <br /> desejada (kg):
                </label>
                <Input
                  name="mass_objective"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 23"
                  onChange={(e) =>
                    setValues({...values, mass_objective: e.target.value})
                  }
                />
              </ContentInput>
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="rpm_calc">RPM a utilizar:</label>
              <strong id="rpm_calc">{formatDecimal(values.rpm_calc)}</strong>
            </ContentInRow>
            <LineHorizontal />

            <ButtonSecondary type="submit" className="button_submit">
              {loading ? (
                <LottieAnimation
                  width={40}
                  height={40}
                  animation={animationLoadingButton}
                />
              ) : (
                <p>Adicionar</p>
              )}
            </ButtonSecondary>
          </Form>
        </Content>
      </LateralModal>

      {showFormToEdit && <BackgroundOpacity className="overlay" />}

      <LateralModal
        open={showFormToEdit}
        title="Editar Registro"
        onClose={setShowFormToEdit}>
        <Content>
          <Form
            initialData={siloToEdit}
            ref={formRefEdit}
            onSubmit={handleEditForm}>
            <ContentInRow>
              <DatePickerStyled>
                <label htmlFor="date">Data:</label>
                <DatePicker
                  name="date"
                  selected={values.date}
                  onChange={(date) => setValues({...values, date})}
                  customInput={<input className="input-datapicker" />}
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeCaption="Horário"
                  timeIntervals={15}
                  // isClearable
                  locale="pt"
                  calendarContainer={CalendarContainer}
                />
              </DatePickerStyled>
              <div>
                <label htmlFor="hour" style={{marginRight: 3}}>
                  Horário:
                </label>
                <span>{formatHourToShow(values.date)}</span>
              </div>
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="silo">Silo:</label>
              <SimpleSelect
                name="silo_number"
                options={[
                  {value: '1', label: '1'},
                  {value: '2', label: '2'},
                  {value: '3', label: '3'},
                  {value: '4', label: '4'},
                  {value: '5', label: '5'},
                  {value: '6', label: '6'},
                  {value: '7', label: '7'},
                  {value: '8', label: '8'},
                ]}
              />
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <ContentInput className="input-block">
                <label htmlFor="rpm_used">RPM utilizado:</label>
                <Input
                  name="rpm_used"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 100"
                  onChange={(e) =>
                    setValues({...values, rpm_used: e.target.value})
                  }
                />
              </ContentInput>
              <ContentInput className="input-block">
                <label htmlFor="measured_time">Tempo medido (s):</label>
                <Input
                  name="measured_time"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 20,32"
                  onChange={(e) =>
                    setValues({...values, measured_time: e.target.value})
                  }
                />
              </ContentInput>
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <ContentInput className="input-block">
                <label htmlFor="mass_found">
                  Massa <br />
                  encontrada (kg):
                </label>
                <Input
                  name="mass_found"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 3,71"
                  onChange={(e) =>
                    setValues({...values, mass_found: e.target.value})
                  }
                />
              </ContentInput>
              <ContentInput className="input-block">
                <label htmlFor="mass_objective">
                  Massa <br /> desejada (kg):
                </label>
                <Input
                  name="mass_objective"
                  type="number"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  step="0.01"
                  min="0"
                  placeholder="Exemplo: 23"
                  onChange={(e) =>
                    setValues({...values, mass_objective: e.target.value})
                  }
                />
              </ContentInput>
            </ContentInRow>
            <LineHorizontal />

            <ContentInRow>
              <label htmlFor="rpm_calc">RPM a utilizar:</label>
              <strong id="rpm_calc">{formatDecimal(values.rpm_calc)}</strong>
            </ContentInRow>
            <LineHorizontal />

            <ButtonSecondary type="submit" className="button_submit">
              {loading ? (
                <LottieAnimation
                  width={40}
                  height={40}
                  animation={animationLoadingButton}
                />
              ) : (
                <p>Salvar alterações</p>
              )}
            </ButtonSecondary>
          </Form>
        </Content>
      </LateralModal>

      <Table>
        <TableHeader>
          <tr>
            <th className="th-edit">Editar</th>
            <th>Data</th>
            <th>Horário</th>
            <th>Colaborador(a)</th>
            <th>Turma</th>
            <th>Silo</th>
            <th>
              RPM <br /> utilizado
            </th>
            <th>
              Tempo <br /> medido (s)
            </th>
            <th>
              Massa <br /> encontrada (kg)
            </th>
            <th>
              Massa <br /> desejada (kg)
            </th>
            <th>
              RPM a <br /> utilizar
            </th>
            <th className="th-delete">Excluir</th>
          </tr>
        </TableHeader>

        {loadingPage ? (
          <TableBody>
            <tr>
              <td>
                <BackgroundOpacity className="overlay">
                  <LottieAnimation
                    width={100}
                    height={100}
                    animation={animationLoadingPage}
                  />
                </BackgroundOpacity>
              </td>
            </tr>
          </TableBody>
        ) : (
          <TableBody>
            {silos.map((silo) => (
              <tr key={silo.id}>
                {profile.occupation !== 'Analista de Laboratório' &&
                profile.id === silo.user_id ? (
                  <td
                    className="td-edit"
                    onClick={() => setFormToEdit(true, silo)}>
                    <MdEdit size={16} color={colors.primary} />
                  </td>
                ) : (
                  <td className="td-edit">
                    <MdVisibility size={16} color={colors.primary} />
                  </td>
                )}

                <td>{formatDateToShow(silo.date)}</td>
                <td>{formatHourToShow(silo.date)}</td>
                <td>
                  {silo.user ? silo.user.name : silo.user_backup.split(':=')[1]}
                </td>
                <td>
                  {silo.user
                    ? silo.user.class_type
                    : silo.user_backup.split(':=')[2]}
                </td>
                <td>{silo.silo_number}</td>
                <td>{formatDecimal(silo.rpm_used)}</td>
                <td>{formatDecimal(silo.measured_time)}</td>
                <td>{formatDecimal(silo.mass_found)}</td>
                <td>{formatDecimal(silo.mass_objective)}</td>
                <td>{formatDecimal(silo.rpm_calc)}</td>

                {profile.occupation !== 'Analista de Laboratório' &&
                profile.id === silo.user_id ? (
                  <td
                    className="td-delete"
                    onClick={() => handleRemoveSilo(silo.id)}>
                    <MdDelete size={16} color={colors.failure} />
                  </td>
                ) : (
                  <td className="td-delete">
                    <MdBlock size={16} color={colors.failure} />
                  </td>
                )}
              </tr>
            ))}
          </TableBody>
        )}
      </Table>
      {silos.length === 0 ? (
        <div className="container_information">
          <p>
            Você pode registrar uma nova informação clicando sobre o botão
            "Adicionar".
          </p>
        </div>
      ) : (
        hasMoreRegister &&
        !loadingPage &&
        silos.length !== countRegister && (
          <>
            <div style={{margin: 5, color: `${colors.primary}`}}>
              <span>
                <strong>
                  {silos.length} / {countRegister}
                </strong>{' '}
                registros encontrados
              </span>
            </div>
            <ButtonSecondary onClick={() => setPage(page + 1)}>
              Mostrar mais
            </ButtonSecondary>
          </>
        )
      )}
    </Container>
  );
}

export default SilosControl;
