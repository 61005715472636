import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as LeitoActions} from '../../ducks/leito/actions';

function* createLeito({payload}) {
  const {
    name,
    gusa_min,
    gusa_goal,
    slag_min,
    slag_max,
    criticities,
    global_weights,
  } = payload.data;

  try {
    const response = yield call(api.post, '/leito', {
      name,
      gusa_min,
      gusa_goal,
      slag_min,
      slag_max,
      criticities,
      global_weights,
    });

    yield put(LeitoActions.createLeitoSuccess(response.data));
  } catch (error) {
    yield put(LeitoActions.createLeitoFailure(error.message));
  }
}

function* getLeito() {
  try {
    const response = yield call(api.get, '/leito');
    yield put(LeitoActions.getLeitoSuccess(response.data));
  } catch (error) {
    yield put(LeitoActions.getLeitoFailure(error.message));
  }
}

function* updateLeito({payload}) {
  try {
    const {data} = yield call(api.patch, `/leito/${payload.id}`, payload.data);

    const leitoUpdated = {
      id: data.id,
      ...data,
    };

    yield put(LeitoActions.updateLeitoSuccess(leitoUpdated.id, leitoUpdated));
  } catch (error) {
    yield put(LeitoActions.updateLeitoFailure(error.message));
  }
}

function* deleteLeito({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/leito/${id}`);

    yield put(LeitoActions.deleteLeitoSuccess(id));
  } catch (error) {
    yield put(LeitoActions.deleteLeitoFailure(error.message));
  }
}
export {createLeito, getLeito, updateLeito, deleteLeito};
