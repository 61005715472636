import {Types} from './types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  fondants: [],
  lotes: [],
};

const fondantRegister = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.CREATE_FONDANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.CREATE_FONDANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fondants: [...state.fondants, action.payload.data],
        lotes: [...state.lotes, Number(action.payload.data.lote_number)],
      };

    case Types.CREATE_FONDANT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.GET_FONDANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.GET_FONDANT_SUCCESS:
      return {
        ...state,
        fondants: action.payload.data,
        lotes: action.payload.data.map((fondantt) => fondantt.lote_number),
        loading: false,
        error: null,
      };

    case Types.GET_FONDANT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.UPDATE_FONDANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.UPDATE_FONDANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fondants: [
          ...state.fondants.map((fondantt) =>
            fondantt.id === action.payload.id ? action.payload.data : fondantt
          ),
        ],
        lotes: [
          ...state.fondants.map((fondantt) =>
            fondantt.id === action.payload.id
              ? Number(action.payload.data.lote_number)
              : Number(fondantt.lote_number)
          ),
        ],
      };

    case Types.UPDATE_FONDANT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    case Types.DELETE_FONDANT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case Types.DELETE_FONDANT_SUCCESS:
      return {
        loading: false,
        error: null,
        fondants: [
          ...state.fondants.filter(
            (fondantt) => fondantt.id !== action.payload.id
          ),
        ],
        lotes: [
          ...state.fondants
            .filter((fondantt) => fondantt.id !== action.payload.id)
            .map((lote) => Number(lote.lote_number)),
        ],
      };

    case Types.DELETE_FONDANT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };

    default:
      return state;
  }
};

export default fondantRegister;
