import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as WasteActions} from '../../ducks/waste/register/actions';

function* createWaste({payload}) {
  const {status, name, price, mass_available, bought_in} = payload.data;

  try {
    const response = yield call(api.post, '/wastes/register', {
      status,
      name,
      price,
      mass_available,
      bought_in,
    });

    yield put(WasteActions.createWasteSuccess(response.data));
  } catch (error) {
    yield put(WasteActions.createWasteFailure(error.message));
  }
}

function* getWaste() {
  try {
    const response = yield call(api.get, '/wastes/register');
    yield put(WasteActions.getWasteSuccess(response.data));
  } catch (error) {
    yield put(WasteActions.getWasteFailure(error.message));
  }
}

function* updateWaste({payload}) {
  try {
    const {id, status, name, price, mass_available, bought_in} = payload.data;

    const waste = {
      status,
      name,
      price,
      mass_available,
      bought_in,
    };

    const {data} = yield call(api.put, `/wastes/register/${id}`, waste);

    const wasteUpdated = {
      id: data.id,
      status: data.status,
      name: data.name,
      price: data.price,
      mass_available: data.mass_available,
      bought_in: data.bought_in,
    };

    yield put(WasteActions.updateWasteSuccess(id, wasteUpdated));
  } catch (error) {
    yield put(WasteActions.updateWasteFailure(error.message));
  }
}

function* deleteWaste({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/wastes/register/${id}`);

    yield put(WasteActions.deleteWasteSuccess(id));
  } catch (error) {
    yield put(WasteActions.deleteWasteFailure(error.message));
  }
}

export {createWaste, getWaste, updateWaste, deleteWaste};
