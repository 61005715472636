export const Types = {
  CREATE_FONDANTCONSTRAINT_REQUEST: '@fondant/CREATE_FONDANTCONSTRAINT_REQUEST',
  CREATE_FONDANTCONSTRAINT_SUCCESS: '@fondant/CREATE_FONDANTCONSTRAINT_SUCCESS',
  CREATE_FONDANTCONSTRAINT_FAILURE: '@fondant/CREATE_FONDANTCONSTRAINT_FAILURE',
  GET_FONDANTCONSTRAINT_REQUEST: '@fondant/GET_FONDANTCONSTRAINT_REQUEST',
  GET_FONDANTCONSTRAINT_SUCCESS: '@fondant/GET_FONDANTCONSTRAINT_SUCCESS',
  GET_FONDANTCONSTRAINT_FAILURE: '@fondant/GET_FONDANTCONSTRAINT_FAILURE',
  UPDATE_FONDANTCONSTRAINT_REQUEST: '@fondant/UPDATE_FONDANTCONSTRAINT_REQUEST',
  UPDATE_FONDANTCONSTRAINT_SUCCESS: '@fondant/UPDATE_FONDANTCONSTRAINT_SUCCESS',
  UPDATE_FONDANTCONSTRAINT_FAILURE: '@fondant/UPDATE_FONDANTCONSTRAINT_FAILURE',
  DELETE_FONDANTCONSTRAINT_REQUEST: '@fondant/DELETE_FONDANTCONSTRAINT_REQUEST',
  DELETE_FONDANTCONSTRAINT_SUCCESS: '@fondant/DELETE_FONDANTCONSTRAINT_SUCCESS',
  DELETE_FONDANTCONSTRAINT_FAILURE: '@fondant/DELETE_FONDANTCONSTRAINT_FAILURE',
};
