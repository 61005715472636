/* eslint-disable import/no-cycle */
import React, {useEffect} from 'react';
import {FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle} from 'react-icons/fi';
import {useToast} from '../../../../contexts/ToastContext';

import {Container} from './styles';

const icons = {
  info: <FiInfo size={24} />,
  error: <FiAlertCircle size={24} />,
  success: <FiCheckCircle size={24} />,
};

function Toast({message, style}) {
  const {removeToast, actionAllowed, setActionAllowed} = useToast();
  const timeDefault = message.type !== 'confirm' ? 3000 : 3600000;

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, timeDefault);

    return () => {
      clearTimeout(timer);
    };
  }, [removeToast, message.id, actionAllowed, timeDefault]);

  function handleConfirmToastTrue(id_message) {
    setActionAllowed(true);
    removeToast(id_message);
  }

  function handleConfirmToastFalse(id_message) {
    setActionAllowed(false);
    removeToast(id_message);
  }

  return (
    <Container type={message.type} style={style}>
      {/* // hasDescription={!!message.description}> */}
      {icons[message.type || 'info']}

      <div className="title-message">
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button
        onClick={() => removeToast(message.id)}
        type="button"
        className="btn_remove_toast">
        <FiXCircle size={18} />
      </button>

      {message.type === 'confirm' && (
        <div id="container_confirm_toast">
          <button
            onClick={() => handleConfirmToastFalse(message.id)}
            type="button">
            <strong>Cancelar</strong>
          </button>
          <button
            onClick={() => handleConfirmToastTrue(message.id)}
            type="button">
            <strong>Confirmar</strong>
          </button>
        </div>
      )}
    </Container>
  );
}

export default Toast;
