import styled, {keyframes, css} from 'styled-components';
import {colors} from '../../../../assets/styles';

const fadeIn = keyframes`
   from {
     opacity: 0;
  }
   to {
     opacity: 1;
   }
`;

const fadeOut = keyframes`
   from {
     opacity: 1;
  }
   to {
     opacity: 0;
   }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100vh;
  max-width: 700px;
  line-height: 2rem;

  label,
  span,
  strong,
  input,
  select {
    font-size: 1.1rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .button_submit {
    transition: 0.5s;
    background: ${colors.primary};
    color: ${colors.secondary};

    &:hover {
      opacity: 0.8;
      color: ${colors.primary};
    }
  }

  ${(props) =>
    props.showForm
      ? css`
          form {
            animation: ${fadeIn} 0.5s;
          }
        `
      : css`
          form {
            animation: ${fadeOut} 0.5s;
          }
        `}

  .label {
    margin-left: 0.5rem;
  }

  @media (max-width: 900px) {
    line-height: 1rem;
    overflow-y: scroll;

    label,
    span,
    strong,
    input,
    select {
      font-size: 0.9rem;
    }

    form {
      margin-top: 14rem;
      margin-bottom: 7rem;
    }

    select {
      margin-top: 0.5rem;
      padding: 0.2rem;
      height: 1.6rem;
      max-width: 90px;
    }
  }
`;

export const ContentInRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  input {
    width: 100%;
    margin: 0.2rem;
    padding: 0.2rem;
  }
`;

export const ContainerSelect = styled.div`
  margin: 0 0.5rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${colors.secondary};

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 900px) {
    padding: 2rem 3rem;
    .button_submit {
      position: relative;
      bottom: 2rem;
    }
  }
`;

export const CriticityTh = styled.th`
  color: ${colors.secondary};
  font-weight: bold;
  font-size: 0.9rem;
  border: 2px solid
    ${({status}) => {
      if (status === 'HIPER CRITICO') {
        return `${colors.failure}`;
      }
      if (status === 'MUITO CRITICO') {
        return `#Cd7728`;
      }
      if (status === 'CRITICO') {
        return `#97851d`;
      }
      if (status === 'MUITO IMPORTANTE') {
        return `#35492f`;
      }
      if (status === 'IMPORTANTE') {
        return `#4b7d99`;
      }
      return `#999`;
    }};
`;

export const Criticity = styled.td`
  color: ${colors.secondary};
  font-weight: bold;
  font-size: 1rem;
  background: ${({status}) => {
    if (status === 'HIPER CRITICO') {
      return `${colors.failure}`;
    }
    if (status === 'MUITO CRITICO') {
      return `#Cd7728`;
    }
    if (status === 'CRITICO') {
      return `#97851d`;
    }
    if (status === 'MUITO IMPORTANTE') {
      return `#35492f`;
    }
    if (status === 'IMPORTANTE') {
      return `#4b7d99`;
    }
    return `#999`;
  }};

  @media (max-width: 900px) {
    font-size: 0.4rem;
  }
`;

export const Space = styled.div`
  margin-top: 12em;
`;

export const SpaceBottom = styled.div`
  /* margin-bottom: 36em; */

  @media (max-width: 900px) {
    margin-bottom: 14em;
  }
`;

export const FixedArrows = styled.div`
  position: absolute;
  top: 5rem;
  bottom: 0;
  left: 0;
  right: 0;
`;
