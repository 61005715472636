import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as OreActions} from '../../ducks/ore/register/actions';

function* createOre({payload}) {
  const {status, name, price, mass_available, bought_in} = payload.data;

  try {
    const response = yield call(api.post, '/ores/register', {
      status,
      name,
      price,
      mass_available,
      bought_in,
    });

    yield put(OreActions.createOreSuccess(response.data));
  } catch (error) {
    yield put(OreActions.createOreFailure(error.message));
  }
}

function* getOre() {
  try {
    const response = yield call(api.get, '/ores/register');
    yield put(OreActions.getOreSuccess(response.data));
  } catch (error) {
    yield put(OreActions.getOreFailure(error.message));
  }
}

function* updateOre({payload}) {
  try {
    const {id, status, name, price, mass_available, bought_in} = payload.data;

    const ore = {
      status,
      name,
      price,
      mass_available,
      bought_in,
    };

    const {data} = yield call(api.put, `/ores/register/${id}`, ore);

    const oreUpdated = {
      id: data.id,
      status: data.status,
      name: data.name,
      price: data.price,
      mass_available: data.mass_available,
      bought_in: data.bought_in,
    };

    yield put(OreActions.updateOreSuccess(id, oreUpdated));
  } catch (error) {
    yield put(OreActions.updateOreFailure(error.message));
  }
}

function* deleteOre({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/ores/register/${id}`);

    yield put(OreActions.deleteOreSuccess(id));
  } catch (error) {
    yield put(OreActions.deleteOreFailure(error.message));
  }
}

export {createOre, getOre, updateOre, deleteOre};
