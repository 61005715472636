import {call, put} from 'redux-saga/effects';
import api from '../../../services/api';

import {Actions as OreGlobalConstraintActions} from '../../ducks/ore/globalConstraints/actions';

function* createOreGlobalConstraint({payload}) {
  const {
    ore_amount_goal,
    ore_number_in_silo,
    choose_just_one,
    ore_percentual_min,
  } = payload.data;

  try {
    const response = yield call(api.post, '/ores/globalconstraints', {
      ore_amount_goal,
      ore_number_in_silo,
      choose_just_one,
      ore_percentual_min,
    });

    yield put(OreGlobalConstraintActions.createOreSuccess(response.data));
  } catch (error) {
    yield put(OreGlobalConstraintActions.createOreFailure(error.message));
  }
}

function* getOreGlobalConstraint() {
  try {
    const response = yield call(api.get, '/ores/globalconstraints');
    yield put(OreGlobalConstraintActions.getOreSuccess(response.data));
  } catch (error) {
    yield put(OreGlobalConstraintActions.getOreFailure(error.message));
  }
}

function* updateOreGlobalConstraint({payload}) {
  try {
    const {
      id,
      ore_amount_goal,
      ore_number_in_silo,
      choose_just_one,
      ore_percentual_min,
    } = payload.data;

    const ore = {
      ore_amount_goal,
      ore_number_in_silo,
      choose_just_one,
      ore_percentual_min,
    };

    const {data} = yield call(api.patch, `/ores/globalconstraints/${id}`, ore);

    const oreUpdated = {
      id: data.id,
      ore_amount_goal: data.ore_amount_goal,
      ore_number_in_silo: data.ore_number_in_silo,
      choose_just_one: data.choose_just_one,
      ore_percentual_min: data.ore_percentual_min,
    };

    yield put(OreGlobalConstraintActions.updateOreSuccess(id, oreUpdated));
  } catch (error) {
    yield put(OreGlobalConstraintActions.updateOreFailure(error.message));
  }
}

function* deleteOreGlobalConstraint({payload}) {
  try {
    const {id} = payload;

    yield call(api.delete, `/ores/globalconstraints/${id}`);

    yield put(OreGlobalConstraintActions.deleteOreSuccess(id));
  } catch (error) {
    yield put(OreGlobalConstraintActions.deleteOreFailure(error.message));
  }
}

export {
  createOreGlobalConstraint,
  getOreGlobalConstraint,
  updateOreGlobalConstraint,
  deleteOreGlobalConstraint,
};
