import React from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = ({width, height, animation}) => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      }}
      width={width}
      height={height}
    />
  );
};

export default LottieAnimation;
