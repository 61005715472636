/* eslint-disable no-underscore-dangle */
import {createGlobalStyle} from 'styled-components';
import {colors, metrics} from '.';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      height: 10px;
      border-radius: 10px;
      background: ${colors.primary};
      &:hover {
        background: ${colors.primary};
      }
    } 

    scrollbar-color: ${colors.primary} ${colors.secondary};
  }

  html, body, #root {
    max-width: 100vw;
    max-height: 100vh;

    width: 100%;
    height: 100%;
  }

  
  body {
    background: ${colors.primary};
  }

  #root {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body,
  input,
  button,
  textarea {
    font: 500 1.3rem Quicksand;
    border: 0;
  }

  input {
    background: ${colors.tertiary};
    color: ${colors.quaternary};
  }

  button {
    cursor: pointer;
    background: none;
  }

  a {
    text-decoration: none;
  }

  .container {
    width: 90vw;
    max-width: 420px;
    height: 100%;
    max-height: 70vh;
  }

  .container_title_page {
    width: 100%;
    background: ${colors.tertiary};
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    /* margin-top: 2rem; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid ${colors.white._100};
    
    @media(max-width: 500px) {
      margin-top: 4rem;
    }
  }

  .title_page {
    font-size: 1.4rem;
    color: ${colors.primary};
    margin-left: ${metrics.space.small}rem;
  }

  .form_template {
    width: 80vw;
    height: auto;
    background: ${colors.secondary};
    border-radius: 0.5rem;
  }

  .container_information {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 70%; 
    p{
      color: #888;
      font: 500 1rem Quicksand;
    }
  }

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    z-index: 1;
  }

  @media(min-width: 700px){
    :root{
      font-size: 72.5%;
    }
    .title_page {
      font-size: ${metrics.font.small}rem;
    }

    .form_template {
      max-width: 45%;
    }

    .container_information {
      p{
        font: 500 1.2rem Quicksand;
      }
    }
  }
`;
